import { DateTime } from 'luxon'
import React from 'react'
import { Button } from 'stream-ui'
import IconProfileInformation from '../../assets/icons/Icon_Profile_Information_SVG.svg'
import HomeAccount from '../../authenticatedRoutes/HomeAccount'
import AppHeader from '../../layout/AppHeader'

const Background = () => (
  <div
    css={`
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      filter: blur(5px);
      z-index: 0;
      user-select: none;
    `}
  >
    <AppHeader
      title={'Card overview'}
      icon={
        <IconProfileInformation
          css={`
            fill: ${(props) => props.theme.color.primary};
          `}
        />
      }
      buttons={[
        <Button
          key={0}
          buttonType='bordered-icon'
          iconColor='success'
          titleColor='success'
          borderColor='successHighlight'
          title='+ Add More Cards'
          description='Order physical or add virtual cards'
          hideTextOnMobile={true}
          textPadding='0 0.75rem'
        />,
      ]}
      description={'Last log in - ' + DateTime.now().toFormat('dd LLLL yyyy, HH:mm a')}
    />
    <HomeAccount
      account={{
        id: 'current',
        balance: 15210,
        currency: 'EUR',
        name: 'Current Account',
        alias: 'Everyday Shopping Card',
        type: 'string',
        created_at: '2019-08-24T14:15:22Z',
        iban: 'TH65418459512574126',
        cards: [
          {
            id: 'curr_phy',
            individual_id: 'b59a8529-befe-4aba-b132-506544286853',
            account_id: 'current',
            brand: 'mastercard',
            currency: 'EUR',
            display_name: 'Joanne Blanda',
            exp_month: '12',
            exp_year: '2023',
            last4: '5741',
            region: 'europe',
            status: 'active',
            type: 'physical',
          },
        ],
      }}
      isFirst={true}
    />
  </div>
)

export default Background

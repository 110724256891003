import React from 'react'
import styled from 'styled-components'

import Dropdown, { OptionItem, OptionLabel } from './Dropdown'
import { countries } from './countries'
import Flag from './Flag'

const CustomLabel = styled(OptionLabel)`
  display: flex;
  padding: 0;
`

const PhoneCodeDropdown = ({ label, value, filter, onChange, description, color, readOnly, ...rest }) => {
  const filteredList = filter && typeof filter === 'function' ? filter(countries) : countries
  const appendKey = (k) => onChange({ ...filteredList[k], key: k })

  if (value && value.key === undefined && value.alpha2code !== '') {
    // if we have the alpha2code of the country, but it's not a keyed entry from this component.
    const keyedCountryIndex = filteredList.findIndex((v) => v.alpha2code === value.alpha2code)
    if (keyedCountryIndex > -1) {
      value = { ...filteredList[keyedCountryIndex], key: keyedCountryIndex }
    }
  }

  return (
    <Dropdown
      {...rest}
      css={'width: unset;'}
      value={value?.key}
      valueFieldFunction={(v, i) => i}
      list={filteredList}
      label={label}
      description={description}
      onChange={readOnly ? () => {} : appendKey}
      filterOption={(input, val) =>
        val.name.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        val.phoneCode.startsWith(input.toLowerCase()) ||
        val.phoneCode.startsWith('+' + input.toLowerCase())
      }
      optionLabelRenderFunction={(v) => (
        <CustomLabel color={color}>
          <Flag countryCode={v.alpha2code} />
          &nbsp;&nbsp;
          <span>
            {v.alpha2code} {v.phoneCode}
          </span>
        </CustomLabel>
      )}
      optionItemRenderFunction={(v) => (
        <OptionItem>
          <Flag countryCode={v.alpha2code} />
          &nbsp;&nbsp;
          <span>
            {v.name} ({v.phoneCode})
          </span>
        </OptionItem>
      )}
    />
  )
}

export default PhoneCodeDropdown

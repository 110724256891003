import parsePhoneNumber from 'libphonenumber-js'
/**
 * Checks if the given phone number object is valid.
 *
 * @param phoneNumber
 * @returns {boolean} Whether the given phone number is valid
 */
export const isValidPhoneNumber = ({ country, phoneNumber }) => {
  if (phoneNumber === undefined) {
    return false
  }
  if (phoneNumber.match(/^[^a-zA-Z]*$/) == null) {
    return false
  }
  try {
    const number = parsePhoneNumber(phoneNumber, country.alpha2code)
    // console.log(number)
    if (number.isValid()) {
      return true
    }
  } catch (e) {
    return false
  }
  return false
}

export const formatPhoneNumber = ({ country, phoneNumber }) => {
  // assuming phone number is valid
  try {
    const number = parsePhoneNumber(phoneNumber, country.alpha2code)
    return number.formatInternational()
  } catch (e) {
    return ''
  }
}

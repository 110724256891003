import React from 'react'
import styled from 'styled-components'

import { ErrorMessage as StyledOne } from 'stream-ui'

const defaultMsg = 'There was a problem'

const CustomErrorMessage = styled(StyledOne)`
  padding-left: 0;
`

const ErrorMessage = ({ message = '', ...rest }) =>
  message !== '' ? (
    <CustomErrorMessage {...rest}>{typeof message === 'string' ? message : defaultMsg}</CustomErrorMessage>
  ) : null

export default ErrorMessage

import React from 'react'
import ReactCountryFlag from 'react-country-flag'

const Flag = ({ countryCode, square = false, className }) => {
  return (
    <ReactCountryFlag
      countryCode={countryCode}
      svg={true}
      className={className}
      css={`
        width: 1.5rem;
        height: 1.5rem;
      `}
      style={{
        width: null,
        height: null,
      }}
      cdnUrl={'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/' + (square ? '1x1/' : '4x3/')}
    />
  )
}

export default Flag

import React from 'react'
import styled from 'styled-components'

import { Dropdown, OptionItem, OptionLabel } from 'stream-ui'

const CustomLabel = styled(OptionLabel)`
  display: flex;
  padding: 0;
`

const AccountDropdown = ({ color, list, ...rest }) => {
  return (
    <Dropdown
      {...rest}
      list={[{ name: 'Show all accounts', id: 'all', theme: 'blue' }, ...list]}
      id='groupDropdown'
      valueFieldFunction={(v) => v.id}
      label={null}
      description={null}
      filterOption={(input, val) => val.name.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      optionLabelRenderFunction={(v) =>
        v.id === 'all' ? (
          <CustomLabel color={color} css='padding: 0.25rem 0;'>
            <span
              css={`
                color: ${(props) => props.theme.color.textPrimary};
                font-style: italic;
                font-size: ${(props) => props.theme.font.size.small};
              `}
            >
              Showing all accounts
            </span>
          </CustomLabel>
        ) : (
          <CustomLabel color={color} css='padding: 0.25rem 0;'>
            <span
              css={`
                font-size: ${(props) => props.theme.font.size.small};
              `}
            >
              {v.name}
            </span>
          </CustomLabel>
        )
      }
      optionItemRenderFunction={(v) =>
        v.id === 'all' ? (
          <OptionItem css='padding: 0.25rem 0.5rem;'>
            <span
              css={`
                font-size: ${(props) => props.theme.font.size.small};
              `}
            >
              Showing all accounts
            </span>
          </OptionItem>
        ) : (
          <OptionItem css='padding: 0.25rem 0.5rem;'>
            <span
              css={`
                font-size: ${(props) => props.theme.font.size.small};
              `}
            >
              {v.name}
            </span>
          </OptionItem>
        )
      }
    />
  )
}

export default AccountDropdown

import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router'
import { Button, Input, onStringChange, PageEncryptedReminder } from 'stream-ui'
import styled, { css } from 'styled-components'
import API from './api'
import CNCardVisa from './assets/CN_card_Visa2_resized-min.png'
import AuthContext from './Auth'
import { buttonStyles, Container, Header, marginXAuto, onDesktop } from './cardnoirStyledElements'
import ErrorMessage from './ErrorMessage'
import Footer from './layout/Footer'
import Navbar from './layout/Navbar'

const FormColumn = styled.div`
  width: 100%;
  border-left: ${({ theme }) => theme.border.accent};
  padding-left: 1.5rem;
  padding-bottom: 4rem;
`
const FormColumnFooter = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.primary};
  text-align: center;
  color: ${({ theme }) => theme.color.background};
  font-size: calc(1.1 * ${({ theme }) => theme.font.size.normal});
  padding: 0.4rem 0;
  font-family: ${({ theme }) => theme.font.family.bold};
`

const ApplyColumn = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 0.4rem;
`

const addMarginBottom = css`
  margin-bottom: 2rem;
`

const SignIn = () => {
  const navigate = useNavigate()
  const [loginError, setLoginError] = useState()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { setToken } = useContext(AuthContext)

  const tryLogin = async () => {
    setLoginError()
    try {
      const { token } = await API.authenticateUser({ email, password })
      setToken(token)
    } catch (err) {
      setLoginError(err)
    }
  }

  return (
    <div>
      <Helmet>
        <title>Card Noir - Sign In</title>
      </Helmet>
      <Navbar />
      <div
        css='display: grid; grid-template-rows: auto 1fr auto;
          min-height: calc(100vh - 87px);'
      >
        <Header>
          Log into your{' '}
          <br
            css={`
              ${onDesktop`
                display: none;
              `}
            `}
          />
          Card Noir profile
        </Header>
        <Container>
          <div
            css={`
              max-width: 392px;
              ${marginXAuto}
            `}
          >
            <FormColumn>
              <Input
                css={addMarginBottom}
                type='email'
                label='Email address'
                id='email'
                description='The email address linked to your profile'
                onChange={onStringChange(setEmail)}
                value={email}
                autoFocus={true}
                required='required'
                onKeyPress={(e) => e.key === 'Enter' && tryLogin()}
              />
              <Input
                css={addMarginBottom}
                type='password'
                label='Password'
                description='Enter your profile password to log in'
                id='password'
                onChange={onStringChange(setPassword)}
                value={password}
                required='required'
                onKeyPress={(e) => e.key === 'Enter' && tryLogin()}
              />
              <ErrorMessage message={loginError} css='margin-bottom: 1rem;' />
              <div css='display: flex; justify-content: end;'>
                <Button
                  title='LOG IN'
                  disabled={email === '' || password === ''}
                  onClick={tryLogin}
                  {...buttonStyles.formButton}
                  borderColor='success'
                  titleColor='success'
                />
              </div>
            </FormColumn>
            <FormColumnFooter>Don&apos;t have a Card Noir profile yet?</FormColumnFooter>
            <ApplyColumn>
              <img
                css='width: 140px; margin-left: -1rem; margin-top: 0.4rem;'
                className='card-holder'
                src={CNCardVisa}
                alt=''
              />
              <div css='display: flex; align-items: center;'>
                <Button
                  title={
                    <>
                      APPLY FOR
                      <br />
                      YOUR CARD TODAY
                    </>
                  }
                  borderColor='primary'
                  paddingInline='0.5rem'
                  titleFontFamily='bold'
                  titleLetterSpacing='2px'
                  textAlign='center'
                  css={`
                    padding-top: 0.4rem;
                    padding-bottom: 0.4rem;
                  `}
                  onClick={() => {
                    navigate('/sign-up')
                  }}
                />
              </div>
            </ApplyColumn>
            <PageEncryptedReminder css='justify-content: center;' />
          </div>
        </Container>
        <Footer />
      </div>
    </div>
  )
}

export default SignIn

import React from 'react'
import Steps, { createStepItem } from './Steps'

const SignUpSteps = ({ step }) => {
  return (
    <Steps
      steps={[
        createStepItem(1, 'Create a Profile'),
        createStepItem(2, 'Enter Profile Information'),
        createStepItem(3, 'Confirm Your Order Details'),
        createStepItem(4, 'Load Funds to Your Card'),
        createStepItem(5, 'Verify Your Information'),
      ]}
      current={step}
      circleDiameter={{ xxs: 30, xs: 36, sm: 50, md: 60 }}
      connectorLength={{ xxs: 30, xs: 36, sm: 60, md: 100 }}
      numberFontSize={{ xs: '1.1rem', sm: '2rem', md: '2.5rem' }}
      textFontSize={{ xxs: '0.66rem', xs: '0.66rem', sm: '0.85rem', md: '1rem' }}
    />
  )
}

export default SignUpSteps

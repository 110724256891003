import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Logo from '../assets/card_noir_logo.svg'
import LoginIcon from '../assets/login.svg'
import IconLogout from '../assets/icons/Icon_Log_Out.svg'

import NavbarBackground from '../assets/top_decco.svg'
import { Container, onDesktop } from '../cardnoirStyledElements'
import SessionContext from '../SessionContext'
import AuthContext from '../Auth'

const HeaderBackground = styled(NavbarBackground)`
  height: 60px;
  position: fixed;
  top: 0;
  width: 100%;
  display: none;

  ${onDesktop`
    height: ${(props) => (props.$smaller ? '90px' : '120px')};
    display: block;
  `}
`

const StyledNavbar = styled.nav`
  z-index: 1000;
  padding: 0;
  position: relative;
  padding-bottom: 60px;

  ${onDesktop`
    padding-bottom: ${(props) => (props.$smaller ? '90px' : '120px')};
  `}
`

const NavbarContent = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.dark};

  position: fixed;
  top: 0;

  ${onDesktop`
    height: ${(props) => (props.$smaller ? '90px' : '120px')};
    background-color: unset;
  `}
`

const StyledLogo = styled(Logo)`
  height: 50px;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;

  ${onDesktop`
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
    height: 52px;
  `}
`

const GradientDivider = styled.div`
  height: 5px;
  bottom: 0;
  background: #fbb03b;
  background: linear-gradient(225deg, #fbb03b, #ed1c24);
  width: 100%;
  position: absolute;
`

const NavbarContainer = styled(Container)`
  display: flex;
  padding-top: unset;
  padding-bottom: 0.3rem;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`

const ToggleButton = styled.button`
  cursor: pointer;
  border: none;
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  -webkit-appearance: button;

  ${onDesktop`
    display: none;
  `}
`

const TogglerIcon = styled.span`
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  stroke: ${({ theme }) => theme.color.primary};
  background: no-repeat 50%;
  background-image: none;
  background-size: auto;
  background-size: 100% 100%;
`

const Menu = styled.div`
  display: ${(props) => (props.collapsed ? 'unset' : 'block')};
  opacity: ${(props) => (props.collapsed ? 0 : 1)};
  visibility: ${(props) => (props.collapsed ? 'hidden' : 'visible')};
  height: ${(props) => (props.collapsed ? 0 : 'unset')};
  top: ${(props) => (props.collapsed ? '-50px' : 0)};
  position: ${(props) => (props.collapsed ? 'relative' : 'unset')};
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
  transition-delay: 0s;
  transition-property: opacity, visibility, top;
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
  font-family: ${({ theme }) => theme.font.family.bold};

  padding-bottom: ${(props) => (props.collapsed ? 0 : '0.9rem')};
  color: ${({ theme }) => theme.color.textPrimary};
  background-color: ${(props) => (props.collapsed ? 'unset' : props.theme.color.background)};

  ${onDesktop`
    height: unset;
    opacity: 1;
    visibility: visible;
    top: unset;
    position: unset;
    display: flex !important;
    flex-basis: auto;
    background-color: unset;
    padding-bottom: 0;
  `}

  > ul {
    justify-content: center;
    margin: 0;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    list-style: none;

    ${onDesktop`
      flex-direction: row;
    `}

    > li {
      display: flex;
      justify-content: center;
    }
  }
`

const MenuAnchors = styled.a`
  text-align: center;
  font-size: 1rem;
  padding: 1rem 1.25rem;
  color: ${({ theme }) => theme.color.textPrimary};
  border-bottom: 2px solid transparent;
  display: block;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    color: ${({ theme }) => theme.color.primary};
    border-bottom-color: ${({ theme }) => theme.color.primary};
  }
`

const LoginButton = styled.button`
  display: flex;
  text-align: center;
  font-size: 1rem;
  padding: 0.9rem 1.25rem;
  color: ${({ theme }) => theme.color.textPrimary};
  border: 2px solid transparent;
  border-radius: 6px;
  text-decoration: none;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  > span {
    margin-left: 8px;
  }

  &:hover {
    color: ${({ theme }) => theme.color.primary};
    border-color: ${({ theme }) => theme.color.primary};
  }
`
const Navbar = ({ smaller = false }) => {
  const navigate = useNavigate()
  const { setUser } = useContext(SessionContext)
  const { token, setToken } = useContext(AuthContext)

  const [collapsed, setCollapsed] = useState(true)

  const logout = () => {
    setUser()
    setToken('')
    navigate('/sign-in')
    localStorage.removeItem('card-noir-session')
    sessionStorage.removeItem('card-noir-token')
    sessionStorage.removeItem('card-noir-last-logged-in')
  }

  return (
    <StyledNavbar $smaller={smaller}>
      <HeaderBackground $smaller={smaller} />
      <NavbarContent $smaller={smaller}>
        <NavbarContainer>
          <StyledLogo className='mt-2 mt-md-1 mb-1' />
          <ToggleButton
            type='button'
            aria-expanded={!collapsed}
            aria-label='Toggle navigation'
            onClick={() => setCollapsed(!collapsed)}
          >
            <TogglerIcon>
              <svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'>
                <path strokeWidth='2' strokeLinecap='round' strokeMiterlimit='10' d='M4 7h22M4 15h22M4 23h22' />
              </svg>
            </TogglerIcon>
          </ToggleButton>

          <Menu collapsed={collapsed} id='navbar-collapse'>
            <ul>
              <li>
                <MenuAnchors href='/'>Home</MenuAnchors>
              </li>
              <li>
                <MenuAnchors href='/'>Why this card?</MenuAnchors>
              </li>
              <li>
                <MenuAnchors href='/'>Pricing</MenuAnchors>
              </li>
              <li>
                <MenuAnchors
                  href='/'
                  css={`
                    color: ${({ theme }) => theme.color.primary};
                  `}
                >
                  Get Your Card
                </MenuAnchors>
              </li>
              <li>
                {token ? (
                  <LoginButton onClick={logout} css='font-family: inherit;'>
                    <IconLogout
                      css={`
                        height: 22px;
                        width: 22px;
                        fill: ${(props) => props.theme.color.white};
                      `}
                    />
                    <span>Log Out</span>
                  </LoginButton>
                ) : (
                  <LoginButton
                    onClick={() => {
                      navigate('/sign-in')
                    }}
                    css='font-family: inherit;'
                  >
                    <LoginIcon
                      css={`
                        height: 22px;
                        width: 22px;
                      `}
                    />
                    <span>Log in</span>
                  </LoginButton>
                )}
              </li>
            </ul>
          </Menu>
        </NavbarContainer>
        <GradientDivider />
      </NavbarContent>
    </StyledNavbar>
  )
}

export default Navbar

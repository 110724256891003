import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Description, DisplayOnlyText, ErrorMessage, Label } from './styledElements'

const Input = styled.input`
  display: block;
  box-shadow: none;
  text-align: center;
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  color: ${({ color, theme }) => (color && theme.color[color] ? theme.color[color] : theme.color.input)};
  background-color: unset;
  font-size: ${({ theme }) => theme.font.size.normal};
  border: 1pt solid transparent;
  border-bottom-color: ${(props) => (props.invalid ? props.theme.color.error : props.theme.color.textSecondary)};
  padding: 0.25rem 0.5rem;
  margin-bottom: 0.125rem;
  margin-top: ${(props) => (props.label ? '0.125rem' : '0')};
  height: 2rem;

  &::placeholder {
    color: ${({ theme }) => theme.color.textPrimary};
    font-style: italic;
    opacity: 0.7;
    font-family: ${({ theme }) => theme.font.family.regular};
  }

  &:focus,
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.color.primary};
    border-radius: 6px;
    border-bottom-color: ${({ theme }) => theme.color.primary};
  }
`
const SimpleDatePicker = ({
  id = '',
  label,
  description,
  value,
  onChange,
  required = false,
  validator = () => '', // returns an error message, empty message if valid
  errorMessage = 'Please enter a valid date', // default error message
  requiredMessage = 'This field is required',
  color,
  displayOnly = false,
  showErrorOnBlur = false,
  reverseOrder = false,
  cssForInputWrapper,
  cssForInput,
  cssForLabel,
  ...props
}) => {
  let luxonDate = DateTime.fromISO(value)
  const [year, setYear] = useState(value ? luxonDate.year : '')
  const [month, setMonth] = useState(value ? luxonDate.month : '')
  const [day, setDay] = useState(value ? luxonDate.day : '')

  let yearRef = React.createRef()
  let monthRef = React.createRef()
  let dayRef = React.createRef()

  const [blurredYear, setBlurredYear] = useState(false)
  const [blurredMonth, setBlurredMonth] = useState(false)
  const [blurredDay, setBlurredDay] = useState(false)

  const isTouched = blurredYear && blurredMonth && blurredDay
  const [isValid, setValid] = useState(!required)

  const numberChange = (handler, stepper, target) => (e) => {
    // call onChange only when valid change occurred
    handler(e.target.value)
    if (stepper === e.target.value.length && target != null) {
      target.current?.focus()
      target.current?.select()
    }
  }

  const dateText = '' + year + ' ' + ('' + month).padStart(2, '0') + ' ' + ('' + day).padStart(2, '0')
  const date = DateTime.fromFormat(dateText, 'yyyy LL dd')

  useEffect(() => {
    setValid((date.isValid && validator(date) === '') || (!required && year === '' && (month === '') & (day === '')))
    if (date.isValid && validator(date) === '') {
      if (date.toISODate() !== value) {
        onChange(date.toISODate())
      }
    } else {
      onChange(undefined)
    }
  }, [dateText, date, required, validator])

  const handleReverse = (arr) => (reverseOrder ? arr.reverse() : arr)

  return (
    <div {...props}>
      {label && (
        <Label
          css={`
            > span {
              font-size: ${({ theme }) => theme.font.size.extraSmall};
              color: ${({ theme }) => theme.color.textSecondary};
            }
            ${cssForLabel}
          `}
        >
          {label}
        </Label>
      )}
      {displayOnly ? (
        luxonDate.isValid ? (
          <DisplayOnlyText>{luxonDate.toFormat('dd.LL.yyyy')}</DisplayOnlyText>
        ) : (
          ''
        )
      ) : (
        <div css='display: flex; align-items: start; flex-direction: column; justify-content: start;'>
          <div
            css={`
              display: flex;
              align-items: center;
              ${cssForInputWrapper}
            `}
          >
            {handleReverse([
              <Input
                key={0}
                color={color}
                invalid={(!showErrorOnBlur || isTouched) && !isValid}
                ref={yearRef}
                type='tel'
                id={id + '-year'}
                pattern='[0-9]*'
                placeholder='YYYY'
                onChange={numberChange(setYear, 4, reverseOrder ? null : monthRef)}
                onBlur={() => setBlurredYear(true)}
                value={year ? year : ''}
                css={`
                  width: 40%;
                  ${cssForInput}
                `}
              />,
              <Input
                key={1}
                color={color}
                invalid={(!showErrorOnBlur || isTouched) && !isValid}
                ref={monthRef}
                type='tel'
                id={id + '-month'}
                pattern='[0-9]*'
                placeholder='MM'
                onChange={numberChange(setMonth, 2, reverseOrder ? yearRef : dayRef)}
                onBlur={() => setBlurredMonth(true)}
                value={month ? month : ''}
                css={`
                  width: 30%;
                  ${cssForInput}
                `}
              />,
              <Input
                key={3}
                color={color}
                invalid={(!showErrorOnBlur || isTouched) && !isValid}
                ref={dayRef}
                type='tel'
                id={id + '-day'}
                pattern='[0-9]*'
                placeholder='DD'
                onChange={numberChange(setDay, 2, reverseOrder ? monthRef : null)}
                onBlur={() => setBlurredDay(true)}
                value={day ? day : ''}
                css={`
                  width: 30%;
                  ${cssForInput}
                `}
              />,
            ])}
          </div>

          <Description css={description ? '' : 'display:none'}>{description}</Description>
          {errorMessage && (!showErrorOnBlur || isTouched) && !isValid && (
            <ErrorMessage>
              {date.isValid
                ? validator(date)
                : required && year === '' && (month === '') & (day === '')
                ? requiredMessage
                : errorMessage}
            </ErrorMessage>
          )}
        </div>
      )}
    </div>
  )
}

export default SimpleDatePicker

import React, { useContext, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router'
import BackIcon from '../assets/icons/Icon_Arrow_Back_1.svg'
import { Container, onDesktop } from '../cardnoirStyledElements'
import AppHeader from '../layout/AppHeader'
import AppWrapper from '../layout/AppWrapper'
import SessionContext from '../SessionContext'
import BeneficiaryCentreList from './BeneficiaryCentreList'
import BeneficiaryCentreSearchIndividual from './BeneficiaryCentreSearchIndividual'

const BeneficiaryCentre = () => {
  const { user } = useContext(SessionContext)
  const { loadingError } = useOutletContext()
  const navigate = useNavigate()

  const [nameFilter, setNameFilter] = useState()
  const [countryFilter, setCountryFilter] = useState()
  const [statusFilter, setStatusFilter] = useState('active')

  return (
    <AppWrapper
      title='Card Noir - Beneficiary Centre'
      appHeader={
        <AppHeader
          title='Manage Beneficiaries'
          description='Add and manage beneficiaries'
          icon={
            <BackIcon
              css={`
                padding: 0.1rem;
                height: 100%;
                width: 100%;
              `}
            />
          }
          iconOnClick={() => {
            navigate(-1)
          }}
        />
      }
    >
      <Container
        css={`
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
          display: grid;
          gap: 1.5rem;
          align-items: start;
          grid-template-areas: 'filters' 'list';
          grid-template-columns: 1fr;
          flex-direction: column;
          ${onDesktop`
              padding-top: 3rem;
              padding-bottom: 3rem;
              grid-template-areas: 'filters list';
              grid-template-columns: auto 1fr;
              gap: 3rem;
            `}
        `}
      >
        {user === undefined && !loadingError && <div>Loading...</div>}
        {user === undefined && loadingError && (
          <div>We&apos;ve met with an unexpected error while loading the dashboard. Please try again later.</div>
        )}
        {user && (
          <>
            <div
              css={`
                width: 100%;
                isolation: isolate;
                grid-area: filters;

                ${onDesktop`
                width: 390px;
              `}
              `}
            >
              <BeneficiaryCentreSearchIndividual
                nameFilter={nameFilter}
                setNameFilter={setNameFilter}
                countryFilter={countryFilter}
                setCountryFilter={setCountryFilter}
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
              />
            </div>
            <BeneficiaryCentreList
              css={`
                grid-area: list;
                ${onDesktop`
                display: block;
              `};
              `}
            />
          </>
        )}
      </Container>
    </AppWrapper>
  )
}

export default BeneficiaryCentre

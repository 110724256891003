import { DateTime } from 'luxon'
import React, { useRef, useState } from 'react'
import { Button } from 'stream-ui'
import { css } from 'styled-components'
import DownArrowIcon from '../assets/icons/Icon_Arrow_Drop_Down.svg'
import FundsCardIcon from '../assets/icons/Icon_Funds_Card.svg'
import FundsCashIcon from '../assets/icons/Icon_Funds_Cash.svg'
import FundsLoadIcon from '../assets/icons/Icon_Funds_Load.svg'
import FundsSendIcon from '../assets/icons/Icon_Funds_Send.svg'
import FundsTransferIcon from '../assets/icons/Icon_Funds_Transfer.svg'
import { onDesktop } from '../cardnoirStyledElements'
import { currencyFormat } from '../currencies'


const pr = new Intl.PluralRules(new Intl.NumberFormat().resolvedOptions().locale, {
  type: 'ordinal',
})
const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
])
const formatOrdinals = (n) => {
  const rule = pr.select(n)
  const suffix = suffixes.get(rule)
  return `${n}${suffix}`
}

const getDate = (dateString) => {
  let date = DateTime.fromISO(dateString)

  return date.toFormat('cccc ') + formatOrdinals(date.get('day')) + date.toFormat(' LLLL, yyyy')
}

export const getTransactionType = (transaction) =>
  transaction.type === 'card-payment'
    ? transaction.reference.indexOf('ATM_WITHDRAWAL') > -1
      ? 'ATM_WITHDRAWAL'
      : 'CARD_PAYMENT'
    : (transaction.reference.indexOf('INTERNAL_TRANSFER') > -1 ? 'INTERNAL_TRANSFER' : 'BANK_TRANSFER') +
      (transaction.amount >= 0 ? '_IN' : '_OUT')

export const descriptionMap = (transactionType) => {
  switch (transactionType) {
    case 'INTERNAL_TRANSFER_OUT':
      return 'Internal transfer to'
    case 'INTERNAL_TRANSFER_IN':
      return 'Internal transfer from'
    case 'ATM_WITHDRAWAL':
      return 'Withdrawn at'
    case 'CARD_PAYMENT':
      return 'Spent at'
    case 'BANK_TRANSFER_IN':
      return 'Received from'
    case 'BANK_TRANSFER_OUT':
      return 'Sent to'
  }
}

export const properName = (transactionType) => {
  switch (transactionType) {
    case 'INTERNAL_TRANSFER_OUT':
    case 'INTERNAL_TRANSFER_IN':
      return 'Internal Transfer'
    case 'ATM_WITHDRAWAL':
      return 'ATM Withdrawal'
    case 'CARD_PAYMENT':
      return 'Card Payment'
    case 'BANK_TRANSFER_IN':
    case 'BANK_TRANSFER_OUT':
      return 'Bank Transfer'
  }
}

export const iconMap = (transactionType) => {
  const iconCSS = css`
    width: 24px;
    flex-shrink: 0;
    path {
      fill: ${(props) => props.theme.color.white};
    }
  `
  switch (transactionType) {
    case 'INTERNAL_TRANSFER_OUT':
      return <FundsTransferIcon css={iconCSS} />
    case 'INTERNAL_TRANSFER_IN':
      return <FundsTransferIcon css={iconCSS} />
    case 'ATM_WITHDRAWAL':
      return <FundsCashIcon css={iconCSS} />
    case 'CARD_PAYMENT':
      return <FundsCardIcon css={iconCSS} />
    case 'BANK_TRANSFER_IN':
      return <FundsLoadIcon css={iconCSS} />
    case 'BANK_TRANSFER_OUT':
      return <FundsSendIcon css={iconCSS} />
  }
}

const TransactionItem = ({ transaction, isLastInGroup }) => {
  const [isOpened, setIsOpened] = useState(false)
  const extraElement = useRef()

  const transactionType = getTransactionType(transaction)

  return (
    <div
      $isLastInGroup={isLastInGroup}
      css={`
        cursor: pointer;
        margin-left: 1rem;
        border-top: 1pt solid ${(props) => props.theme.color.muted};
        border-bottom: 1pt solid ${(props) => (props.$isLastInGroup ? props.theme.color.muted : 'transparent')};
        margin-bottom: ${isLastInGroup ? '0.35rem' : ''};
        :nth-child(even) {
          background: ${(props) => props.theme.color.backgroundMuted};
        }
      `}
      onClick={() => setIsOpened((v) => !v)}
    >
      <div
        css={`
          display: flex;
          width: 100%;
          padding: 0.5rem 0.25rem;
          height: 4.375rem;
          align-items: center;
          font-size: ${(props) => props.theme.font.size.small};
          font-family: ${(props) => props.theme.font.family.bold};
          color: ${(props) => props.theme.color.textPrimary};
          line-height: 1.3;
          ${onDesktop`padding: 0.5rem 0.75rem; height: 3.75rem;`}
        `}
      >
        {iconMap(transactionType)}
        <div css='flex-basis: 100%; padding-left: 0.5rem; '>
          <div
            css={`
              font-size: ${(props) => props.theme.font.size.extraSmall};
              color: ${(props) => props.theme.color.primaryHighlight};
            `}
          >
            {transaction.account.alias}
          </div>
          <div
            css={`
              font-size: ${(props) => props.theme.font.size.extraSmall};
              ${onDesktop`font-size: ${(props) => props.theme.font.size.small};`}
            `}
          >
            <span
              css={`
                font-family: ${(props) => props.theme.font.family.regular};
                display: block;
                ${onDesktop`display: inline-block;`}
              `}
            >
              {descriptionMap(transactionType)}
            </span>
            <span>{' ' + transaction.counterparty.name}</span>
          </div>
        </div>
        <div
          css={`
            text-align: right;
            flex-shrink: 0;
          `}
        >
          {currencyFormat.format(Math.abs(transaction.amount)) + '  ' + transaction.currency}
        </div>
        <Button
          buttonType='borderless'
          buttonSize='extra-small'
          icon={
            <DownArrowIcon
              css={`
                transform: ${isOpened ? 'rotate(180deg)' : null};
                transition-duration: 0.3s;
                transition-timing-function: ease-in-out;
                transition-delay: 0s;
                transition-property: transform;
              `}
            />
          }
          iconColor='primary'
          cssForIconWrapper={css`
            width: 1.25rem;
            height: 1.25rem;
          `}
          css={`
            flex-shrink: 0;
            min-width: 1.25rem;
            min-height: 1.25rem;
            margin-left: 0.25rem;
            ${onDesktop`
              margin-left: 0.75rem;              
            `}
          `}
        />
      </div>
      <div
        ref={extraElement}
        $isOpened={isOpened}
        css={`
          height: ${isOpened ? extraElement.current.scrollHeight + 'px' : '0px'};
          border-top: 1pt ${isOpened ? 'solid' : 'none'} ${(props) => props.theme.color.successHighlight};
          border-left: 1pt ${isOpened ? 'solid' : 'none'} ${(props) => props.theme.color.successHighlight};
          margin: ${isOpened ? '0 3.5rem 0.75rem 0.75rem' : '0 3.75rem 0 0'};
          opacity: ${isOpened ? 1 : 0};
          overflow: hidden;
          transition-duration: 0.3s;
          transition-timing-function: ease;
          transition-delay: 0s;
          transition-property: height opacity;
        `}
      >
        <div
          css={`
            padding: 0.5rem 0 0.5rem 1rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            ${onDesktop`flex-direction: row;`}
          `}
        >
          <div css='width: 100%;'>
            <div
              css={`
                font-size: ${(props) => props.theme.font.size.small};
                font-family: ${(props) => props.theme.font.family.bold};
              `}
            >
              Transaction Details
            </div>
            <div
              css={`
                padding-left: 0.75rem;
                padding-top: 0.5rem;
                font-size: ${(props) => props.theme.font.size.extraSmall};
              `}
            >
              {transactionType.startsWith('INTERNAL_') ? (
                <>
                  <div
                    css={`
                      display: grid;
                      grid-template-columns: 1fr auto;
                      border-bottom: 1pt solid ${(props) => props.theme.color.muted};
                      padding-bottom: 1rem;
                    `}
                  >
                    <div>Total {transaction.amount > 0 ? 'received' : 'sent'}</div>
                    <div
                      css={`
                        font-family: ${(props) => props.theme.font.family.bold};
                      `}
                    >
                      {currencyFormat.format(Math.abs(transaction.amount)) + '  ' + transaction.currency}
                    </div>
                  </div>
                  <div
                    css={`
                      display: grid;
                      grid-template-columns: 1fr auto;
                      padding-top: 0.25rem;
                    `}
                  >
                    <div>Estimated arrival</div>
                    <div css='text-align: right;'>
                      {DateTime.fromISO(transaction.created_at).toFormat('d LLL yyyy')}
                    </div>
                    <div>Reference</div>
                    <div css='text-align: right;'>{transaction.reference}</div>
                    <div>Transaction number</div>
                    <div css='text-align: right;'>{transaction.id}</div>
                  </div>
                </>
              ) : transaction.amount > 0 ? (
                <>
                  <div
                    css={`
                      display: grid;
                      grid-template-columns: 1fr auto;
                      border-bottom: 1pt solid ${(props) => props.theme.color.muted};
                      padding-bottom: 1rem;
                    `}
                  >
                    <div>Total received</div>
                    <div
                      css={`
                        font-family: ${(props) => props.theme.font.family.bold};
                      `}
                    >
                      {currencyFormat.format(transaction.amount) + '  ' + transaction.currency}
                    </div>
                  </div>
                  <div
                    css={`
                      display: grid;
                      grid-template-columns: 1fr auto;
                      padding-top: 0.25rem;
                    `}
                  >
                    <div>Estimated arrival</div>
                    <div css='text-align: right;'>
                      {DateTime.fromISO(transaction.created_at).toFormat('d LLL yyyy')}
                    </div>
                    <div>Reference</div>
                    <div css='text-align: right;'>{transaction.reference}</div>
                    <div>Transaction number</div>
                    <div css='text-align: right;'>{transaction.id}</div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    css={`
                      display: grid;
                      grid-template-columns: 1fr auto;
                      border-bottom: 1pt solid ${(props) => props.theme.color.muted};
                      padding-bottom: 0.75rem;
                    `}
                  >
                    <div>Transaction status</div>
                    <div css='text-align: right;'>Completed</div>
                    <div>3rd Party fees</div>
                    <div css='text-align: right;'>2.50 EUR</div>
                    <div>Leikur fee</div>
                    <div css='text-align: right;'>1.50 EUR</div>
                  </div>
                  <div
                    css={`
                      display: grid;
                      grid-template-columns: 1fr auto;
                      border-bottom: 1pt solid ${(props) => props.theme.color.muted};
                      padding-bottom: 0.75rem;
                      padding-top: 0.25rem;
                    `}
                  >
                    <div>Total spent</div>
                    <div
                      css={`
                        font-family: ${(props) => props.theme.font.family.bold};
                        text-align: right;
                      `}
                    >
                      {currencyFormat.format(Math.abs(transaction.amount)) + '  ' + transaction.currency}
                    </div>
                  </div>
                  <div
                    css={`
                      display: grid;
                      grid-template-columns: 1fr auto;
                      padding-top: 0.25rem;
                    `}
                  >
                    <div>Transaction type</div>
                    <div css='text-align: right;'>{properName(transactionType)}</div>
                    <div>Date</div>
                    <div css='text-align: right;'>
                      {DateTime.fromISO(transaction.created_at).toFormat('d LLL yyyy')}
                    </div>
                    <div>Time (local)</div>
                    <div css='text-align: right;'>
                      {DateTime.fromISO(transaction.created_at).toLocaleString(DateTime.TIME_24_SIMPLE)}
                    </div>
                    <div>Transaction number</div>
                    <div css='text-align: right;'>{transaction.id}</div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div css='width: 100%;'>
            <div
              css={`
                font-size: ${(props) => props.theme.font.size.small};
                font-family: ${(props) => props.theme.font.family.bold};
                border-top: 1pt solid ${(props) => props.theme.color.successHighlight};
                ${onDesktop`
                  border: none;
                `}
              `}
            >
              Analytics Category
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StatementCentreTimeline = ({ transactions }) => {
  let displayedTransactions = {}
  transactions.sort((f, s) => DateTime.fromISO(f.created_at) < DateTime.fromISO(s.created_at))

  for (let tx in transactions) {
    displayedTransactions[getDate(transactions[tx].created_at)] = [
      ...(displayedTransactions[getDate(transactions[tx].created_at)] || []),
      transactions[tx],
    ]
  }

  return (
    <div
      css={`
        padding: 1rem 0 1rem 0.5rem;
        border-top: 2pt solid ${(props) => props.theme.color.successHighlight};
      `}
    >
      <div
        css={`
          border-left: 4px solid ${(props) => props.theme.color.white};
        `}
      >
        {Object.keys(displayedTransactions).map((date) => (
          <div key={date}>
            <div
              css={`
                font-size: ${(props) => props.theme.font.size.small};
                font-family: ${(props) => props.theme.font.family.bold};
                color: ${(props) => props.theme.color.primary};
                position: relative;

                ::before {
                  content: '';
                  width: 22px;
                  display: block;
                  height: 22px;
                  background-color: ${(props) => props.theme.color.primary};
                  border: 2pt solid ${(props) => props.theme.color.primary};
                  border-radius: 50%;
                  position: absolute;
                  left: calc(-13px);
                }
              `}
            >
              <div
                css={`
                  margin-left: 1rem;
                  padding-bottom: 0.5rem;
                `}
              >
                {date}
              </div>
            </div>
            {displayedTransactions[date].map((transaction, i) => (
              <TransactionItem
                key={transaction.id}
                transaction={transaction}
                isLastInGroup={i === displayedTransactions[date].length - 1}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default StatementCentreTimeline

import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { Container } from '../cardnoirStyledElements'
import LightboxContext from '../LightboxContext'
import MenuContext from '../MenuContext'
import AppFooter from './AppFooter'
import AppNavbar from './AppNavbar'

const AppWrapper = ({ children, title, appHeader }) => {
  const { lightbox} = useContext(LightboxContext)
  const { menuCollapsed } = useContext(MenuContext)

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AppNavbar />
      {lightbox && (
        <div
          css={`
            position: fixed;
            top: 3rem;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 11;
            display: flex;
            justify-items: center;
            padding: 1.5rem 0;
            overflow-y: auto;
          `}
        >
          <Container
            css={`
              width: unset;
            `}
          >
            {lightbox}
          </Container>
        </div>
      )}
      <div
        css={`
          filter: ${menuCollapsed && lightbox === undefined ? null : 'blur(5px)'};
          min-height: calc(100vh - 3rem);
          display: grid;
          grid-template-rows: auto 1fr auto;
        `}
      >
        {appHeader}
        {children}
        <AppFooter />
      </div>
    </>
  )
}

export default AppWrapper

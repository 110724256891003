import React from 'react'
import styled from 'styled-components'

const StyledCheckboxLabel = styled.label`
  display: flex;
  justify-content: start;
  align-items: start;
  cursor: pointer;
`
const StyledCheck = styled.svg`
  opacity: ${(props) => (props.checked ? 1 : 0)};
  fill: currentColor;
  color: ${({ theme }) => theme.color.success};
  pointer-events: none;
  position: relative;
  left: -5px;
  top: -6px;
  width: 30px;
`
const StyledCheckboxWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.color.textPrimary};
  border-radius: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;

  &:focus-within {
    border-color: ${({ theme }) => theme.color.primary};
  }
`

const Checkbox = ({
  id = 'checkbox',
  label,
  onChange,
  checked,
  name = 'checkbox',
  cssForLabel,
  cssForBox,
  cssForCheckIcon,
  ...props
}) => {
  return (
    <StyledCheckboxLabel {...props}>
      <input
        css={`
          opacity: 0;
          position: absolute;
        `}
        id={id}
        type='checkbox'
        name={name}
        onChange={(e) => onChange(e.target.checked)}
        checked={checked}
      />
      <StyledCheckboxWrapper css={cssForBox} checked={checked}>
        <StyledCheck checked={checked} viewBox='0 0 2500 2500' css={cssForCheckIcon}>
          <g>
            <path d='M123.1 1485.3c-61.26,-75.34 -49.84,-186.1 25.5,-247.36 75.34,-61.26 186.1,-49.84 247.36,25.5l454.07 558.99 1238.95 -1519.29c61.26,-75.34 172.02,-86.76 247.36,-25.5 75.34,61.26 86.76,172.02 25.5,247.36l-1373.89 1684.76c-7.88,10 -16.97,19.29 -27.26,27.66 -75.34,61.26 -186.1,49.84 -247.36,-25.5l-590.23 -726.62z' />
          </g>
        </StyledCheck>
      </StyledCheckboxWrapper>
      <div
        css={`
          user-select: none;
          line-height: 1.25;
          font-family: ${({ theme }) => theme.font.family.label};
          ${cssForLabel}
        `}
      >
        {label}
      </div>
    </StyledCheckboxLabel>
  )
}

export default Checkbox

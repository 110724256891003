import React from 'react'
import styled from 'styled-components'

import Dropdown, { OptionItem, OptionLabel } from './Dropdown'
import { countries } from './countries'
import Flag from './Flag'

const CustomLabel = styled(OptionLabel)`
  display: flex;
  padding: 0;
`

const CountryCodeDropdown = ({ label, value, filter, onChange, description, color, readOnly, ...rest }) => {
  const filteredList = filter && typeof filter === 'function' ? filter(countries) : countries
  const appendKey = (k) => onChange({ ...filteredList[k], key: k })

  if (value && value.key === undefined && value.alpha2code !== '') {
    // if we have the alpha2code of the country, but it's not a keyed entry from this component.
    const keyedCountryIndex = filteredList.findIndex((v) => v.alpha2code === value.alpha2code)
    if (keyedCountryIndex > -1) {
      value = { ...filteredList[keyedCountryIndex], key: keyedCountryIndex }
    }
  }

  return (
    <Dropdown
      {...rest}
      value={value?.key}
      displayValue={
        value && value.alpha2code ? (
          <CustomLabel
            color={'success'}
            css={`
              :hover {
                background-color: unset;
                color: unset;
              }
            `}
          >
            <Flag countryCode={value.alpha2code} />
            &nbsp;&nbsp;
            <span>
              {value.name}&nbsp;&nbsp;&nbsp;{value.alpha2code}
            </span>
          </CustomLabel>
        ) : (
          ''
        )
      }
      valueFieldFunction={(v, i) => i}
      list={filteredList}
      label={label}
      description={description}
      onChange={readOnly ? () => {} : appendKey}
      filterOption={(input, val) => val.name.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      optionLabelRenderFunction={(v) => (
        <CustomLabel color={color}>
          <Flag countryCode={v.alpha2code} />
          &nbsp;&nbsp;
          <span>
            {v.name}&nbsp;&nbsp;&nbsp;{v.alpha2code}
          </span>
        </CustomLabel>
      )}
      optionItemRenderFunction={(v) => (
        <OptionItem>
          <Flag countryCode={v.alpha2code} />
          &nbsp;&nbsp;
          <span>{v.name}</span>
        </OptionItem>
      )}
    />
  )
}

export default CountryCodeDropdown

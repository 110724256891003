import { createGlobalStyle } from 'styled-components'

/*  TODO - Clean rc-select css (leverage styled-components nesting ability) */
export const GlobalStyle = createGlobalStyle`
  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }
  
  .rc-select {
    position: relative;
    margin-bottom: 0.125rem;  
  }

  .rc-select-disabled,
  .rc-select-disabled input {
    cursor: not-allowed;
  }
  
  .rc-select-disabled .rc-select-selector {
    opacity: 0.3;
  }

  .rc-select-selector {
    display: flex;
    overflow: hidden;
    border: 1px solid ${({ theme }) => theme.color.muted};
    border-radius: 6px;
    padding-right: 1.6rem;
    padding-left: 0.65rem;
  }

  .rc-select-focused .rc-select-selector {
    border-color: ${({ theme }) => theme.color.primary};
    box-shadow: 0px 1px 0 0 ${({ theme }) => theme.color.primary}; 
  }

  
  .rc-invalid .rc-select-selector {
    border: 1px solid ${({ theme }) => theme.color.error};
  }
  
  .rc-select .rc-select-selection-search-input {
    appearance: none;
  }

  .rc-select .rc-select-selection-search-input::-webkit-search-cancel-button {
    display: none;
    appearance: none;
  }

  .rc-select-single .rc-select-selector {
    position: relative;
  }

  .rc-select-single .rc-select-selector .rc-select-selection-search {
    width: 100%;
  }

  .rc-select-single .rc-select-selector .rc-select-selection-search-input {
    width: 100%;
  }

  .rc-select-single .rc-select-selector .rc-select-selection-item {
    width: 100%;
    
    position: absolute;
    left: 0; right:0;
    top: 0; bottom: 0;
    
    display: flex; 
    align-items: center;
    
    padding: 0 1.4rem 0 0.65rem;
    
    pointer-events: none;

    font-size: ${({ theme }) => theme.font.size.normal};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    
    > * {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .rc-select-selector .rc-select-selection-placeholder {
    width: 100%; 
    white-space: nowrap;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    display: flex; 
    align-items: center;

    padding: 0rem 0.65rem;
    pointer-events: none;
    font-size: ${({ theme }) => theme.font.size.placeholder};
    font-style: italic;
    opacity: 0.7;
  }

  .rc-select-single:not(.rc-select-customize-input) .rc-select-selector .rc-select-selection-search-input {
    width: 100%;
  }
 
  .rc-select-multiple .rc-select-selector{
    display: flex;
    flex-wrap: wrap;
  }

  .rc-select-multiple .rc-select-selection-overflow {
    display: flex;
    flex-wrap: wrap;
    column-gap: 4px;
  }
  .rc-select-multiple .rc-select-selection-overflow-item {
    height: 30px;
    display: flex;
    align-items: center;
  }

  .rc-select-multiple .rc-select-selector .rc-select-selection-item {
    display: flex;
    align-items: center;
    padding: 0px 4px;
    border-radius: 6px;
    background-color: #f9fcf9;
  }

  .rc-select-multiple .rc-select-selector .rc-select-selection-item .rc-select-selection-item-content { 
    display: flex; 
    align-items: center;
  }

  .rc-select-multiple .rc-select-selector .rc-select-selection-item-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .rc-select-multiple .rc-select-selector .rc-select-selection-search {
    position: relative;
  }

  .rc-select-multiple .rc-select-selector .rc-select-selection-search-mirror {
    position: absolute;
    z-index: 100;
    white-space: nowrap;
    position: none;
    left: 0;
    top: 0;
    visibility: hidden;
  }

  .rc-select-multiple .rc-select-selector .rc-select-selection-search-input {
    border: none;
    outline: none;
    width: 100%;
    min-width: 0;
  }

  .rc-select-multiple .rc-select-selection-item-remove{
    line-height: 1;
    font-size:1.5rem;
    padding-left: 0.4rem; 

    > span {
      display: inline-block;
      height: 100%;
    }
  }

  .rc-select-multiple .rc-select-selection-overflow-item.rc-select-selection-overflow-item-suffix:not(:first-child) {
    position: absolute;
  }

  .rc-select-multiple.rc-select-open .rc-select-selection-overflow-item.rc-select-selection-overflow-item-suffix {
    position: static;
  }

  .rc-select-allow-clear.rc-select-multiple .rc-select-selector {
    padding-right: 20px;
  }

  .rc-select-allow-clear .rc-select-clear {
    position: absolute;
    right: 24px;
    top: 6px;
  }

  .rc-select-show-arrow .rc-select-arrow {
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 2px;
  }

  .rc-select-show-arrow .rc-select-arrow-icon::after {
    content: '';
    border: 6px solid transparent;
    width: 0;
    height: 0;
    display: inline-block;
    border-top: 9px solid ${({ theme }) => theme.color.primary};;
    transform: translateY(10px);
  }

  .rc-select-dropdown {
    border: 1pt solid ${({ theme }) => theme.color.muted};
    min-height: 100px;
    position: absolute;
    background: ${({ theme }) => theme.color.background};
    margin-top: -4px;
    border-radius: 6px;
    overflow: hidden;
    z-index: 50;
  }

  .rc-select-dropdown-hidden {
    display: none;
  }

  .rc-select-item {
    color: ${({ theme }) => theme.color.textPrimary};
  }

  .rc-select-item-option {
    position: relative;
  }

  .rc-select-item-option-active {
    background-color: ${({ theme }) => theme.color.dropdownSelectedBackground};
  }

  .rc-select-item-option-active {
    color: ${({ theme }) => theme.color.dropdownSelectedText};
  }

  .rc-select-item-option-state-icon {
    display: none;
  }

  .rc-select-item-option-disabled {
    color: #999;
  }
  .rc-select-item-empty {
    text-align: center;
    color: #999;
  }

  input.rc-select-selection-search-input {
    border: none;
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 0;
    background-color: ${({ theme }) => theme.color.background};
    color: ${({ theme }) => theme.color.textPrimary};
    height: 1.875rem;
    line-height: 1.875rem;
    min-width: 130px;
    font-size: ${({ theme }) => theme.font.size.normal};
  }


  input.rc-select-selection-search-input:focus,
  input.rc-select-selection-search-input:focus-within {
    outline: none;
  }

  /* .rc-select-show-arrow.rc-select-loading .rc-select-arrow-icon::after {
    box-sizing: border-box;
    width: 13px;
    height: 13px;
    border-radius: 100%;
    border: 2px solid ${({ theme }) => theme.color.primary};;
    border-top-color: transparent;
    border-bottom-color: transparent;
    transform: none;
    margin-top: 4px;
    animation: rcSelectLoadingIcon 0.5s infinite;
  } */

  
  .rc-select-item-group {
    color: #999;
    font-weight: bold;
    font-size: 80%;
  }
  .rc-select-item-option {
    position: relative;
  }
  .rc-select-item-option-grouped {
    padding-left: 24px;
  }

  .rc-select-selection__choice-zoom {
    transition: all 0.3s;
  }
  .rc-select-selection__choice-zoom-appear {
    opacity: 0;
    transform: scale(0.5);
  }
  .rc-select-selection__choice-zoom-appear.rc-select-selection__choice-zoom-appear-active {
    opacity: 1;
    transform: scale(1);
  }
  .rc-select-selection__choice-zoom-leave {
    opacity: 1;
    transform: scale(1);
  }
  .rc-select-selection__choice-zoom-leave.rc-select-selection__choice-zoom-leave-active {
    opacity: 0;
    transform: scale(0.5);
  }
  .rc-select-dropdown-slide-up-enter,
  .rc-select-dropdown-slide-up-appear {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-play-state: paused;
  }
  .rc-select-dropdown-slide-up-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    opacity: 1;
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-play-state: paused;
  }
  .rc-select-dropdown-slide-up-enter.rc-select-dropdown-slide-up-enter-active.rc-select-dropdown-placement-bottomLeft,
  .rc-select-dropdown-slide-up-appear.rc-select-dropdown-slide-up-appear-active.rc-select-dropdown-placement-bottomLeft {
    animation-name: rcSelectDropdownSlideUpIn;
    animation-play-state: running;
  }
  .rc-select-dropdown-slide-up-leave.rc-select-dropdown-slide-up-leave-active.rc-select-dropdown-placement-bottomLeft {
    animation-name: rcSelectDropdownSlideUpOut;
    animation-play-state: running;
  }
  .rc-select-dropdown-slide-up-enter.rc-select-dropdown-slide-up-enter-active.rc-select-dropdown-placement-topLeft,
  .rc-select-dropdown-slide-up-appear.rc-select-dropdown-slide-up-appear-active.rc-select-dropdown-placement-topLeft {
    animation-name: rcSelectDropdownSlideDownIn;
    animation-play-state: running;
  }
  .rc-select-dropdown-slide-up-leave.rc-select-dropdown-slide-up-leave-active.rc-select-dropdown-placement-topLeft {
    animation-name: rcSelectDropdownSlideDownOut;
    animation-play-state: running;
  }

  @keyframes rcSelectDropdownSlideUpIn {
    0% {
      opacity: 0;
      transform-origin: 0% 0%;
      transform: scaleY(0);
    }
    100% {
      opacity: 1;
      transform-origin: 0% 0%;
      transform: scaleY(1);
    }
  }
  
  @keyframes rcSelectDropdownSlideUpOut {
    0% {
      opacity: 1;
      transform-origin: 0% 0%;
      transform: scaleY(1);
    }
    100% {
      opacity: 0;
      transform-origin: 0% 0%;
      transform: scaleY(0);
    }
  }

  @keyframes rcSelectLoadingIcon {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

`

/**
 * This object includes all the props referenced by the common components. You can extend/override these for your use
 */
export const basicTheme = {
  color: {
    primary: '#fbb03b',
    success: '#72bb52',
    error: '#ed1c24',
    muted: '#c0c0c0',
    background: '#333333',
    backgroundMuted: '#444444',
    textPrimary: '#ffffff',
    textSecondary: '#c0c0c0',
    label: '#ffffff',
    input: '#fbb03b',
    dropdownSelectedBackground: '#444444',
    dropdownSelectedText: '#fbb03b',
    buttonDisabled: '#666666',
    unset: 'unset',
    transparent: 'transparent', // used to remove color from elements that have a default value set
  },
  font: {
    size: {
      normal: '1rem',
      small: '0.875rem',
      extraSmall: '0.75rem',
      placeholder: '0.813rem',
      buttonTitle: '1.15rem',
      label: '1rem',
    },
    family: {
      regular: 'sans-serif',
      menuHeader: 'sans-serif',
      buttonTitleFontFamily: 'sans-serif',
      label: 'sans-serif',
    },
  },
}

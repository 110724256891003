import upd from 'immer'

export default (session, action) => {
  const nextState = upd(session, (draft) => {
    switch (action.type) {
      case 'updateTransactions':
        // TODO: don't forget to remove this
        if (action.user.accounts && action.user.accounts[0]) {
          draft.transactions = [...draft.transactions.map((t) => ({ ...t, account_id: action.user.accounts[0].id }))]
        }
        break
      case 'setUser':
        draft.user = action.user
        break
      case 'resetLastSteps':
        draft.user.status = 'onboarding_profile_confirmed'
        break
      case 'addBeneficiary':
        draft.beneficiaries = [...draft.beneficiaries, action]
        break
      case 'addTransaction':
        draft.transactions = [...draft.transactions, action]
        break
    }
  })
  localStorage.setItem('card-noir-session', JSON.stringify(nextState))
  return nextState
}

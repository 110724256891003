import parsePhoneNumber from 'libphonenumber-js'
import { DateTime } from 'luxon'
import React, { useContext, useEffect, useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import {
  AddressInput,
  Button,
  Checkbox,
  CountryCodeDropdown,
  Input,
  PageEncryptedReminder,
  PhoneNumberInput,
  SimpleDatePicker,
  DisplayOnlyText,
  ErrorMessage,
  InputGrid,
  onStringChange,
} from 'stream-ui'
import styled from 'styled-components'
import API from '../api'
import StampImage from '../assets/CardNoir_Stamp.png'
import FASaveIcon from '../assets/icons/FA_save.svg'
import EditIcon from '../assets/icons/Icon_Card_Name_Edit.svg'
import CloseIcon from '../assets/icons/Icon_Close_Delete_No.svg'
import {
  buttonStyles,
  ButtonWrapper,
  Container,
  Header,
  inputGridPaddingX,
  paddingBottom,
} from '../cardnoirStyledElements'
import Footer from '../layout/Footer'
import Navbar from '../layout/Navbar'
import SessionContext from '../SessionContext'
import SiderLayout from './SiderLayout'
import SignUpSteps from './SignUpSteps'
import CustomErrorMessage from '../ErrorMessage'

const StyledFAPenIcon = styled(EditIcon)`
  fill: ${({ theme }) => theme.color.primary};
  height: 17px;
  padding: 3px 0px;
  margin-left: 4px;
`
const StyledFASaveIcon = styled(FASaveIcon)`
  fill: ${({ theme }) => theme.color.success};
  height: 17px;
  padding: 2px 0px;
  margin-left: 4px;
`
const StyledFATimesIcon = styled(CloseIcon)`
  fill: ${({ theme }) => theme.color.error};
  height: 17px;
  padding: 2px 0px;
  margin-left: 4px;
`
const SectionHeaderButton = styled.button`
  padding: 0.35rem 0.25rem 0.35rem 0.5rem;
  font-family: ${({ theme }) => theme.font.family.bold};
  font-size: calc(0.9 * ${({ theme }) => theme.font.size.normal});
  display: inline-flex;
  cursor: pointer;
  letter-spacing: 3px;
  margin-right: 1px;
  justify-content: center;
  align-items: center;
  color: ${({ theme, disabled }) => (disabled ? theme.color.muted : theme.color.primary)};
  background: ${({ theme }) => theme.color.background};
  border-style: solid;
  border-color: ${({ theme }) => theme.color.primary};
  border-width: 2px;
  height: 32px;
  margin: 1px;

  @media (min-width: 740px) {
    height: 24px;
    padding: 0.1rem 0.25rem 0.1rem 0.5rem;
    border-color: ${({ theme }) => theme.color.background};
  }
`

const EditButton = styled(SectionHeaderButton)`
  border-radius: 6px;
  &:hover {
    border-left-color: ${({ theme }) => theme.color.primary};
    background: ${({ theme }) => theme.color.primary};
    color: ${({ theme }) => theme.color.background};

    ${StyledFAPenIcon} {
      fill: ${({ theme }) => theme.color.background};
    }
  }

  @media (min-width: 740px) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    &:hover {
      border-left-color: ${({ theme }) => theme.color.background};
    }
  }
`
const SaveButton = styled(SectionHeaderButton)`
  width: 120px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border-color: ${({ theme, disabled }) => (disabled ? theme.color.muted : theme.color.primary)};

  &:hover {
    color: ${({ theme, disabled }) => (disabled ? theme.color.muted : theme.color.success)};
  }

  ${StyledFASaveIcon} {
    fill: ${({ theme, disabled }) => (disabled ? theme.color.muted : theme.color.success)};
  }

  @media (min-width: 740px) {
    border-color: ${({ theme }) => theme.color.background};
  }
`

const CancelButton = styled(SectionHeaderButton)`
  width: 120px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;

  &:hover {
    color: ${({ theme }) => theme.color.error};
  }

  @media (min-width: 740px) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

const SectionHeaderText = styled.div`
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 1rem;
  color: ${({ theme }) => theme.color.background};
  font-family: ${({ theme }) => theme.font.family.bold};
  font-size: calc(0.95 * ${({ theme }) => theme.font.size.normal});
`
const SectionHeaderButtons = styled.div`
  border-radius: 6px;

  @media (min-width: 740px) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-direction: column;

  ${SectionHeaderText} {
    width: 100%;
    background-color: ${({ theme }) => theme.color.primary};
  }

  ${SectionHeaderButtons} {
    margin-top: 1rem;
  }

  @media (min-width: 740px) {
    flex-direction: row;
    ${SectionHeaderText} {
      width: unset;
      flex-grow: 1;
    }
    ${SectionHeaderButtons} {
      background-color: ${({ theme }) => theme.color.primary};
      margin-top: unset;
    }
  }

  @media (min-width: 1100px) {
    div {
      padding-left: 2rem;
    }
  }
`

const SectionButtons = ({ editingStatus, toggler, isValid, cancelEdit, startEdit, ...props }) => (
  <SectionHeaderButtons {...props}>
    {editingStatus ? (
      <>
        <CancelButton onClick={() => cancelEdit(toggler)}>
          CANCEL <StyledFATimesIcon />
        </CancelButton>
        <SaveButton onClick={() => toggler(false)} disabled={!isValid}>
          SAVE <StyledFASaveIcon />
        </SaveButton>
      </>
    ) : (
      <EditButton onClick={() => startEdit(toggler)}>
        EDIT THIS SECTION <StyledFAPenIcon />
      </EditButton>
    )}
  </SectionHeaderButtons>
)
const ConfirmOrder = () => {
  const navigate = useNavigate()
  const { session, dispatch } = useContext(SessionContext)

  const [firstName, setFirstName] = useState(session.user.first_name)
  const [lastName, setLastName] = useState(session.user.last_name)
  const [nationality, setNationality] = useState({ alpha2code: session.user.nationality[0] })
  const [countryOfBirth, setCountryOfBirth] = useState({ alpha2code: session.user.country_of_birth })
  const parsedPhoneNumber = parsePhoneNumber(session.user.phone_number)
  const [phoneNumber, setPhoneNumber] = useState({
    phoneNumber: parsedPhoneNumber.nationalNumber,
    country: {
      alpha2code: parsedPhoneNumber.country,
    },
  })
  const [dateOfBirth, setDateOfBirth] = useState(session.user.date_of_birth)
  const [physicalAddress, setPhysicalAddress] = useState({
    country: {
      alpha2code: session.user.address.country_code,
    },
    streetName: session.user.address.street1,
    buildingOrHouseNameOrSuiteNumber: session.user.address.street2,
    city: session.user.address.city,
    postalCode: session.user.address.postal_code,
    isValid: true,
  })
  const shippingAddress = session.user.shipping_address
    ? {
        country: {
          alpha2code: session.user.shipping_address.country_code,
        },
        streetName: session.user.shipping_address.street1,
        buildingOrHouseNameOrSuiteNumber: session.user.shipping_address.street2,
        city: session.user.shipping_address.city,
        postalCode: session.user.shipping_address.postal_code,
        isValid: true,
      }
    : undefined
  const [agreeToTOS, setAgreeToTOS] = useState(false)
  const [agreeToPromotions, setAgreeToPromotions] = useState(false)
  const [editingPersonalDetails, setEditingPersonalDetails] = useState(false)
  const [editingPermanentAddress, setEditingPermanentAddress] = useState(false)
  const [hideErrors, setHideErrors] = useState(true)

  const [submitting, setSubmitting] = useState(false)
  const [confirmOrderError, setConfirmOrderError] = useState()

  const [beforeEdit, setBeforeEdit] = useState()

  const startEdit = (toggler) => {
    setBeforeEdit({
      firstName,
      lastName,
      phoneNumber,
      dateOfBirth,
      nationality,
      countryOfBirth,
      physicalAddress,
    })
    toggler(true)
  }

  const cancelEdit = (toggler) => {
    setFirstName(beforeEdit.firstName)
    setLastName(beforeEdit.lastName)
    setPhoneNumber(beforeEdit.phoneNumber)
    setDateOfBirth(beforeEdit.dateOfBirth)
    setNationality(beforeEdit.nationality)
    setCountryOfBirth(beforeEdit.countryOfBirth)
    setPhysicalAddress(beforeEdit.physicalAddress)
    toggler(false)
  }

  const isValid =
    firstName &&
    lastName &&
    phoneNumber &&
    nationality &&
    countryOfBirth &&
    physicalAddress &&
    firstName !== '' &&
    lastName !== '' &&
    physicalAddress.isValid &&
    dateOfBirth

  let confirmOrder = async () => {
    if (!submitting) {
      if (isValid && agreeToTOS) {
        setConfirmOrderError()
        setSubmitting(true) // hack to disable signup button when processing
        try {
          const individual = await API.confirmOrder({
            id: session.user.id,
            firstName,
            lastName,
            nationality,
            countryOfBirth,
            phoneNumber,
            dateOfBirth,
            physicalAddress,
            shippingAddress,
            agreeToTOS,
            agreeToPromotions,
          })

          setSubmitting(false)

          dispatch({
            type: 'setUser',
            user: individual,
          })
        } catch (err) {
          setSubmitting(false)
          setConfirmOrderError(err)
        }
      } else {
        window.scrollTo(0, document.getElementById('top-of-form').getBoundingClientRect().top)
        setHideErrors(false)
      }
    }
  }

  /** FIXME: Identify a better way to handle waiting for dispatch to complete.
   * See CompleteSignUp.jsx for more info
   */
  useEffect(() => {
    if (isValid && agreeToTOS) {
      navigate('/sign-up/last-steps')
    }
  }, [session])

  return (
    <>
      <Helmet>Card Noir - Confirm Your Order Details</Helmet>
      <Navbar smaller={true}/>
      <Header>Review and confirm your application details</Header>
      <Container>
        <SignUpSteps step={3} />
        <SiderLayout
          extraInfo={
            <div
              css={`
                color: ${({ theme }) => theme.color.primary};
                font-variant-numeric: lining-nums;
              `}
            >
              Annual fee is 33 EUR.
              <br />
              Account initiation and minimum sign up period of 3 years:
              <br />
              <br />
              <div
                css={`
                  font-family: ${({ theme }) => theme.font.family.bold};
                `}
              >
                TOTAL FEE DUE TODAY<span css='float:right;'>EUR 99</span>
              </div>
            </div>
          }
        >
          <SectionHeader id='top-of-form'>
            <SectionHeaderText>Your personal details</SectionHeaderText>
            <SectionButtons
              startEdit={startEdit}
              cancelEdit={cancelEdit}
              editingStatus={editingPersonalDetails}
              toggler={setEditingPersonalDetails}
              isValid={isValid}
            />
          </SectionHeader>
          <InputGrid
            css={`
              ${inputGridPaddingX}${paddingBottom}
            `}
          >
            <Input
              label='First name'
              id='firstname'
              description='First name as it appears on your ID'
              value={firstName}
              onChange={onStringChange(setFirstName)}
              errorMessage={firstName === '' ? 'This field is required' : ''}
              displayOnly={!editingPersonalDetails}
            />
            <Input
              label='Last name'
              id='lastname'
              description='Last name as it appears on your ID'
              value={lastName}
              onChange={onStringChange(setLastName)}
              errorMessage={lastName === '' ? 'This field is required' : ''}
              displayOnly={!editingPersonalDetails}
            />
            <Input
              type='email'
              label='Email address'
              description='Your email address has already been verified'
              id='email'
              value={session.user.email}
              displayOnly={!editingPersonalDetails}
              readOnly={true}
              color='success'
            />
            <PhoneNumberInput
              label='Mobile phone number'
              description='A verification SMS will be sent to this phone number'
              value={phoneNumber}
              onChange={setPhoneNumber}
              required={true}
              displayOnly={!editingPersonalDetails}
            />
            <CountryCodeDropdown
              label='Country of birth'
              description='Country where you were born.'
              value={countryOfBirth}
              onChange={setCountryOfBirth}
              displayOnly={!editingPersonalDetails}
            />
            <CountryCodeDropdown
              label='Nationality'
              description='Country in which you hold citizenship.'
              value={nationality}
              onChange={setNationality}
              displayOnly={!editingPersonalDetails}
            />
            <SimpleDatePicker
              value={dateOfBirth}
              label='Date of birth'
              description='Enter your date of birth. Format: YYYY-MM-DD'
              required={true}
              validator={(current) =>
                current <= DateTime.local().startOf('day') ? '' : 'Date of birth cannot be a future date'
              }
              onChange={setDateOfBirth}
              displayOnly={!editingPersonalDetails}
            />
          </InputGrid>
          <SectionHeader>
            <SectionHeaderText>Your permanent address</SectionHeaderText>
            <SectionButtons
              startEdit={startEdit}
              cancelEdit={cancelEdit}
              editingStatus={editingPermanentAddress}
              toggler={setEditingPermanentAddress}
              isValid={isValid}
            />
          </SectionHeader>
          <div
            css={`
              ${paddingBottom}${inputGridPaddingX}
            `}
          >
            <AddressInput
              label='Permanent Address'
              description='Your permanent address which will be verified later'
              value={physicalAddress}
              onChange={setPhysicalAddress}
              displayOnly={!editingPermanentAddress}
              required={true}
            />
          </div>
          {session.user.promo_code && session.user.promo_code !== '' && (
            <>
              <SectionHeader>
                <SectionHeaderText>Your new card&apos;s address will be listed with the travel club</SectionHeaderText>
              </SectionHeader>
              <div
                css={`
                  ${paddingBottom}${inputGridPaddingX}
                `}
              >
                <div css='margin-bottom: 0.75rem;'>
                  Address of the{' '}
                  <span
                    css={`
                      height: 1.5rem;
                      font-family: ${({ theme }) => theme.font.family.bold};
                    `}
                  >
                    Excelsior Travel Club
                  </span>
                </div>
                <div
                  css={`
                    border: ${({ theme }) => theme.border.accent};
                    border-radius: 6px;
                    padding-left: 1rem;
                    position: relative;
                    background-color: #444444;

                    @media (min-width: 450px) {
                      margin-left: 1rem;
                      width: 450px;
                    }
                  `}
                >
                  <br />
                  <br />
                  <br />

                  <div
                    css={`
                      @media (min-width: 400px) {
                        display: none;
                      }
                    `}
                  >
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                  <div
                    css={`
                      height: 1.5rem;
                      font-family: ${({ theme }) => theme.font.family.bold};
                    `}
                  >
                    Excelsior Travel Club
                  </div>
                  {/* <CustomDisplayOnlyText>{shippingAddress.country.name}</CustomDisplayOnlyText> */}
                  <DisplayOnlyText
                    css={`
                      color: ${({ theme }) => theme.color.textPrimary};
                    `}
                  >
                    {shippingAddress.buildingOrHouseNameOrSuiteNumber}
                  </DisplayOnlyText>
                  <DisplayOnlyText
                    css={`
                      color: ${({ theme }) => theme.color.textPrimary};
                    `}
                  >
                    {shippingAddress.streetName}
                  </DisplayOnlyText>
                  <DisplayOnlyText
                    css={`
                      color: ${({ theme }) => theme.color.textPrimary};
                    `}
                  >
                    {shippingAddress.city}
                  </DisplayOnlyText>
                  <DisplayOnlyText
                    css={`
                      color: ${({ theme }) => theme.color.textPrimary};
                    `}
                  >
                    {shippingAddress.postalCode}
                  </DisplayOnlyText>
                  <br />
                  <div css='position: absolute; top: 10px; right: -2px;'>
                    <img src={StampImage} css='height: 100px; width: 150px;' />
                    <ReactCountryFlag
                      countryCode={shippingAddress.country.alpha2code}
                      svg
                      style={{
                        position: 'absolute',
                        top: '18px',
                        left: '18px',
                        objectFit: 'cover',
                        height: '40px',
                        width: '39px',
                        borderRadius: '20px',
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          <SectionHeader>
            <SectionHeaderText>Terms and conditions for your new card</SectionHeaderText>
          </SectionHeader>
          <div
            css={`
              ${inputGridPaddingX}
              padding-bottom: 4rem;
            `}
          >
            <Checkbox
              css={`
                margin-bottom: ${hideErrors || agreeToTOS ? '2rem' : '0.5rem'};
              `}
              label={
                <>
                  I agree to the{' '}
                  <a
                    css={`
                      color: ${({ theme }) => theme.color.textPrimary};
                    `}
                    href='#'
                  >
                    Card Noir Terms and Conditions
                  </a>{' '}
                  and the following fees: Pre-payment of a 3 year Card Noir membership fee of 99 EUR.
                </>
              }
              checked={agreeToTOS}
              onChange={setAgreeToTOS}
            />
            <ErrorMessage css={hideErrors || agreeToTOS ? 'display:none;' : 'margin-bottom: 2rem;'}>
              Please agree to the terms and conditions to proceed.
            </ErrorMessage>
            <Checkbox
              css={`
                margin-bottom: 2rem;
              `}
              label={
                <>
                  I agree to Card Noir sending me information about product updates, benefits and promotions. I agree to
                  my personal data being processed by Card Noir and selected third party services for this purpose. I
                  can revoke my consent at any time.
                </>
              }
              checked={agreeToPromotions}
              onChange={setAgreeToPromotions}
            />
            <div>
              Our{' '}
              <a
                css={`
                  color: ${({ theme }) => theme.color.textPrimary};
                `}
                href='#'
              >
                Privacy Policy
              </a>{' '}
              applies to all applications.
              <br />
              <br />
              Your agreement with us starts when we email you to confirm that your application has been approved. The
              pre-paid annual fee will not be refunded should your application be rejected.
            </div>
          </div>

          <CustomErrorMessage message={confirmOrderError} css={'text-align:right; margin-bottom: 1rem;'} />

          <ButtonWrapper>
            <PageEncryptedReminder
              css={`
                @media (min-width: 1100px) {
                  margin: 0;
                  padding: 0;
                }
              `}
            />
            <div>
              <Button
                onClick={confirmOrder}
                title='SAVE AND CREATE PROFILE'
                {...buttonStyles.formButton}
                borderColor={(isValid && agreeToTOS) || submitting ? 'success' : 'buttonDisabled'}
                titleColor={(isValid && agreeToTOS) || submitting ? 'success' : 'buttonDisabled'}
              />
            </div>
          </ButtonWrapper>
        </SiderLayout>
      </Container>
      <br />
      <br />
      <br />
      <Footer />
    </>
  )
}

export default ConfirmOrder

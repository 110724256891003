import { DateTime } from 'luxon'
import { useState, createContext } from 'react'

// copied and adapted from fxbet

/** Hook that manages user authentication, and exposes current
 * authentication state, as well as function(s) to manage this
 * state. Typically used by top-level components that wish to
 * manage an authentication context - i.e. usually at the top-level
 * of the app. The return value is an object suitable to use as the
 * value for a `AuthContext.Provider`.
 */

/**
 * @returns {[string, (jwt: string) => void]}
 */
export const useAuthManager = () => {
  // For authentication, we store / retrieve simple JWT (token)
  // in both localstorage and cookies. Changes to these are tracked
  // in top-level application state though
  //

  const t = sessionStorage.getItem('card-noir-token')
  const l = sessionStorage.getItem('card-noir-last-logged-in')
  const [token, setToken] = useState(t)
  const [lastLoggedIn, setLastLoggedIn] = useState(l)

  const storeToken = (jwt) => {
    sessionStorage.setItem('card-noir-token', jwt)
    const l = DateTime.now().toFormat('dd LLLL yyyy, HH:mm a')
    setLastLoggedIn(l)
    sessionStorage.setItem('card-noir-last-logged-in', l)
    setToken(jwt)
  }

  return [token, storeToken, lastLoggedIn]
}

/**
 * @type {{ token: ?string, setToken: (jwt: string) => void}}
 */
const defaultContext = {
  token: null,
  lastLoggedIn: null,
  setToken: () => {},
}

export default createContext(defaultContext)

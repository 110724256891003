import React from 'react'
import ChatIcon from './assets/icons/Icon_Contact_Us.svg'
import { Button } from 'stream-ui'
import { onDesktop } from './cardnoirStyledElements'

const ChatButton = ({ className, ...props }) => {
  return (
    <div css='position: relative;' className={className}>
      <button
        css={`
          position: fixed;
          bottom: 7rem;
          right: 0;
          width: 5rem;
          height: 5rem;

          padding: 0;
          margin: 0;
          border-style: none;
          display: flex;
          flex-direction: column;
          background: unset;
          cursor: pointer;

          display: none;

          ${onDesktop`
            display: flex;
          `}

          :hover {
            svg {
              filter: drop-shadow(0px 0px 1px ${(props) => props.theme.color.primary}a0);
            }
          }
        `}
        {...props}
      >
        <div
          css={`
            height: 50%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: ${(props) => props.theme.color.background};
            border-radius: 5px 0px 0px 0px;
            border-top: 1pt solid ${(props) => props.theme.color.physicalCardHighlight};
            border-bottom: 1pt solid ${(props) => props.theme.color.physicalCardHighlight};
            border-left: 1pt solid ${(props) => props.theme.color.physicalCardHighlight};
          `}
        >
          <ChatIcon
            css={`
              height: 30px;
              fill: ${(props) => props.theme.color.primary};
            `}
          />
        </div>
        <div
          css={`
            display: flex;
            height: 50%;
            width: 100%;
            justify-content: center;
            align-items: center;
            background-color: ${(props) => props.theme.color.physicalCardHighlight};
            color: ${(props) => props.theme.color.background};
            line-height: 1.1;
            font-size: 0.875rem;
            font-family: ${(props) => props.theme.font.family.bold};
            border-radius: 0px 0px 0px 5px;
          `}
        >
          Contact
          <br />
          Support
        </div>
      </button>
      <Button
        buttonType='normal'
        borderColor='primaryHighlight'
        borderRadius={'5px'}
        icon={<ChatIcon />}
        iconColor='primary'
        title='Help, Contact Us &amp; Feedback'
        description='Reach out for help, and getting in touch'
        titleColor='textPrimary'
        css={`
          width: 100%;
          justify-content: center;
          ${onDesktop`
            display: none;
          `}
        `}
        paddingInline='0.5rem'
        textAlign='center'
      />
    </div>
  )
}

export default ChatButton

import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { HOSTED_PAYMENT_PAGE_BASE_URL } from '../../api'
import { Header } from '../../cardnoirStyledElements'

const Iframe = styled.iframe`
  width: ${(props) => (props.$custVeri ? '100%' : '640px')};
  height: ${(props) => (props.$custVeri ? '100%' : '500px')};
  /* height: 530px; */
  border: none;
  margin-inline: auto;
  display: block;

  /* position: ${(props) => (props.$custVeri ? 'fixed' : null)}; */
  /* top: ${(props) => (props.$custVeri ? '2rem' : props.$top + 'px')}; */
  /* bottom: ${(props) => (props.$custVeri ? '2rem' : '50%')}; */
  /* left: ${(props) => (props.$custVeri ? '2rem' : props.$left + 'px')}; */
  /* right: ${(props) => (props.$custVeri ? '2rem' : '50%')}; */
  transition: all 0.5s ease-in-out;
  border-radius: ${(props) => (props.$custVeri ? '5px' : null)};
  box-shadow: 0px 0px 1px 3px ${(props) => (props.$custVeri ? '#ffffff4c' : 'transparent')};

  background-color: ${(props) => props.theme.color.background};
`

// const HideOverflow = createGlobalStyle`
//   html {
//     overflow: hidden;
//   }
// `
const Payment = ({
  paymentIntent,
  setPaymentSuccess,
  setPaymentFailure /*setPaymentCustomerVerificationRequired*/,
}) => {
  const [data, setData] = useState({ done: false })
  const iframeRef = useRef()
  useEffect(() => {
    const handler = (event) => {
      if (event.origin !== HOSTED_PAYMENT_PAGE_BASE_URL) return
      if (event?.data?.message?.status === 'success') {
        setData({
          ...event.data.message,
        })
        setTimeout(setPaymentSuccess(), 1000)
      } else if (event?.data?.message?.status === 'failed') {
        setData({
          ...event.data.message,
        })
        setTimeout(() => setPaymentFailure('Your transaction was declined.'), 2000)
      } else {
        setData({
          ...event.data.message,
        })
      }
    }
    window.addEventListener('message', handler)
    // clean up
    return () => window.removeEventListener('message', handler)
  }, [])

  // useEffect(() => {
  //   if (data.status === 'cust_verification_required') {
  //     setPaymentCustomerVerificationRequired(true)
  //   } else {
  //     setPaymentCustomerVerificationRequired(false)
  //   }
  // }, [data])

  console.log(data)
  return (
    <>
      {/* {data.status === 'cust_verification_required' && <HideOverflow />} */}
      <Header css='margin-top: 0;'>Pay your membership fee</Header>
      <p css='width: 80%; text-align: center; margin-top: -0.8rem; margin-bottom: 3rem; margin-inline: auto;'>
        Your membership fee is due today. Once your application is approved you will immediately be able to load
        additional funds to your new card.
      </p>
      {paymentIntent?.url && (
        <div css='width: 100%; height: 500px;'>
          <Iframe
            ref={iframeRef}
            $top={iframeRef?.current?.offsetTop + iframeRef?.current?.offsetParent?.offsetTop}
            $left={iframeRef?.current?.offsetLeft + iframeRef?.current?.offsetParent?.offsetLeft}
            $custVeri={data.status === 'cust_verification_required'}
            // src='http://localhost:1234/tockedMoken?maxWidth=700px'
            src={paymentIntent?.url}
            sandbox='allow-forms allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox'
          />
        </div>
      )}
    </>
  )
}

export default Payment

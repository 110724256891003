import React, { useContext } from 'react'
import { Button } from 'stream-ui'
import LightboxContext from '../../LightboxContext'

const CancelButtonFooter = ({ onClick }) => {
  const { setLightbox } = useContext(LightboxContext)

  return (
    <div css='display: flex; justify-content: end;'>
      <Button
        buttonType='normal'
        borderColor='errorHighlight'
        borderRadius={'5px'}
        title='CANCEL'
        titleColor='error'
        titleFontSize='normal'
        textAlign='center'
        paddingInline='3.5rem'
        onClick={onClick ? onClick : () => setLightbox(undefined)}
      />
    </div>
  )
}
export default CancelButtonFooter

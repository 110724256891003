import { darken } from 'polished'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Button from './Button'

const DownArrowIcon = (props) => (
  <svg
    {...props}
    css={`
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
      cursor: pointer;
      position: absolute;
      top: 1.75rem;
      right: 1rem;
      height: 0.625rem;
      width: 1.125rem;
      transform: ${(props) => (props.isOpened ? 'rotate(90deg)' : 'rotate(270deg)')};
      transition-duration: 0.5s;
      transition-timing-function: ease-in-out;
      transition-delay: 0s;
      transition-property: transform;
      fill: ${(props) => props.theme.color.primary};

      @media (min-width: 575px) {
        transform: ${(props) => (props.isOpened ? 'rotate(180deg)' : 'initial')};
      }
    `}
    viewBox='0 0 3598.32 2187.22'
  >
    <g id='Layer_x0020_1'>
      <path d='M116.69 531.5c477.53,499.16 980.71,1068.9 1474.12,1540.91 112.06,117.14 299.55,121.29 416.69,9.22 480.64,-459.75 1002.13,-1065.96 1474.13,-1559.36 112.06,-117.14 107.91,-304.63 -9.23,-416.7 -117.14,-112.05 -304.65,-107.92 -416.72,9.24l-1260.94 1318.07 -1252.11 -1308.84c-112.07,-117.16 -299.58,-121.3 -416.72,-9.24 -117.14,112.06 -121.29,299.55 -9.22,416.7z' />
    </g>
  </svg>
)
const CloseIcon = (props) => (
  <svg
    {...props}
    css={`
      shape-rendering: geometricPrecision;
      text-rendering: geometricPrecision;
      image-rendering: optimizeQuality;
      fill-rule: evenodd;
      clip-rule: evenodd;
      cursor: pointer;
      position: absolute;
      top: 4px;
      right: 12px;
      height: 24px;
      width: 24px;
      fill: ${(props) => props.theme.color.muted};
    `}
    viewBox='0 0 3598.32 3598.32'
  >
    <g id='Layer_x0020_1'>
      <path d='M3519.23 3106.69l-1307.52 -1307.52 1307.52 -1307.53c58.42,-58.41 58.41,-154 -0.01,-212.42l-200.12 -200.12c-58.42,-58.42 -154,-58.42 -212.41,0l-1307.53 1307.53 -1307.53 -1307.53c-58.41,-58.42 -153.99,-58.42 -212.41,0l-200.12 200.12c-58.42,58.42 -58.43,154.01 -0.01,212.42l1307.52 1307.53 -1307.52 1307.52c-58.41,58.42 -58.41,154 0,212.41l200.13 200.13c58.41,58.42 154,58.42 212.42,0.01l1307.52 -1307.52 1307.52 1307.52c58.42,58.41 154.01,58.41 212.42,-0.01l200.13 -200.13c58.41,-58.41 58.41,-153.99 0,-212.41z' />
    </g>
  </svg>
)

const MenuIcon = () => (
  <svg
    css='shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd'
    viewBox='0 0 3598.32 3598.32'
  >
    <g id='Layer_x0020_1'>
      <g>
        <path d='M3563.05 2506.63l-276.37 272.43 -981.9 -979.9 981.9 -979.9 276.37 272.43 -697.76 707.47 697.76 707.47 0 0zm-3527.78 -1883.37l2547.8 0 0 391.99 -2547.8 0 0 -391.99 0 0zm0 1371.9l0 -392 1959.89 0 0 392 -1959.89 0 0 0zm0 979.9l0 -391.99 2547.8 0 0 391.99 -2547.8 0z' />
      </g>
    </g>
  </svg>
)

const ItemIcon = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  top: 0;
  left: 1rem;
  bottom: 0;

  > * {
    height: 100%;
  }
`

const ItemHeader = styled.div`
  line-height: 1.3;
  font-size: 1.25rem;
  font-family: ${(props) => props.theme.font.family.menuHeader};
  color: ${(props) =>
    props.color && props.theme.color[props.color] ? props.theme.color[props.color] : props.theme.color.primary};
`

const ItemDescription = styled.div`
  line-height: 1.3;
  font-size: 0.875rem;
  color: ${(props) => props.theme.color.textSecondary};
`

const ListItem = styled.li`
  position: relative;
  display: flex;
  align-items: ${(props) => props.alignment || 'start'};
  text-align: ${(props) => props.alignment || 'start'};
  justify-content: center;
  flex-direction: column;

  padding-left: ${(props) => (props.hasIcon ? '4rem' : '1rem')};
  padding-right: 1rem;
  border-top-style: solid;
  border-top-width: ${(props) => (props.borderWidth || 1) + 'pt'};
  border-top-color: ${(props) =>
    props.borderColor && props.theme.color[props.borderColor]
      ? props.theme.color[props.borderColor]
      : props.theme.color.muted};
  cursor: pointer;
  height: 4.375rem;

  background-color: ${(props) => props.theme.color[props.menuBackgroundColor]};

  > div {
    flex-shrink: 0;
  }

  :last-child {
    border-bottom-style: solid;
    border-bottom-width: ${(props) => (props.borderWidth || 1) + 'pt'};
    border-bottom-color: ${(props) =>
      props.borderColor && props.theme.color[props.borderColor]
        ? props.theme.color[props.borderColor]
        : props.theme.color.muted};
  }

  /* TODO: change the on-hover effect for menu */
  :hover {
    background-color: ${(props) =>
      (props.$onHoverBackgroundColor && props.theme.color[props.$onHoverBackgroundColor]) ||
      darken(0.2, props.theme.color[props.menuBackgroundColor])};
  }
`
const SubMenuListItem = styled(ListItem)`
  padding-left: 1.5rem;
`

const TitleItem = styled.li`
  position: relative;
  width: 100%;
  text-align: center;
  padding: 0 3rem 1rem 1rem;
  margin-top: 0rem;
  color: ${({ theme }) => theme.color.textPrimary};
  font-size: 1.5625rem;
  font-family: ${({ theme }) => theme.font.family.menuHeader};
`

const StyledMenu = styled.div`
  display: block;
  background-color: ${(props) => props.theme.color[props.menuBackgroundColor]};

  padding-top: 1rem;
  padding-bottom: 1rem;

  position: fixed;
  top: 0;
  bottom: 0;
  right: ${(props) => (props.position === 'end' && props.collapsed ? '-575px' : '0')};
  left: ${(props) => (props.position === 'start' && props.collapsed ? '-575px' : '0')};
  z-index: 100;
  min-width: 414px;
  max-width: 575px;

  opacity: ${(props) => (props.collapsed ? 0 : 1)};
  visibility: ${(props) => (props.collapsed ? 'hidden' : 'visible')};

  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
  transition-property: right, opacity, visibility;

  overflow: auto;

  > ul {
    margin: 0;
    margin-left: auto;
    padding: 0 1rem;
    list-style: none;

    ${ListItem}:not(.opened) {
      display: ${(props) => (props.subMenuOpen ? 'none' : 'flex')};
    }
  }

  @media (min-width: 575px) {
    border-radius: 0.3125rem;

    top: 8px;
    bottom: 8px;
    right: ${(props) => (props.collapsed ? '-575px' : props.position === 'end' ? '8px' : 'unset')};
    left: ${(props) => (props.collapsed ? '-575px' : props.position === 'start' ? '8px' : 'unset')};
    box-shadow: 0px 0px 32px ${(props) => props.theme.color.textPrimary}99;

    > ul {
      ${ListItem}:not(.opened) {
        display: flex;
      }
    }
  }
`

const SubMenu = ({
  mainMenuCollapsed,
  title,
  description,
  children,
  color = 'textPrimary',
  onClickHook,
  menuBackgroundColor,
  onHoverBackgroundColor,
  ...rest
}) => {
  const [collapsed, setCollapsed] = useState(true)

  useEffect(() => {
    if (mainMenuCollapsed) {
      setCollapsed(true)
    }
  }, [mainMenuCollapsed])
  return (
    <>
      <ListItem
        {...rest}
        onClick={() => {
          onClickHook(collapsed ? title : undefined)
          setCollapsed(!collapsed)
        }}
        isSubMenu={true}
        className={collapsed ? '' : 'opened'}
        menuBackgroundColor={menuBackgroundColor}
        $onHoverBackgroundColor={onHoverBackgroundColor}
      >
        <DownArrowIcon isOpened={!collapsed} />
        <ItemHeader color={color}>{title}</ItemHeader>
        <ItemDescription>{description}</ItemDescription>
      </ListItem>

      {!collapsed && children.map((c, i) => React.cloneElement(c, { key: i, className: 'opened' }))}
    </>
  )
}

const Menu = ({
  collapsed,
  setCollapsed,
  title,
  items,
  position = 'start',
  menuBackgroundColor = 'background',
  onHoverBackgroundColor,
  submenuBackgroundColor = 'backgroundMuted',
  submenuOnHoverBackgroundColor,
  submenuAlignment = 'start',
  buttonIcon,
  buttonText,
  buttonStyle,
}) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false)
  return (
    <>
      <Button
        icon={buttonIcon || <MenuIcon />}
        title={buttonText}
        aria-expanded={!collapsed}
        aria-label='Toggle menu'
        onClick={() => setCollapsed(!collapsed)}
        {...buttonStyle}
        css={buttonStyle.css}
      />
      <div css='position:relative;'>
        {/* Overlay to catch clicks outside of the menu */}
        <div
          css={`
            display: ${collapsed ? 'none' : 'block'};
            position: fixed;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 100;
          `}
          onClick={() => {
            setCollapsed(!collapsed)
            setSubMenuOpen(false)
          }}
        ></div>

        <StyledMenu
          collapsed={collapsed}
          subMenuOpen={subMenuOpen}
          menuBackgroundColor={menuBackgroundColor}
          position={position}
        >
          <ul>
            <TitleItem>
              {title}

              <CloseIcon
                onClick={() => {
                  setCollapsed(true)
                  setSubMenuOpen(false)
                }}
              />
            </TitleItem>

            {items.map((i, k) =>
              !i.subItems || i.subItems.length === 0 ? (
                <ListItem
                  key={k}
                  borderColor={i.style.borderColor}
                  borderWidth={i.style.borderWidth}
                  $onHoverBackgroundColor={i.style.onHoverBackgroundColor || onHoverBackgroundColor}
                  hasIcon={i.icon !== undefined}
                  onClick={i.onClick}
                  menuBackgroundColor={menuBackgroundColor}
                  alignment={'left'}
                >
                  {i.icon && <ItemIcon>{i.icon}</ItemIcon>}
                  <ItemHeader color={i.style.color}>{i.title}</ItemHeader>
                  <ItemDescription>{i.description}</ItemDescription>
                </ListItem>
              ) : (
                <SubMenu
                  key={k}
                  title={i.title}
                  description={i.description}
                  color={i.style.color}
                  borderColor={i.style.borderColor}
                  borderWidth={i.style.borderWidth}
                  onClickHook={setSubMenuOpen}
                  mainMenuCollapsed={collapsed}
                  onClick={i.onClick}
                  menuBackgroundColor={menuBackgroundColor}
                  onHoverBackgroundColor={i.style.onHoverBackgroundColor || onHoverBackgroundColor}
                >
                  {i.subItems.map((si, k) => (
                    <SubMenuListItem
                      key={k}
                      borderColor={si.style.borderColor}
                      borderWidth={si.style.borderWidth}
                      $onHoverBackgroundColor={si.style.onHoverBackgroundColor || submenuOnHoverBackgroundColor}
                      onClick={si.onClick}
                      menuBackgroundColor={submenuBackgroundColor}
                      alignment={submenuAlignment}
                    >
                      <ItemHeader color={si.style.color}>{si.title}</ItemHeader>
                      <ItemDescription>{si.description}</ItemDescription>
                    </SubMenuListItem>
                  ))}
                </SubMenu>
              )
            )}
          </ul>
        </StyledMenu>
      </div>
    </>
  )
}

export const createMenuItem = ({
  title,
  description,
  color = 'textPrimary',
  borderColor = 'muted',
  borderWidth = 1,
  onHoverBackgroundColor = null,
  icon,
  subItems = [],
  onClick,
}) => ({
  title,
  description,
  icon,
  style: {
    color: color,
    borderColor: borderColor,
    borderWidth: borderWidth,
    onHoverBackgroundColor: onHoverBackgroundColor,
  },
  subItems,
  onClick,
})

export default Menu

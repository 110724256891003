import React, { useState, useEffect } from 'react'
import { Description, Label, ErrorMessage, DisplayOnlyText } from './styledElements'
import Input from './Input'
import PhoneCodeDropdown from './PhoneCodeDropdown'
import { formatPhoneNumber, isValidPhoneNumber } from '../phoneUtils'

/* value is a JS object containing the country calling code and the phone number e.g. { countryCallingCodeWithCountry: 'MU+230', phoneNumber: '59236584' }*/
/* PhoneCodeSelect returns alpha2Code + phoneCode - since some countries share phone codes. Only using phoneCode causes unexpected values to be selected from the list  */
const PhoneNumberInput = ({
  id,
  label,
  value = { country: {}, phoneNumber: '' },
  onChange,
  description,
  validator = () => '', // returns an error message, empty message if valid
  errorMessage = 'Please enter a valid phone number',
  required = false,
  requiredMessage = 'This field is required',
  showErrorOnBlur = true,
  displayOnly = false,
  isValidHook,
  filter,
  lockCountry = false,
  className,
}) => {
  const [country, setCountry] = useState(value?.country)
  const [phoneNumber, setPhoneNumber] = useState(value?.phoneNumber)

  const [blurredPhoneNumber, setBlurredPhoneNumber] = useState(false)
  const [blurredPhoneCode, setBlurredPhoneCode] = useState(lockCountry)

  const [isValid, setValid] = useState(!required)
  const isTouched = blurredPhoneCode && blurredPhoneNumber

  useEffect(() => {
    if (!displayOnly) {
      setValid(
        (isValidPhoneNumber({ country, phoneNumber }) && validator({ country, phoneNumber }) === '') ||
          (!required && phoneNumber === '' && (country.name === undefined || lockCountry))
      )

      if (isValidPhoneNumber({ country, phoneNumber }) && validator({ country, phoneNumber }) === '') {
        if (country.alpha2code !== value?.country?.alpha2code || phoneNumber !== value?.phoneNumber) {
          onChange({ country, phoneNumber })
        }
      } else {
        onChange(undefined)
      }
    }
  }, [country, phoneNumber, required, validator])

  useEffect(() => {
    if (isValidHook && typeof isValidHook === 'function') {
      isValidHook(isValid)
    }
  }, [isValid])

  return (
    <div className={className}>
      <Label htmlFor={id}>{label}</Label>
      {displayOnly ? (
        <DisplayOnlyText>{formatPhoneNumber(value)}</DisplayOnlyText>
      ) : (
        <>
          <div
            css={`
              display: grid;
              gap: 1rem;
              grid-template-columns: 140px 1fr;
            `}
          >
            <PhoneCodeDropdown
              id={'phoneCode_' + id}
              name={'phoneCode_' + id}
              value={country}
              filter={filter}
              onChange={setCountry}
              onBlur={() => setBlurredPhoneCode(true)}
              required={required}
              errorMessage={!isValid && (!showErrorOnBlur || isTouched) ? '' : false}
              readOnly={lockCountry}
            />
            <Input
              id={id}
              name={id}
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value)
              }}
              invalid={!isValid && (!showErrorOnBlur || isTouched)}
              onBlur={() => setBlurredPhoneNumber(true)}
            />
          </div>
          {description && <Description>{description}</Description>}
          {(!isValid && (!showErrorOnBlur || isTouched)) && (
            <ErrorMessage>
              {isValidPhoneNumber({ country, phoneNumber })
                ? validator({ country, phoneNumber })
                : phoneNumber === '' && (country.name === undefined || lockCountry)
                ? requiredMessage
                : errorMessage}
            </ErrorMessage>
          )}
        </>
      )}
    </div>
  )
}

export default PhoneNumberInput

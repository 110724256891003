import React from 'react'
import styled from 'styled-components'

/**
 * Create a StepItem object to simplify additions to the Steps component
 *
 * @param {number} number
 * @param {string} description
 * @param {React.Node} icon
 * @returns
 */
export const createStepItem = (number, description, icon) => ({
  number: number,
  description: description,
  icon: icon,
})

const generateMediaQueriesOnBreakpoints = (breakpointMap, generator) => {
  return `
  ${generator(breakpointMap.xxs || breakpointMap.xs)}
  
  @media (min-width: 360px) {
    ${generator(breakpointMap.xs)}
  }

  @media (min-width: 576px) {
    ${generator(breakpointMap.sm)}
  }

  @media (min-width: 768px) {
    ${generator(breakpointMap.md)}
  }

  @media (min-width: 992px) {
    ${generator(breakpointMap.lg)}
  }

  @media (min-width: 1200px) {
    ${generator(breakpointMap.xl)}
  }

  @media (min-width: 1430px) {
    ${generator(breakpointMap.xxl)}
  }
  `
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${(props) => (props.vertical ? 'row' : 'column')};
  gap: 0.5rem;

  ${({ circleDiameter, vertical }) =>
    generateMediaQueriesOnBreakpoints(
      circleDiameter,
      (breakpointValue) => `width: ${vertical ? 3 * breakpointValue : breakpointValue}px;`
    )}
`

const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Step = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
  justify-content: center;
  align-items: flex-start;
`

const Number = styled.div`
  color: ${({ theme, notDone }) => (notDone ? theme.color.primaryMuted : theme.color.primary)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 3px;
  border-style: solid;
  border-color: ${({ theme, notDone }) => (notDone ? theme.color.primaryMuted : theme.color.primary)};
  font-family: ${({ theme }) => theme.font.family.medium};
  font-variant-numeric: lining-nums;

  svg {
    fill: ${({ theme, notDone }) => (notDone ? theme.color.primaryMuted : theme.color.primary)};
  }

  ${({ numberLineHeight }) =>
    generateMediaQueriesOnBreakpoints(numberLineHeight, (breakpointValue) => `line-height: ${breakpointValue};`)}
  ${({ numberFontSize }) =>
    generateMediaQueriesOnBreakpoints(numberFontSize, (breakpointValue) => `font-size: ${breakpointValue};`)}

  ${({ circleDiameter }) =>
    generateMediaQueriesOnBreakpoints(
      circleDiameter,
      (breakpointValue) =>
        `width: ${breakpointValue}px; 
         height: ${breakpointValue}px;
         border-radius: ${breakpointValue}px;`
    )}
`

const Connector = styled.div`
  color: ${({ theme }) => theme.color.primary};
  text-align: center;
  ${(props) =>
    props.vertical
      ? `border-right-width: 3px;
  border-right-style: solid;
  border-right-color: ${props.notDone ? props.theme.color.primaryMuted : props.theme.color.primary};
  ${generateMediaQueriesOnBreakpoints(props.connectorLength, (breakpointValue) => `height: ${breakpointValue}px;`)}
  ${generateMediaQueriesOnBreakpoints(props.circleDiameter, (breakpointValue) => `width: ${breakpointValue / 2}px;`)}
  `
      : `border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: ${props.notDone ? props.theme.color.primaryMuted : props.theme.color.primary};
  ${generateMediaQueriesOnBreakpoints(props.connectorLength, (breakpointValue) => `width: ${breakpointValue}px;`)}
  ${generateMediaQueriesOnBreakpoints(props.circleDiameter, (breakpointValue) => `height: ${breakpointValue / 2}px;`)}
  `}
`

const StepDescription = styled.div`
  color: ${(props) => (props.notDone ? props.theme.color.muted : props.theme.color.textPrimary)};
  position: relative;
  text-align: center;

  ${(props) =>
    generateMediaQueriesOnBreakpoints(props.textFontSize, (breakpointValue) => `font-size: ${breakpointValue};`)}

  ${(props) =>
    generateMediaQueriesOnBreakpoints(props.circleDiameter, (breakpointValue) => `width: ${1.8 * breakpointValue}px;`)}
`

const generateBreakpointsObject = (userValue) => {
  let breakpointObject = {
    xxs: userValue.xxs || userValue.xs,
    xs: userValue.xs,
  }
  breakpointObject.sm = userValue.sm ? userValue.sm : breakpointObject.xs
  breakpointObject.md = userValue.md ? userValue.md : breakpointObject.sm
  breakpointObject.lg = userValue.lg ? userValue.lg : breakpointObject.md
  breakpointObject.xl = userValue.xl ? userValue.xl : breakpointObject.lg
  breakpointObject.xxl = userValue.xxl ? userValue.xxl : breakpointObject.xl
  return breakpointObject
}

/**
 *
 * @prop {Array} steps - list of StepItems
 * @prop {number} number - current item
 * @prop {object} circleDiameter - breakpoint map of the diameter of the step circle (default: `{ xs: 60 }`)
 * @prop {object} connectorLength - breakpoint map of the length of the connector between circles (default: `{ xs: 100 }`)
 * @prop {object} numberFontSize - breakpoint map of the font size of the numbers inside the circle (default: `{ xs: '2.5rem' }`)
 * @prop {object} numberLineHeight - breakpoint map of the line height of the numbers inside the circle (adjust to center the number in the circle, default: { xs: 1.1 })
 * @prop {object} textFontSize -  breakpoint map of the font size of the text below the circle (default: { xs: 1rem })
 * @prop {boolean} vertical -  change direction to vertical
 */
const Steps = ({
  steps = [],
  current = 0,
  circleDiameter = { xs: 60 },
  connectorLength = { xs: 100 },
  numberLineHeight = { xs: 1.1 },
  numberFontSize = { xs: '2.5rem' },
  textFontSize = { xs: '1rem' },
  vertical = false,
  ...props
}) => {
  let circleDiameterWithDefaults = generateBreakpointsObject(circleDiameter)
  let connectorLengthWithDefault = generateBreakpointsObject(connectorLength)
  let numberFontSizeWithDefault = generateBreakpointsObject(numberFontSize)
  let numberLineHeightWithDefault = generateBreakpointsObject(numberLineHeight)
  let textFontSizeWithDefault = generateBreakpointsObject(textFontSize)

  return (
    <StepsWrapper {...props}>
      <div
        css={`
          display: flex;
          flex-direction: ${vertical ? 'column' : 'row'};
          justify-content: center;
          align-items: flex-start;
        `}
      >
        {steps.map((v, i) => (
          <Step key={i} vertical={vertical}>
            {i != 0 && (
              <Connector
                vertical={vertical}
                notDone={v.number > current}
                connectorLength={connectorLengthWithDefault}
                circleDiameter={circleDiameterWithDefaults}
              >
                &nbsp;
              </Connector>
            )}
            <Wrapper circleDiameter={circleDiameterWithDefaults} vertical={vertical}>
              <Number
                notDone={v.number > current}
                circleDiameter={circleDiameterWithDefaults}
                numberLineHeight={numberLineHeightWithDefault}
                numberFontSize={numberFontSizeWithDefault}
              >
                {v.icon ? v.icon : v.number}
              </Number>
              <StepDescription
                notDone={v.number > current}
                circleDiameter={circleDiameterWithDefaults}
                textFontSize={textFontSizeWithDefault}
                vertical={vertical}
              >
                {v.description}
              </StepDescription>
            </Wrapper>
          </Step>
        ))}
      </div>
    </StepsWrapper>
  )
}

export default Steps

export default {
  // accounts: [
  //   {
  //     id: 'savings',
  //     balance: 32122,
  //     currency: 'EUR',
  //     name: 'Savings Account',
  //     alias: 'Savings',
  //     type: 'string',
  //     created_at: '2019-08-24T14:15:22Z',
  //     iban: 'DS84518452345574126',
  //   },
  //   {
  //     id: 'savings2',
  //     balance: 4210,
  //     currency: 'EUR',
  //     name: 'Savings Account 2',
  //     alias: 'Online Transactions',
  //     type: 'string',
  //     created_at: '2019-08-24T14:15:22Z',
  //     iban: 'FD12518453529574926',
  //   },
  //   {
  //     id: 'current',
  //     balance: 15210,
  //     currency: 'EUR',
  //     name: 'Current Account',
  //     alias: 'Everyday Shopping Card',
  //     type: 'string',
  //     created_at: '2019-08-24T14:15:22Z',
  //     iban: 'TH65418459512574126',
  //   },
  // ],
  // cards: [
  //   {
  //     id: 'curr_phy',
  //     individual_id: 'b59a8529-befe-4aba-b132-506544286853',
  //     account_id: 'MOCKED_ID',
  //     brand: 'mastercard',
  //     currency: 'EUR',
  //     display_name: 'Joanne Blanda',
  //     exp_month: '12',
  //     exp_year: '2023',
  //     last4: '5741',
  //     region: 'europe',
  //     status: 'active',
  //     type: 'physical',
  //   },
  //   {
  //     id: 'curr_vir',
  //     individual_id: 'b59a8529-befe-4aba-b132-506544286853',
  //     account_id: 'MOCKED_ID',
  //     brand: 'mastercard',
  //     currency: 'EUR',
  //     display_name: 'Joanne Blanda',
  //     exp_month: '12',
  //     exp_year: '2023',
  //     last4: '5977',
  //     region: 'europe',
  //     status: 'active',
  //     type: 'virtual',
  //   },
  //   {
  //     id: 'sav1_phy',
  //     individual_id: 'b59a8529-befe-4aba-b132-506544286853',
  //     account_id: 'savings',
  //     brand: 'visa',
  //     currency: 'EUR',
  //     display_name: 'Joanne Blanda',
  //     exp_month: '06',
  //     exp_year: '2024',
  //     last4: '5784',
  //     region: 'europe',
  //     status: 'active',
  //     type: 'physical',
  //   },
  //   {
  //     id: 'sav2_vir',
  //     individual_id: 'b59a8529-befe-4aba-b132-506544286853',
  //     account_id: 'savings2',
  //     brand: 'visa',
  //     currency: 'EUR',
  //     display_name: 'Joanne Blanda',
  //     exp_month: '07',
  //     exp_year: '2025',
  //     last4: '5988',
  //     region: 'europe',
  //     status: 'active',
  //     type: 'virtual',
  //   },
  // ],
  beneficiaries: [
    {
      id: 'bene1',
      name: 'James Bond',
      alias: 'James Bond',
      payment_type: 'sepa', // || swift || sepa || cardnoir
      entity_type: 'individual', // || individual || corporate
      currency: 'EUR',
      email: 'jb@iso.uk',
      status: 'active', // || inactive || pending || active
      account_details: {
        name: 'James Bond',
        account: {
          bic: 'FTCSSESS',
          iban: 'SE37977000000100082',
          type: 'sepa',
        },
      },
      country: 'GB',
    },
    {
      id: 'bene2',
      name: 'Cloudy Hosting',
      alias: 'Cloudy Hosting',
      payment_type: 'sepa',
      entity_type: 'corporate',
      currency: 'EUR',
      email: 'billing@cloudyhosting.com',
      status: 'active',
      account_details: {
        name: 'Cloudy Hosting',
        account: {
          bic: 'FTCRRTXX',
          iban: 'DE37988004400165282',
          type: 'sepa',
        },
      },
      country: 'GB',
    },
    {
      id: 'bene3',
      name: 'Richard Summer',
      alias: 'Richard Summer',
      payment_type: 'swift',
      entity_type: 'individual',
      currency: 'EUR',
      email: 'jsmith@smiths.com',
      status: 'active',
      account_details: {
        name: 'John Smith',
        account: {
          bic: 'FTCXXEXX',
          iban: 'GR37857000000100742',
          type: 'swift',
        },
      },
      country: 'GB',
    },
    {
      id: 'bene4',
      name: 'Timothy Harland',
      alias: 'Timothy Harland',
      payment_type: 'swift',
      entity_type: 'individual',
      currency: 'EUR',
      email: 'jsmith@smiths.com',
      status: 'active',
      account_details: {
        name: 'Timothy Harland',
        account: {
          bic: 'FTCWWEXX',
          iban: 'CS95632000000847522',
          type: 'swift',
        },
      },
      country: 'GB',
    },
  ],
  transactions: [
    {
      account_id: 'MOCKED_ID',
      amount: -100,
      counterparty: {
        name: 'James Bond',
        account_details: {
          iban: 'SE37977000000100082',
          type: 'sepa',
        },
      },
      created_at: '2021-08-17T14:15:22.900Z',
      currency: 'EUR',
      id: 'dAC3YuVVz1',
      reference: 'A new pair of shoes',
      request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
      status: 'completed',
      type: 'bank-transfer',
    },
    {
      account_id: 'MOCKED_ID',
      amount: -144.25,
      counterparty: {
        name: 'John Smith',
        account_details: {},
      },
      created_at: '2021-08-17T14:15:22.900Z',
      currency: 'EUR',
      id: 'dAC3YuVVz2',
      reference: 'Thanks for the tips',
      request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
      status: 'completed',
      type: 'card-payment',
    },
    {
      account_id: 'MOCKED_ID',
      amount: -544.25,
      counterparty: {
        name: 'Bagatelle Mall',
        account_details: {},
      },
      created_at: '2021-08-16T14:15:22.900Z',
      currency: 'EUR',
      id: 'dAC3YuVVz3',
      reference: 'Survival Kit',
      request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
      status: 'completed',
      type: 'card-payment',
    },
    // {
    //   account_id: 'savings',
    //   amount: -5445.25,
    //   counterparty: {
    //     name: 'Richard Summer',
    //     account_details: {
    //       iban: 'GR37857000000100742',
    //       type: 'swift',
    //     },
    //   },
    //   created_at: '2021-08-13T14:15:22.900Z',
    //   currency: 'EUR',
    //   id: 'dAC3YuVVz4',
    //   reference: 'Nada',
    //   request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
    //   status: 'completed',
    //   type: 'bank-transfer',
    // },
    // {
    //   account_id: 'savings',
    //   amount: -422.0,
    //   counterparty: {
    //     name: 'Timothy Harland',
    //     account_details: {
    //       iban: 'CS95632000000847522',
    //       type: 'swift',
    //     },
    //   },
    //   created_at: '2021-08-12T14:15:22.900Z',
    //   currency: 'EUR',
    //   id: 'dAC3sdVVzo',
    //   reference: 'Nada 2',
    //   request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
    //   status: 'completed',
    //   type: 'bank-transfer',
    // },
    // {
    //   account_id: 'savings',
    //   amount: -400.0,
    //   counterparty: {
    //     account_details: {},
    //     name: 'Cascavelle',
    //   },
    //   created_at: '2021-08-13T14:15:22.900Z',
    //   currency: 'EUR',
    //   id: 'dAC3sdVVz1',
    //   reference: 'ATM_WITHDRAWAL',
    //   request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
    //   status: 'completed',
    //   type: 'card-payment',
    // },
    // {
    //   account_id: 'savings2',
    //   amount: -15.25,
    //   counterparty: {
    //     account_details: {
    //       iban: 'DE37988004400165282',
    //       type: 'sepa',
    //     },
    //     name: 'Cloudy Hosting',
    //   },
    //   created_at: '2021-06-05T14:15:22.900Z',
    //   currency: 'EUR',
    //   id: 'dAC3YuVVz5',
    //   reference: 'More storage space',
    //   request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
    //   status: 'completed',
    //   type: 'card-payment',
    // },
    // {
    //   account_id: 'savings2',
    //   amount: -15.25,
    //   counterparty: {
    //     account_details: {
    //       iban: 'DE37988004400165282',
    //       type: 'sepa',
    //     },
    //     name: 'Cloudy Hosting',
    //   },
    //   created_at: '2021-07-05T14:15:22.900Z',
    //   currency: 'EUR',
    //   id: 'dAC3YuVVzo',
    //   reference: 'More storage space',
    //   request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
    //   status: 'completed',
    //   type: 'card-payment',
    // },
    // {
    //   account_id: 'savings2',
    //   amount: -15.25,
    //   counterparty: {
    //     account_details: {
    //       iban: 'DE37988004400165282',
    //       type: 'sepa',
    //     },
    //     name: 'Cloudy Hosting',
    //   },
    //   created_at: '2021-08-05T14:15:22.900Z',
    //   currency: 'EUR',
    //   id: 'dAC3YuVVz6',
    //   reference: 'More storage space',
    //   request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
    //   status: 'completed',
    //   type: 'card-payment',
    // },
    // {
    //   account_id: 'savings2',
    //   amount: -15.25,
    //   counterparty: {
    //     account_details: {
    //       iban: 'DE37988004400165282',
    //       type: 'sepa',
    //     },
    //     name: 'Cloudy Hosting',
    //   },
    //   created_at: '2021-09-05T14:15:22.900Z',
    //   currency: 'EUR',
    //   id: 'dAC3YuVVz7',
    //   reference: 'More storage space',
    //   request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
    //   status: 'completed',
    //   type: 'card-payment',
    // },
    // {
    //   account_id: 'MOCKED_ID',
    //   amount: -200.0,
    //   counterparty: {
    //     account_details: {},
    //     name: 'Phydra',
    //   },
    //   created_at: '2021-08-16T14:15:22.900Z',
    //   currency: 'EUR',
    //   id: 'dAC3YuVVz8',
    //   reference: 'Soap',
    //   request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
    //   status: 'completed',
    //   type: 'card-payment',
    // },
    // {
    //   account_id: 'MOCKED_ID',
    //   amount: -350.25,
    //   counterparty: {
    //     account_details: {},
    //     name: 'Intermart',
    //   },
    //   created_at: '2021-08-16T14:15:22.900Z',
    //   currency: 'EUR',
    //   id: 'dAC3YuVVz9',
    //   reference: 'SUPERMARKET',
    //   request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
    //   status: 'completed',
    //   type: 'card-payment',
    // },
    // {
    //   account_id: 'MOCKED_ID',
    //   amount: -54.25,
    //   counterparty: {
    //     account_details: {},
    //     name: 'Pharmacy St Jean',
    //   },
    //   created_at: '2021-08-13T14:15:22.900Z',
    //   currency: 'EUR',
    //   id: 'dAC3YuVV10',
    //   reference: 'Survival Kit',
    //   request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
    //   status: 'completed',
    //   type: 'card-payment',
    // },
    // {
    //   account_id: 'MOCKED_ID',
    //   amount: -5000.0,
    //   counterparty: {
    //     account_id: 'savings',
    //     name: 'Joanne Blanda',
    //   },
    //   created_at: '2021-08-13T14:15:22.900Z',
    //   currency: 'EUR',
    //   id: 'dAC3YuVV11',
    //   reference: 'INTERNAL_TRANSFER',
    //   request_id: 'b9fb166f-8ea1-48e7-be90-c02625c7d98a',
    //   status: 'completed',
    //   type: 'bank-transfer',
    // },
  ],
}

import React from 'react'
import styled from 'styled-components'

import { Dropdown, OptionItem, OptionLabel } from 'stream-ui'

const CustomLabel = styled(OptionLabel)`
  display: flex;
  padding: 0;
`

const AnalyticsDropdown = ({ color, ...rest }) => {
  return (
    <Dropdown
      {...rest}
      list={[
        'Filter by analytics'
      ]}
      id='analyticsDropdown'
      label={null}
      description={null}
      filterOption={(input, val) => val.code.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      optionLabelRenderFunction={(v) => (
        <CustomLabel color={color} css='padding: 0.25rem 0;'>
          <span
            css={`
              font-size: ${(props) => props.theme.font.size.small};
            `}
          >
            {v}
          </span>
        </CustomLabel>
      )}
      optionItemRenderFunction={(v) => (
        <OptionItem css='padding: 0.25rem 0.5rem;'>
          <span
            css={`
              font-size: ${(props) => props.theme.font.size.small};
            `}
          >
            {v}
          </span>
        </OptionItem>
      )}
    />
  )
}

export default AnalyticsDropdown

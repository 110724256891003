import { createContext } from 'react'
import data from './data'
export const initSession = {
  version: 4,
  ...data,
  // email: 'dsilver@gmail.com',
  // firstName: 'David',
  // lastName: 'Silverfield',
  // countryOfResidence: {
  //   name: 'United Kingdom',
  //   phoneCode: '+44',
  //   alpha2code: 'GB',
  //   alpha3code: 'GBR',
  //   key: 220,
  // },
  // nationality: {
  //   name: 'Chad',
  //   phoneCode: '+235',
  //   alpha2code: 'TD',
  //   alpha3code: 'TCD',
  //   key: 41,
  // },
  // dateOfBirth: '1990-10-01',
  // phoneNumber: {
  //   phoneNumber: '7411111111',
  //   country: {
  //     name: 'United Kingdom',
  //     phoneCode: '+44',
  //     alpha2code: 'GB',
  //     alpha3code: 'GBR',
  //     key: 220,
  //   },
  // },
  // countryOfBirth: {
  //   name: 'Chad',
  //   phoneCode: '+235',
  //   alpha2code: 'TD',
  //   alpha3code: 'TCD',
  //   key: 41,
  // },
  // language: {
  //   alpha2code: 'GB',
  //   name: 'English',
  //   key: 0,
  // },
  // physicalAddress: {
  //   country: {
  //     name: 'United Kingdom',
  //     phoneCode: '+44',
  //     alpha2code: 'GB',
  //     alpha3code: 'GBR',
  //     key: 220,
  //   },
  //   buildingOrHouseNameOrSuiteNumber: '2nd Blockhouse',
  //   streetName: 'Da Street',
  //   city: 'Towne Town',
  //   postalCode: '54541',
  // },
  // shippingAddress: {
  //   country: {
  //     name: 'Gibraltar',
  //     phoneCode: '+350',
  //     alpha2code: 'GI',
  //     alpha3code: 'GIB',
  //     key: 77,
  //   },
  //   buildingOrHouseNameOrSuiteNumber: 'Suite 1, Quay 29 Offices',
  //   streetName: '1 Rockface Road',
  //   city: 'Gibraltar',
  //   postalCode: 12345,
  // },
  // cardUse: 'A for Apple',
  // cardFunding: 'B for Banana',
}

const defaultContext = {
  session: null,
  dispatch: () => {},
}

export default createContext(defaultContext)

// copied and adapted from fxbet
import parsePhoneNumber from 'libphonenumber-js'

const isDev = window.location.hostname === 'localhost'
const useUrl = (prod, dev) => (isDev ? dev : prod)
const API_BASE_URL = useUrl(`https://${window.location.hostname}/api`, 'http://localhost:8095/api')
export const HOSTED_PAYMENT_PAGE_BASE_URL = useUrl('https://cleanpay.mokajava.com', 'http://localhost:1235')
const COSMO_API_BASE_URL = useUrl('https://cleanpay.mokajava.com/api', 'http://localhost:8096/api')

// TODO: throw proper Error objects
export default {
  authenticateUser: async ({ email, password }) => {
    const resp = await fetch(`${API_BASE_URL}/user/authenticate`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ email, password }),
    })

    if (resp.status === 200) {
      return resp.json()
    } else if (resp.status === 401) {
      const error = await resp.json()
      throw error.message
    }
  },
  signup: async ({ firstName, lastName, email, dateOfBirth, promoCode, countryOfResidence, nationality }) => {
    const resp = await fetch(`${API_BASE_URL}/onboarding/signup`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email: email,
        date_of_birth: dateOfBirth,
        promo_code: promoCode,
        address: {
          country_code: countryOfResidence.alpha2code,
        },
        nationality: [nationality.alpha2code],
      }),
    })

    if (resp.status === 200) {
      return resp.json()
    } else if (resp.status === 400) {
      console.error(await resp.json())
      throw 'There is an issue with the request.'
    } else if (resp.status === 409) {
      console.error(await resp.json())
      throw 'This email address is already associated with an account.'
    } else if (resp.status === 500) {
      console.error(await resp.json())
      throw 'An unexpected server error has occurred.'
    } else {
      const errorMessage = await resp.text()
      throw errorMessage
    }
  },
  completeSignUp: async (emailToken, email, password) => {
    const resp = await fetch(`${API_BASE_URL}/onboarding/complete-signup`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ token: emailToken, email, password }),
    })

    if (resp.status === 200) {
      return resp.json()
    } else if (resp.status === 400) {
      const error = await resp.json()

      if (error.error_type === 'INVALID_TOKEN') throw 'This link has expired. Please start sign up process again.'

      console.error(error)
      throw 'There is an issue with the request.'
    } else if (resp.status === 500) {
      console.error(await resp.json())
      throw 'An unexpected server error has occurred.'
    } else {
      const errorMessage = await resp.text()
      throw errorMessage
    }
  },
  completeProfile: async ({
    id,
    firstName,
    lastName,
    phoneNumber,
    nationality,
    countryOfBirth,
    language,
    physicalAddress,
    shippingAddress,
    cardUse,
    cardFunding,
  }) => {
    const resp = await fetch(`${API_BASE_URL}/onboarding/complete-profile`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        id,
        first_name: firstName,
        last_name: lastName,
        address: {
          country_code: physicalAddress.country.alpha2code,
          street1: physicalAddress.streetName,
          street2: physicalAddress.buildingOrHouseNameOrSuiteNumber,
          city: physicalAddress.city,
          postal_code: physicalAddress.postalCode,
        },
        phone_number: parsePhoneNumber(phoneNumber.phoneNumber, phoneNumber.country.alpha2code).formatInternational(),
        country_of_birth: countryOfBirth.alpha2code,
        nationality: [nationality.alpha2code],
        language: language.alpha2code,
        shipping_address: shippingAddress
          ? {
              country_code: shippingAddress.country.alpha2code,
              street1: shippingAddress.streetName,
              street2: shippingAddress.buildingOrHouseNameOrSuiteNumber,
              city: shippingAddress.city,
              postal_code: shippingAddress.postalCode,
            }
          : undefined,
        card_use: cardUse,
        card_funding: cardFunding,
      }),
    })

    if (resp.status === 200) {
      return resp.json()
    } else if (resp.status === 400) {
      console.error(await resp.json())
      throw 'There is an issue with the request.'
    } else if (resp.status === 500) {
      console.error(await resp.json())
      throw 'An unexpected server error has occurred.'
    } else {
      const errorMessage = await resp.text()
      throw errorMessage
    }
  },
  confirmOrder: async ({
    id,
    firstName,
    lastName,
    nationality,
    countryOfBirth,
    phoneNumber,
    dateOfBirth,
    physicalAddress,
    shippingAddress,
    agreeToTOS,
    agreeToPromotions,
  }) => {
    const resp = await fetch(`${API_BASE_URL}/onboarding/confirm-order`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        id,
        first_name: firstName,
        last_name: lastName,
        address: {
          country_code: physicalAddress.country.alpha2code,
          street1: physicalAddress.streetName,
          street2: physicalAddress.buildingOrHouseNameOrSuiteNumber,
          city: physicalAddress.city,
          postal_code: physicalAddress.postalCode,
        },
        phone_number: parsePhoneNumber(phoneNumber.phoneNumber, phoneNumber.country.alpha2code).formatInternational(),
        country_of_birth: countryOfBirth.alpha2code,
        nationality: [nationality.alpha2code],
        date_of_birth: dateOfBirth,
        shipping_address: shippingAddress
          ? {
              country_code: shippingAddress.country.alpha2code,
              street1: shippingAddress.streetName,
              street2: shippingAddress.buildingOrHouseNameOrSuiteNumber,
              city: shippingAddress.city,
              postal_code: shippingAddress.postalCode,
            }
          : undefined,
        agreed_to_tos: agreeToTOS,
        agreed_to_promotions: agreeToPromotions,
      }),
    })

    if (resp.status === 200) {
      return resp.json()
    } else if (resp.status === 400) {
      console.error(await resp.json())
      throw 'There is an issue with the request.'
    } else {
      console.error(await resp.json())
      throw 'An unexpected server error has occurred'
    }
  },
  createPaymentIntentForMembershipFee: async ({ id, name, email }) => {
    /* TODO: remove next line - it's purpose is to make my linter happy when i'm hardcoding the email address */
    console.log(email)
    const resp = await fetch(`${COSMO_API_BASE_URL}/payment/intent`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        order: {
          total: {
            amount: 99,
            currency: 'EUR',
          },
          ref: `membershipfee:${name}/${new Date().getMilliseconds()}`,
          items: [
            {
              name: 'Membership Fee',
              cost: {
                amount: 99,
                currency: 'EUR',
              },
            },
          ],
        },
        card_holder: {
          contact: {
            // email: email,
            email: 'jonas@quickbit.com',
          },
        },
        callback_url: 'https://cardnoir.mokajava.com',
        customer: {
          ref: id,
        },
        customisation: {
          color: {
            background: '#323232',
            accent: '#fbb03b',
            accentText: '#323232',
            accentHover: '#FA9C09',
            text: '#f7f6f6',
            label: '#f7f6f6',
            input: '#fbb03b',
            inputBorder: '#c0c0c0',
            shadow: '#ffffff4c',
            buttonGreyed: '#666666',
            buttonGreyedHover: '#9b9999',
          },
        },
      }),
    })

    if (resp.status === 200) {
      return resp.json()
    } else if (resp.status === 400) {
      const error = await resp.json()
      console.error(error)
      throw 'There is an issue with the request.'
    } else if (resp.status === 500) {
      console.error(await resp.json())
      throw 'An unexpected server error has occurred.'
    } else {
      const errorMessage = await resp.text()
      throw errorMessage
    }
  },
  simulatePayment: async (id) => {
    const resp = await fetch(`${API_BASE_URL}/onboarding/simulate-payment`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ id }),
    })

    if (resp.status === 200) {
      return resp.json()
    } else if (resp.status === 400) {
      const error = await resp.json()
      console.error(error)
      throw 'There is an issue with the request.'
    } else if (resp.status === 500) {
      console.error(await resp.json())
      throw 'An unexpected server error has occurred.'
    } else {
      const errorMessage = await resp.text()
      throw errorMessage
    }
  },
  simulateOnboarding: async (id) => {
    const resp = await fetch(`${API_BASE_URL}/onboarding/simulate-onboarding-kyc`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ id }),
    })

    if (resp.status === 200) {
      return resp.json()
    } else if (resp.status === 400) {
      const error = await resp.json()
      console.error(error)
      throw 'There is an issue with the request.'
    } else if (resp.status === 500) {
      console.error(await resp.json())
      throw 'An unexpected server error has occurred.'
    } else {
      const errorMessage = await resp.text()
      throw errorMessage
    }
  },
  getUser: async (token) => {
    const resp = await fetch(`${API_BASE_URL}/user`, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: token,
      },
      // body: JSON.stringify({ time: Date.now() }),
    })

    if (resp.status === 200) {
      return resp.json()
    } else if (resp.status === 400 || resp.status === 401) {
      throw 'Session expired'
    } else if (resp.status === 500) {
      console.error(await resp.json())
      throw 'An unexpected server error has occurred.'
    } else {
      const errorMessage = await resp.text()
      throw errorMessage
    }
  },
}

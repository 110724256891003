import React, { useContext, useState } from 'react'
import {
  AddressInput,
  Button,
  CountryCodeDropdown,
  EMAIL_REGEX,
  Input,
  InputGrid,
  Label,
  onStringChange,
  PhoneNumberInput,
  regexValidate,
  ToggleButton,
} from 'stream-ui'
import styled from 'styled-components'
import HelpIcon from '../../assets/icons/Icon_Help_Question.svg'
import { onDesktop } from '../../cardnoirStyledElements'
import CancelButtonFooter from './CancelButtonFooter'
import LightboxWrapper from './LightboxWrapper'

import CardNoirLogo from '../../assets/CardNoir_logo_B_W.png'
import SEPALogo from '../../assets/Icon_Sepa_SVG.svg'
import SwiftLogo from '../../assets/Icon_Swift_SVG.svg'
import PDFIcon from '../../assets/icons/Icon_PDF.svg'
import ArrowBackIcon from '../../assets/icons/Icon_Arrow_Back_2.svg'
import SessionContext from '../../SessionContext'
import LightboxContext from '../../LightboxContext'
import LBSendFunds from './LBSendFunds'

const FilterButton = (props) => (
  <ToggleButton
    {...props}
    activeBackgroundColor={'success'}
    inactiveBackgroundColor='transparent'
    activeColor='textPrimary'
    inactiveColor='textPrimary'
    activeBorderColor='success'
    inactiveBorderColor='muted'
    css={`
      flex-grow: 1;
      width: 100%;
      height: 45px;
      ${onDesktop`
        height: 32px;
      `}
      border-radius: 5px;
      border-width: 2px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: ${(props) => props.theme.font.size.small};
      font-family: ${(props) => (props.isActive ? props.theme.font.family.bold : null)};
    `}
  />
)

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  justify-content: center;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 2pt solid ${(props) => props.theme.color.primaryHighlight};

  ${onDesktop`
    grid-template-columns: 1fr 1fr;   
    justify-content: start;         
  `}
`

const FluidAddressInput = styled(AddressInput)`
  grid-column: span 2;

  ${InputGrid} {
    grid-template-columns: 1fr;
    ${onDesktop`grid-template-columns: 1fr 1fr;`}
  }

  ${Label} {
    max-width: unset;
  }
`

const LBAddBeneficiary = (props) => {
  const { dispatch } = useContext(SessionContext)
  const { setLightbox } = useContext(LightboxContext)

  const [isConfirming, setIsConfirming] = useState(false)
  const [selectedBeneficiaryType, setSelectedBeneficiaryType] = useState('cardnoir')

  const [emailAddress, setEmailAddress] = useState('')
  // 'adz@adz.com'
  const [alias, setAlias] = useState('')
  // 'Ubs'
  // pop: proof of payment
  const [popEmail, setPopEmail] = useState('')
  // 'adz@adz.com'
  const [popMobileNumber, setPopMobileNumber] = useState()
  //   {
  //   phoneNumber: '7411111111',
  //   country: {
  //     name: 'United Kingdom',
  //     phoneCode: '+44',
  //     alpha2code: 'GB',
  //     alpha3code: 'GBR',
  //     key: 220,
  //   },
  // }

  const [country, setCountry] = useState()
  const [IBANNumber, setIBANNumber] = useState('')
  const [beneficiaryName, setBeneficiaryName] = useState('')
  const [BICCode, setBICCode] = useState('')

  const [beneficiaryAddress, setBeneficiaryAddress] = useState()
  const [bankName, setBankName] = useState('')
  const [bankAddress, setBankAddress] = useState()

  const saveContact = (goToPayment) => {
    if (selectedBeneficiaryType === 'cardnoir') {
      dispatch({
        type: 'addBeneficiary',
        id: emailAddress,
        name: alias,
        alias: alias,
        payment_type: 'cardnoir',
        entity_type: 'individual',
        currency: 'EUR',
        email: emailAddress,
        status: 'active',
        account_details: {
          name: alias,
          account_id: emailAddress,
        },
      })
    }
    if (goToPayment) {
      setLightbox(<LBSendFunds selectBeneficiaryId={emailAddress} />)
    } else {
      setLightbox(undefined)
    }
  }

  return (
    <LightboxWrapper
      title='Create a new beneficiary'
      {...props}
      css='width: 100%; max-width: 632px;'
      footer={<CancelButtonFooter />}
    >
      {isConfirming ? (
        <div>
          <div
            css={`
              display: grid;
              grid-template-columns: 1fr;
              grid-template-areas: 'logo' 'description';
              gap: 1rem;

              ${onDesktop`
                  grid-template-columns: 1fr auto;
                  grid-template-areas: "description logo";
                `};
            `}
          >
            <div css='grid-area: description;'>
              {selectedBeneficiaryType === 'cardnoir' && (
                <>
                  <div css='padding-bottom: 1rem;'>Confirm beneficiary information:</div>
                  <div css='padding-left: 0.5rem; padding-bottom: 0.5rem;'>Email address</div>
                  <div css='padding-left: 0.5rem; padding-bottom: 0.75rem;'>
                    <Input value={emailAddress} displayOnly={true} />
                  </div>
                  <div css='padding-left: 0.5rem; padding-bottom: 0.5rem;'>Alias</div>
                  <div css='padding-left: 0.5rem; padding-bottom: 0.75rem;'>
                    <Input value={alias} displayOnly={true} />
                  </div>
                  {(popEmail || popMobileNumber) && (
                    <div css='padding-left: 0.5rem;padding-bottom: 0.5rem;'>Proof of payment</div>
                  )}
                  {popEmail && (
                    <div css='padding-left: 0.5rem;'>
                      <Input value={popEmail} displayOnly={true} />
                    </div>
                  )}
                  {popMobileNumber && (
                    <div css='padding-left: 0.5rem; padding-bottom: 0.75rem;'>
                      <PhoneNumberInput value={popMobileNumber} displayOnly={true} />
                    </div>
                  )}
                </>
              )}
            </div>
            <div css='grid-area: logo; display: flex; justify-content: center;'>
              {selectedBeneficiaryType === 'cardnoir' && <img src={CardNoirLogo} css='height: 3rem;' />}
              {selectedBeneficiaryType === 'iban' && <SEPALogo css='width: 6rem; height: 2.25rem;' />}
              {selectedBeneficiaryType === 'swift' && <SwiftLogo css='width: 3.65rem; height: 3.65rem;' />}
            </div>
          </div>

          <div css='margin-top: 1rem; display: flex; gap: 1rem; align-items: center;'>
            <Button
              paddingInline='0.5rem'
              title='BACK'
              titleColor='muted'
              borderColor='muted'
              icon={<ArrowBackIcon />}
              iconColor='muted'
              onClick={() => setIsConfirming(false)}
              css='flex-shrink: 0;flex-grow: 0;'
            />
            <Button
              paddingInline='2rem'
              title='SAVE &amp; CLOSE'
              titleColor='success'
              borderColor='successHighlight'
              onClick={saveContact}
              css='flex-shrink: 0;flex-grow: 0;'
            />
            <Button
              paddingInline='2rem'
              title='SAVE &amp; PAY BENEFICIARY'
              titleColor='success'
              borderColor='successHighlight'
              onClick={() => {
                saveContact(true)
              }}
              css='flex-shrink: 0;flex-grow: 0;'
            />
            <Button
              buttonType='borderless'
              title={
                <>
                  Need
                  <br />
                  Help?
                </>
              }
              titleFontSize='extraSmall'
              titleFontFamily='normal'
              titleColor='muted'
              textAlign='center'
              textPadding='0'
              icon={<HelpIcon />}
              iconPadding='0.4rem 0.25rem 0.4rem 0.55rem'
              iconColor='muted'
              onClick={() => {}}
              css='flex-shrink: 0;flex-grow: 0;'
            />
          </div>
          {selectedBeneficiaryType !== 'cardnoir' && (
            <div css='display: flex; gap: 0.25rem; margin-top: 1rem;'>
              <div
                css={`
                  text-align: right;
                  font-size: ${(props) => props.theme.font.size.small};
                  color: ${(props) => props.theme.color.muted};
                `}
              >
                NOTE - It is your responsibility to ensure that the details you provide when sending funds are accurate.
                Incorrect details could result in an unsuccessful payment or funds paid to an incorrect account.
              </div>
              <Button
                buttonType='borderless'
                title={
                  <>
                    Print
                    <br />
                    Info
                  </>
                }
                titleFontSize='extraSmall'
                titleFontFamily='normal'
                titleColor='success'
                textPadding='0'
                icon={<PDFIcon />}
                iconPadding='0.4rem 0.25rem 0.4rem 0.55rem'
                iconColor='success'
                onClick={() => {}}
                css='min-width: unset;'
              />
            </div>
          )}
        </div>
      ) : (
        <>
          <div
            css={`
              display: flex;
              gap: 1.5rem;
              padding-bottom: 1rem;
              border-bottom: 2pt solid ${(props) => props.theme.color.successHighlight};
              margin-bottom: 1.5rem;
            `}
          >
            <FilterButton
              title='With a Card Noir profile'
              isActive={selectedBeneficiaryType === 'cardnoir'}
              onToggle={() => setSelectedBeneficiaryType('cardnoir')}
            />
            <FilterButton
              title='With an IBAN in Europe'
              isActive={selectedBeneficiaryType === 'iban'}
              onToggle={() => setSelectedBeneficiaryType('iban')}
            />
            <FilterButton
              title={
                <>
                  <div
                    css={`
                      display: none;
                      ${onDesktop`display: block;`}
                    `}
                  >
                    With an international account
                  </div>
                  <div
                    css={`
                      ${onDesktop`display: none;`}
                    `}
                  >
                    International account
                  </div>
                </>
              }
              isActive={selectedBeneficiaryType === 'swift'}
              onToggle={() => setSelectedBeneficiaryType('swift')}
            />
          </div>
          {selectedBeneficiaryType === 'cardnoir' && (
            <div>
              <Grid>
                <Input
                  type='email'
                  label='Email address'
                  id='email'
                  description='Enter either the email address of the Card Noir contact'
                  value={emailAddress}
                  onChange={onStringChange(setEmailAddress)}
                  errorMessage={
                    emailAddress === ''
                      ? 'This field is required'
                      : regexValidate(emailAddress, true, EMAIL_REGEX)
                      ? ''
                      : 'Please enter a valid email address'
                  }
                  showErrorOnBlur={true}
                />
                <CountryCodeDropdown
                  label='Country'
                  description='Country of residence of the contact'
                  value={country}
                  onChange={setCountry}
                  required={true}
                  errorMessage={country === undefined ? 'This field is required' : undefined}
                  showErrorOnBlur={true}
                />
                <Input
                  label={'Beneficiary Name'}
                  id='name'
                  description='Full name of the beneficiary on the account'
                  value={beneficiaryName}
                  onChange={onStringChange(setBeneficiaryName)}
                  errorMessage={beneficiaryName === '' ? 'This field is required' : ''}
                  showErrorOnBlur={true}
                />
                <Input
                  label='Alias'
                  id='alias'
                  description='Easily find this contact by giving them a unique ALIAS. Details will be saved in your beneficiary list'
                  value={alias}
                  onChange={onStringChange(setAlias)}
                  errorMessage={alias === '' ? 'This field is required' : ''}
                  showErrorOnBlur={true}
                />
              </Grid>
              <Grid>
                <Input
                  type='email'
                  label={
                    <>
                      Proof of payment - Email <span css='font-style: italic;'>(optional)</span>
                    </>
                  }
                  id='popemail'
                  description='OPTIONAL - Add a default email address to send a proof of payment to after a transaction'
                  value={popEmail}
                  onChange={onStringChange(setPopEmail)}
                  errorMessage={
                    regexValidate(popEmail, true, EMAIL_REGEX) || popEmail === ''
                      ? ''
                      : 'Please enter a valid email address'
                  }
                  showErrorOnBlur={true}
                />
                <PhoneNumberInput
                  label={
                    <>
                      Proof of payment - Mobile <span css='font-style: italic;'>(optional)</span>
                    </>
                  }
                  id='popmobile'
                  description='OPTIONAL - Add a default mobile number to send a proof of payment text message to after a transaction'
                  value={popMobileNumber}
                  onChange={setPopMobileNumber}
                />
              </Grid>
            </div>
          )}
          {selectedBeneficiaryType === 'iban' && (
            <div>
              <Grid>
                <CountryCodeDropdown
                  label='Destination country'
                  description='Country where you will be sending the funds'
                  value={country}
                  onChange={setCountry}
                />

                <Input
                  label='IBAN Number'
                  id='iban'
                  description='International bank account number'
                  value={IBANNumber}
                  onChange={onStringChange(setIBANNumber)}
                />
                <Input
                  label={'Beneficiary Name'}
                  id='name'
                  description='Full name of the beneficiary on the account'
                  value={beneficiaryName}
                  onChange={onStringChange(setBeneficiaryName)}
                />
                <Input
                  label='Alias'
                  id='alias'
                  description='Easily find this contact by giving them a unique ALIAS. Details will be saved in your beneficiary list'
                  value={alias}
                  onChange={onStringChange(setAlias)}
                />
              </Grid>
              <Grid>
                <Input
                  type='email'
                  label={
                    <>
                      Proof of payment - Email <span css='font-style: italic;'>(optional)</span>
                    </>
                  }
                  id='popemail'
                  description='OPTIONAL - Add a default email address to send a proof of payment to after a transaction'
                  value={popEmail}
                  onChange={onStringChange(setPopEmail)}
                  errorMessage={
                    popEmail === ''
                      ? 'This field is required'
                      : regexValidate(popEmail, true, EMAIL_REGEX)
                      ? ''
                      : 'Please enter a valid email address'
                  }
                  showErrorOnBlur={true}
                />
                <PhoneNumberInput
                  label={
                    <>
                      Proof of payment - Mobile <span css='font-style: italic;'>(optional)</span>
                    </>
                  }
                  id='popmobile'
                  description='OPTIONAL - Add a default mobile number to send a proof of payment text message to after a transaction'
                  value={popMobileNumber}
                  onChange={setPopMobileNumber}
                />
              </Grid>
            </div>
          )}
          {selectedBeneficiaryType === 'swift' && (
            <div>
              <Grid>
                <CountryCodeDropdown
                  label='Destination country'
                  description='Country where you will be sending the funds'
                  value={country}
                  onChange={setCountry}
                />
                <div />

                <Input
                  label='Account number / IBAN'
                  id='iban'
                  description='International bank account number'
                  value={IBANNumber}
                  onChange={onStringChange(setIBANNumber)}
                />
                <Input
                  label='SWIFT / BIC code'
                  id='ibic'
                  description='Bank identification code'
                  value={BICCode}
                  onChange={onStringChange(setBICCode)}
                />
              </Grid>
              <Grid>
                <Input
                  label={'Beneficiary Name'}
                  id='name'
                  description='Full name of the beneficiary on the account'
                  value={beneficiaryName}
                  onChange={onStringChange(setBeneficiaryName)}
                />
                <div />
                <FluidAddressInput
                  label='Beneficiary address'
                  description='Address of the recipient'
                  value={beneficiaryAddress}
                  onChange={setBeneficiaryAddress}
                />
              </Grid>
              <Grid>
                <Input
                  label={'Bank Name'}
                  id='name'
                  description='Name of the bank you are transferring funds to'
                  value={bankName}
                  onChange={onStringChange(setBankName)}
                />
                <div />
                <FluidAddressInput
                  label='Bank address'
                  description='Address of the bank'
                  value={bankAddress}
                  onChange={setBankAddress}
                />
              </Grid>
              <Grid>
                <Input
                  label='Alias'
                  id='alias'
                  description='Easily find this contact by giving them a unique ALIAS. Details will be saved in your beneficiary list'
                  value={alias}
                  onChange={onStringChange(setAlias)}
                />
              </Grid>
              <Grid>
                <Input
                  type='email'
                  label={
                    <>
                      Proof of payment - Email <span css='font-style: italic;'>(optional)</span>
                    </>
                  }
                  id='popemail'
                  description='OPTIONAL - Add a default email address to send a proof of payment to after a transaction'
                  value={popEmail}
                  onChange={onStringChange(setPopEmail)}
                  errorMessage={
                    popEmail === ''
                      ? 'This field is required'
                      : regexValidate(popEmail, true, EMAIL_REGEX)
                      ? ''
                      : 'Please enter a valid email address'
                  }
                  showErrorOnBlur={true}
                />
                <PhoneNumberInput
                  label={
                    <>
                      Proof of payment - Mobile <span css='font-style: italic;'>(optional)</span>
                    </>
                  }
                  id='popmobile'
                  description='OPTIONAL - Add a default mobile number to send a proof of payment text message to after a transaction'
                  value={popMobileNumber}
                  onChange={setPopMobileNumber}
                />
              </Grid>
            </div>
          )}
          <div css='display: flex; justify-content: end;'>
            <Button
              title={'CONTINUE'}
              titleColor='success'
              titleFontSize='normal'
              textAlign='center'
              borderColor='successHighlight'
              borderRadius={'5px'}
              disabled={selectedBeneficiaryType === 'cardnoir' && (emailAddress === '' || alias === '')}
              onClick={() => {
                setIsConfirming(true)
              }}
              paddingInline='3.5rem'
            />
            <Button
              buttonType='borderless'
              title={
                <>
                  Need
                  <br />
                  Help?
                </>
              }
              titleFontSize='extraSmall'
              titleFontFamily='normal'
              textAlign='center'
              textPadding='0'
              icon={<HelpIcon />}
              iconPadding='0.4rem 0.25rem 0.4rem 0.55rem'
              iconColor='muted'
              onClick={() => {}}
            />
          </div>
        </>
      )}
    </LightboxWrapper>
  )
}

export default LBAddBeneficiary

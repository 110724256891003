import { DateTime } from 'luxon'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router'
import { createMenuItem, Button, Menu } from 'stream-ui'
import styled from 'styled-components'
import IconLogout from '../assets/icons/Icon_Log_Out.svg'
import IconProfileInformation from '../assets/icons/Icon_Profile_Information_SVG.svg'
import IconStatementCentre from '../assets/icons/Icon_Statement.svg'
import CNLogo from '../assets/Logo_Card Noir_Background.svg'
import AuthContext from '../Auth'
import { buttonStyles, Container, onDesktop } from '../cardnoirStyledElements'
import MenuContext from '../MenuContext'
import SessionContext from '../SessionContext'

const StyledNavbar = styled.nav`
  z-index: 1000;
  padding: 0;
  position: relative;
  height: 3rem;
`

const NavbarContent = styled.div`
  height: 3rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.dark};
  position: fixed;
  top: 0;
`

const NavbarContainer = styled(Container)`
  display: flex;
  padding-top: unset;
  padding-bottom: 0.3rem;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  position: relative;

  ${onDesktop`
    height: 3rem;
    justify-content: center;
  `}
`

const AppNavbar = () => {
  const { setUser } = useContext(SessionContext)
  const { setToken } = useContext(AuthContext)
  const { menuCollapsed, setMenuCollapsed } = useContext(MenuContext)
  const navigate = useNavigate()

  const logout = () => {
    setUser()
    setToken('')
    setMenuCollapsed(true)
    navigate('/sign-in')
    localStorage.removeItem('card-noir-session')
    sessionStorage.removeItem('card-noir-token')
    sessionStorage.removeItem('card-noir-last-logged-in')
  }

  return (
    <StyledNavbar>
      <NavbarContent>
        <NavbarContainer>
          <div
            css={`
              position: absolute;
              top: 0;
              right: 1rem;
              ${onDesktop`
                right: unset;
                left: 0;
              `}
            `}
          >
            <Menu
              buttonText={
                <>
                  Menu &amp;
                  <br />
                  Settings
                </>
              }
              buttonStyle={{ ...buttonStyles.appNavbar, css: ['filter: ', menuCollapsed ? null : 'blur(5px);'] }}
              collapsed={menuCollapsed}
              setCollapsed={setMenuCollapsed}
              title={'Menu & Settings'}
              submenuAlignment='end'
              submenuBackgroundColor='background'
              items={[
                createMenuItem({
                  title: 'Card Overview',
                  description: 'Dashboard of all your balances',
                  color: 'primary',
                  borderColor: 'primary',
                  borderWidth: 2,
                  onClick: () => {
                    navigate('/member')
                    setMenuCollapsed(true)
                  },
                }),
                createMenuItem({
                  title: '+ Annual Fees',
                  description: 'Pay your annual profile fees',
                  color: 'error',
                  borderColor: 'primary',
                  borderWidth: 2,
                }),
                createMenuItem({
                  title: 'Transact',
                  description: 'Send, load and transfer funds',
                  color: 'primary',
                  borderColor: 'primary',
                  borderWidth: 2,
                  subItems: [
                    createMenuItem({
                      title: 'Send funds',
                      description: 'Pay a beneficiary',
                    }),
                    createMenuItem({
                      title: 'Load funds',
                      description: 'Add funds to one of your cards',
                    }),
                    createMenuItem({
                      title: 'Transfer funds internally',
                      description: 'Move funds between your cards',
                    }),
                    createMenuItem({
                      title: 'Direct Debit Orders',
                      description: 'Manage all direct debit orders',
                    }),
                    createMenuItem({
                      title: 'Outstanding Authorizations',
                      description: 'Transactions that need release',
                    }),
                    createMenuItem({
                      title: 'Dispute Centre',
                      description: 'Transaction disputes are logged here',
                    }),
                  ],
                }),
                createMenuItem({
                  title: 'Beneficiary Centre',
                  description: 'Add and manage beneficiaries',
                  color: 'primary',
                  borderColor: 'primary',
                  borderWidth: 2,
                  onClick: () => {
                    navigate('/member/beneficiary-centre')
                    setMenuCollapsed(true)
                  },
                }),
                createMenuItem({
                  title: 'Statement Centre',
                  description: 'Analytics, transaction history, downloads',
                  color: 'primary',
                  borderColor: 'primary',
                  borderWidth: 2,
                  onClick: () => {
                    navigate('/member/statement-centre')
                    setMenuCollapsed(true)
                  },
                }),
                createMenuItem({
                  title: 'Profile Information',
                  description: 'Update info, app personalisation',
                  color: 'primary',
                  borderColor: 'primary',
                  borderWidth: 2,
                  subItems: [
                    createMenuItem({
                      title: 'Update Profile Information',
                      description: 'Passwords, manage info, address',
                    }),
                    createMenuItem({
                      title: 'App Personalization',
                      description: 'Language, notifications, theme, etc',
                    }),
                    createMenuItem({
                      title: 'Security Centre',
                      description: 'Connected devices currently logged in',
                    }),
                  ],
                }),
                createMenuItem({
                  title: '+ Add More Cards',
                  description: 'Order physical or add virtual cards',
                  color: 'success',
                  borderColor: 'primary',
                  borderWidth: 2,
                }),
                createMenuItem({
                  title: '+ Activate a Physical Card',
                  description: 'Initiate a physical card you received',
                  color: 'error',
                }),
                createMenuItem({
                  title: 'Mail, News & Security Info',
                  description: 'Latest news & product updates',
                  borderColor: 'primary',
                  borderWidth: 2,
                }),
                createMenuItem({
                  title: 'Contact & Help Centre',
                  description: 'Reach out for assistance & give feedback',
                }),
                createMenuItem({
                  title: 'Legal Documents',
                  description: 'Dashboard for all legal information',
                }),
                createMenuItem({
                  title: 'Log Out',
                  description: 'Log out of your profile',
                  color: 'primary',
                  borderColor: 'primary',
                  borderWidth: 2,
                  onClick: logout,
                }),
              ]}
            />
          </div>
          <div
            css={`
              filter: ${menuCollapsed ? null : 'blur(5px)'};
              position: absolute;
              display: none;
              ${onDesktop`
                display: block;
                left: 6.5rem;
              `}
            `}
          >
            <Button
              icon={<IconProfileInformation />}
              title={
                <span>
                  Your
                  <br />
                  Profile
                </span>
              }
              // onClick={() => setMenuCollapsed(!menuCollapsed)}
              {...buttonStyles.appNavbar}
            />
          </div>
          <div
            css={`
              filter: ${menuCollapsed ? null : 'blur(5px)'};
              display: none;
              ${onDesktop`
                display: block;
                position: absolute;
                top: 0;
                right: 0;
              `}
            `}
          >
            <Button
              icon={<IconStatementCentre />}
              title={
                <span>
                  Statement
                  <br />
                  Centre
                </span>
              }
              onClick={() => {
                navigate('/member/statement-centre')
                setMenuCollapsed(true)
              }}
              {...buttonStyles.appNavbar}
              css={`
                display: none;
                @media (min-width: 1050px) {
                  display: inline-flex;
                }
              `}
            />
            <Button
              icon={<IconLogout />}
              title={
                <span>
                  Log
                  <br />
                  Out
                </span>
              }
              onClick={logout}
              {...buttonStyles.appNavbar}
              iconPadding='0.4rem 0.1rem 0.4rem 0.7rem'
            />
            <div
              css={`
                display: inline-flex;
                flex-direction: column;
                color: ${(props) => props.theme.color.textPrimary};
                font-size: ${(props) => props.theme.font.size.small};
                font-family: ${(props) => props.theme.font.family.bold};
                text-align: right;
                width: 8.75rem;
              `}
            >
              <div>{DateTime.now().toFormat('HH:mm a (ZZZZ)')}</div>
              <div>{DateTime.now().toFormat('dd LLLL, yyyy')}</div>
            </div>
          </div>
          <div
            css={`
              display: flex;
              filter: ${menuCollapsed ? null : 'blur(5px)'};
            `}
          >
            <CNLogo css='display: inline-block; width: 13.5rem; padding-top: 0.3rem;' />
          </div>
        </NavbarContainer>
      </NavbarContent>
    </StyledNavbar>
  )
}

export default AppNavbar

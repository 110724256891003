import React from 'react'
import { Button } from 'stream-ui'
import CardFreezeIcon from '../assets/icons/Icon_Card_Freeze.svg'
import CardLimitsIcon from '../assets/icons/Icon_Card_Limits.svg'
import CardNameEditIcon from '../assets/icons/Icon_Card_Name_Edit.svg'
import CardPINIcon from '../assets/icons/Icon_Card_PIN.svg'
import CardCancelIcon from '../assets/icons/Icon_Close_Delete_No.svg'
import CopyIcon from '../assets/icons/Icon_Copy.svg'
import CardIcon from '../assets/icons/Icon_Funds_Card.svg'
import FundsLoadIcon from '../assets/icons/Icon_Funds_Load.svg'
import FundsSendIcon from '../assets/icons/Icon_Funds_Send.svg'
import FundsTransferIcon from '../assets/icons/Icon_Funds_Transfer.svg'
import CardReplacementIcon from '../assets/icons/Icon_Replacement_Card.svg'
import { buttonStyles, onDesktop } from '../cardnoirStyledElements'
import { formatIBAN } from '../utils'


const StatementCentreCardOptions = ({ account, className }) => {
  const accentColor = account.cards[0].type + 'CardHighlight'
  return (
    <div className={className}>
      <div
        $accent={accentColor}
        css={`
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;
          row-gap: 1rem;
          column-gap: 1.5rem;
          padding: 1rem;
          border-top: 2pt solid ${(props) => props.theme.color[props.$accent]};
          border-bottom: 2pt solid ${(props) => props.theme.color[props.$accent]};

          ${onDesktop`
            border-top: none;
          `}
        `}
      >
        <Button
          title='Load funds'
          description='receive and add'
          icon={<FundsLoadIcon />}
          {...buttonStyles.cardOptionsStyle}
          borderColor={accentColor}
        />
        <Button
          title='Send funds'
          description='pay a beneficiary'
          icon={<FundsSendIcon />}
          {...buttonStyles.cardOptionsStyle}
          borderColor={accentColor}
        />
        <Button
          title='Transfer'
          description='to another card'
          icon={<FundsTransferIcon />}
          {...buttonStyles.cardOptionsStyle}
          borderColor={accentColor}
        />
        <Button
          title='Card limits'
          description='adjust and manage'
          icon={<CardLimitsIcon />}
          {...buttonStyles.cardOptionsStyle}
          borderColor={accentColor}
        />
      </div>
      <div css='display: flex; padding: 1rem; justify-content: space-between; align-items: center; width: 100%;'>
        <div>
          <div>IBAN</div>
          <div
            css={`
              font-size: ${(props) => props.theme.font.size.small};
              color: ${(props) => props.theme.color.muted};
              padding-top: 0.25rem;
              padding-left: 1rem;
            `}
          >
            {formatIBAN(account.bank_details.iban)}
          </div>
        </div>
        <Button
          description='copy'
          icon={<CopyIcon />}
          buttonType='vertical'
          textAlign='center'
          onClick={() => navigator.clipboard.writeText(account.bank_details.iban.replaceAll(' ', ''))}
        />
      </div>
      <div
        $accent={accentColor}
        css={`
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;
          row-gap: 1rem;
          column-gap: 1.5rem;
          padding: 1rem;
          border-top: 2pt solid ${(props) => props.theme.color[props.$accent]};
        `}
      >
        <Button
          title='Update Name'
          description='name this balance'
          icon={<CardNameEditIcon />}
          {...buttonStyles.cardOptionsStyle}
          borderColor={'successHighlight'}
        />
        <Button
          title='Manage PIN'
          description='view and modify'
          icon={<CardPINIcon />}
          {...buttonStyles.cardOptionsStyle}
          borderColor={'successHighlight'}
        />
        <Button
          title='Freeze Card'
          description='temporarily freeze'
          icon={<CardFreezeIcon />}
          {...buttonStyles.cardOptionsStyle}
          borderColor={'successHighlight'}
        />
        <Button
          title='Cancel Card'
          description='permanently and manage'
          icon={<CardCancelIcon />}
          {...buttonStyles.cardOptionsStyle}
          borderColor={'successHighlight'}
        />
        <Button
          title='Replacement'
          description='order a new card'
          icon={<CardReplacementIcon />}
          {...buttonStyles.cardOptionsStyle}
          borderColor={'successHighlight'}
        />
        <Button
          title='+ Joint Card'
          description='add a second card'
          icon={<CardIcon />}
          {...buttonStyles.cardOptionsStyle}
          borderColor={'successHighlight'}
        />
      </div>
      {/* <div
        $accent={accentColor}
        css={`
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;
          row-gap: 1rem;
          column-gap: 1.5rem;
          padding: 1rem;
          border-top: 2pt solid ${(props) => props.theme.color[props.$accent]};
        `}
      >
        <Button
          title='Apple Pay'
          description='link apple pay'
          icon={<CardIcon />}
          {...buttonStyles.cardOptionsStyle}
          borderColor={'muted'}
        />
        <Button
          title='Samsung Pay'
          description='link samsung pay'
          icon={<CardIcon />}
          {...buttonStyles.cardOptionsStyle}
          borderColor={'muted'}
        />
        <Button
          title='Google Pay'
          description='link google pay'
          icon={<CardIcon />}
          {...buttonStyles.cardOptionsStyle}
          borderColor={'muted'}
        />
        <Button
          title='Garmin Pay'
          description='link garmin pay'
          icon={<CardIcon />}
          {...buttonStyles.cardOptionsStyle}
          borderColor={'muted'}
        />
      </div> */}
    </div>
  )
}

export default StatementCentreCardOptions

import React, { useContext } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Button } from 'stream-ui'
import IconPlus from '../assets/icons/Icon_Plus_Order_Card.svg'
import IconProfileInformation from '../assets/icons/Icon_Profile_Information_SVG.svg'
import Auth from '../Auth'
import { Container, onDesktop } from '../cardnoirStyledElements'
import ChatButton from '../ChatButton'
import AppHeader from '../layout/AppHeader'
import AppWrapper from '../layout/AppWrapper'
import SessionContext from '../SessionContext'
import HomeAccount from './HomeAccount'

const Home = () => {
  const { user } = useContext(SessionContext)
  const { lastLoggedIn } = useContext(Auth)
  const { loadingError } = useOutletContext()

  return (
    <AppWrapper
      title='Card Noir - Dashboard'
      appHeader={
        <AppHeader
          title={'Card overview'}
          icon={
            <IconProfileInformation
              css={`
                fill: ${(props) => props.theme.color.primary};
              `}
            />
          }
          buttons={[
            <Button
              key={0}
              buttonType='bordered-icon'
              icon={<IconPlus />}
              iconColor='success'
              titleColor='success'
              borderColor='successHighlight'
              title='+ Add More Cards'
              description='Order physical or add virtual cards'
              hideTextOnMobile={true}
              textPadding='0 0.75rem'
            />,
          ]}
          description={`Last log in - ${lastLoggedIn}`}
        />
      }
    >
      <Container
        css={`
          padding: 2rem 0rem;
          display: grid;
          gap: 3.125rem;
          align-content: start;

          ${onDesktop`
              padding: 3.125rem 0;
            `}
        `}
      >
        {user === undefined && !loadingError && <div>Loading...</div>}
        {user === undefined && loadingError && (
          <div>We&apos;ve met with an unexpected error while loading the dashboard. Please try again later.</div>
        )}
        {user && user.accounts.map((v, i) => <HomeAccount key={i} account={v} isFirst={i === 0} />)}

        <ChatButton />
      </Container>
    </AppWrapper>
  )
}

export default Home

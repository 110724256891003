import { darken } from 'polished'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { css as styledCSS } from 'styled-components'

const ButtonTextTitle = styled.div`
  font-size: ${(props) => props.theme.font.size[props.$textFontSize]};
  font-family: ${(props) => props.theme.font.family[props.$titleFontFamily]};
  letter-spacing: ${(props) => props.$titleLetterSpacing};
  text-align: ${(props) => props.$textAlign};
  color: ${(props) => props.theme.color[props.$titleColor]};
`
const ButtonTextDescription = styled.div`
  font-size: ${(props) => props.theme.font.size[props.$descriptionFontSize]};
  text-align: ${(props) => props.$textAlign};
  color: ${(props) => props.theme.color[props.$descriptionColor]};
`

export const ButtonText = ({
  title,
  description,
  textAlign,
  titleLetterSpacing,
  titleFontSize,
  titleFontFamily,
  titleColor,
  descriptionFontSize,
  descriptionColor,
  cssForTextWrapper,
  cssForTitle,
  cssForDescription,
}) => {
  return (
    <div
      css={`
        ${cssForTextWrapper}
      `}
    >
      {title && (
        <ButtonTextTitle
          $textAlign={textAlign}
          $textFontSize={titleFontSize}
          $titleColor={titleColor}
          $titleLetterSpacing={titleLetterSpacing}
          $titleFontFamily={titleFontFamily}
          css={`
            ${cssForTitle}
          `}
        >
          {title}
        </ButtonTextTitle>
      )}
      {description && (
        <ButtonTextDescription
          $textAlign={textAlign}
          $descriptionColor={descriptionColor}
          $descriptionFontSize={descriptionFontSize}
          css={`
            ${cssForDescription}
          `}
        >
          {description}
        </ButtonTextDescription>
      )}
    </div>
  )
}

const IconWrapper = styled.div`
  position: absolute;
  left: ${(props) =>
    props.$isVertical
      ? null
      : 'calc(-' + (props.$borderWidth || '0px') + ' + ' + (props.$paddingInline || '0px') + ')'};
  top: ${(props) =>
    props.$isVertical
      ? 'calc(-' + (props.$borderWidth || '0px') + ' + ' + (props.$paddingInline || '0px') + ')'
      : null};

  height: ${(props) => props.$iconHeight};
  width: ${(props) => props.$iconWidth};
  padding: ${(props) => props.$iconPadding};
  margin: unset;

  border-style: solid;
  border-width: ${(props) => props.$borderWidth};
  border-radius: ${(props) => props.$borderRadius};
  border-color: ${(props) => props.theme.color[props.$borderColor] || props.theme.color.textPrimary};

  background-color: ${(props) => props.$iconBackgroundColor && props.theme.color[props.$iconBackgroundColor]};
  
  svg * {
    fill: ${(props) => props.theme.color[props.$iconFillColor] || props.theme.color.textPrimary};
    stroke: ${(props) => props.theme.color[props.$iconStrokeColor] || props.theme.color.textPrimary};
  }
`

const DefaultButton = styled.button`
  position: relative;
  display: inline-flex;
  justify-content: ${(props) => (props.$isVertical ? 'center' : 'start')};
  align-items: center;

  margin: unset;
  min-height: ${(props) => props.$buttonMinWidthHeight};
  min-width: ${(props) => props.$buttonMinWidthHeight};
  padding-left: ${(props) =>
    props.$isVertical
      ? props.$paddingBlock
      : props.$hasIcon
      ? 'calc(' + props.$iconWidth + ' + ' + (props.$paddingInline || '0px') + ' - (2 * ' + props.$borderWidth + '))'
      : props.$paddingInline};
  padding-top: ${(props) =>
    props.$isVertical
      ? props.$hasIcon
        ? 'calc(' + props.$iconHeight + ' + ' + (props.$paddingInline || '0px') + ' - (2 * ' + props.$borderWidth + '))'
        : props.$paddingInline
      : props.$paddingBlock};

  padding-right: ${(props) => (props.$isVertical ? props.$paddingBlock : props.$paddingInline)};
  padding-bottom: ${(props) => (props.$isVertical ? props.$paddingInline : props.$paddingBlock)};

  background-color: ${(props) => props.theme.color[props.$backgroundColor] || 'unset'};
  border-style: solid;
  border-width: ${(props) => props.$borderWidth};
  border-radius: ${(props) => props.$borderRadius};
  border-color: ${(props) => props.theme.color[props.$borderColor] || props.theme.color.textPrimary};

  font-variant-numeric: lining-nums;
  cursor: pointer;

  :hover {
    box-shadow: 0px 0px 6px
      ${(props) =>
        props.theme.color[props.$onHoverBorderShadowColor] ||
        props.theme.color[props.$borderColor] ||
        props.theme.color.textPrimary};

    background-color: ${(props) => props.theme.color[props.$onHoverBackgroundColor]};
    border-color: ${(props) => props.theme.color[props.$onHoverBorderColor] || props.theme.color[props.$borderColor]};

    ${ButtonTextTitle}, ${ButtonTextDescription} {
      color: ${(props) => props.theme.color[props.$onHoverTextColor]};
    }

    ${IconWrapper} {
      box-shadow: 0px 0px 6px
        ${(props) =>
          props.theme.color[props.$onHoverIconBorderShadowColor] ||
          props.theme.color[props.$iconBorderColor] ||
          props.theme.color.textPrimary};
      background-color: ${(props) =>
        props.$onHoverIconBackgroundColor && props.theme.color[props.$onHoverIconBackgroundColor]};
      svg {
        filter: drop-shadow(
          0px 0px 1px
            ${(props) =>
              props.theme.color[props.$onHoverIconShadowColor] ||
              props.theme.color[props.$iconFillColor] ||
              props.theme.color.textPrimary}
        );

        * {
          fill: ${(props) => props.theme.color[props.$onHoverIconFillColor]};
          stroke: ${(props) => props.theme.color[props.$onHoverIconStrokeColor]};
        }
      }
    }
  }

  :active {
    box-shadow: 0px 0px 6px transparent;

    background-color: ${(props) => props.$backgroundColor && darken(0.2, props.theme.color[props.$backgroundColor])};

    border-color: ${(props) =>
      (props.$onHoverBorderColor || props.$borderColor) &&
      darken(0.3, props.theme.color[props.$onHoverBorderColor] || props.theme.color[props.$borderColor])};

    ${IconWrapper} {
      box-shadow: 0px 0px 6px transparent;
      background-color: ${(props) =>
        props.$onHoverIconBackgroundColor && props.theme.color[props.$onHoverIconBackgroundColor]};
      svg {
        filter: drop-shadow(0px 0px 1px transparent);
        * {
          fill: ${(props) =>
            (props.$onHoverIconFillColor || props.$iconFillColor) &&
            darken(0.2, props.theme.color[props.$onHoverIconFillColor] || props.theme.color[props.$iconFillColor])};
          stroke: ${(props) =>
            (props.$onHoverIconStrokeColor || props.$iconStrokeColor) &&
            darken(0.2, props.theme.color[props.$onHoverIconStrokeColor] || props.theme.color[props.$iconStrokeColor])};
        }
      }
    }
  }

  &:disabled,
  &:disabled:hover {
    border-color: ${({ theme }) => theme.color.buttonDisabled};
    box-shadow: 0px 0px 6px transparent;
    color: ${({ theme }) => theme.color.buttonDisabled};
    background: unset;
    cursor: not-allowed;
    ${ButtonTextTitle}, ${ButtonTextDescription} {
      color: ${({ theme }) => theme.color.buttonDisabled};
    }
  }

  :focus-visible {
    outline: ${({ theme }) => theme.color.outline} solid 1px;
  }
`

/**
 * Opinionated button component to support common button patterns used throughout CN and maybe Leikur.
 *
 * Note: All custom colors/font size/font family are keys defined in the styled-components theme objects. 
 * E.g. `borderColor='primary'` will look for `theme.color['primary']`
 *
 * Props:
 * - buttonSize - Value: `small` | `normal`. Default: `normal`
 * - buttonType - Value: `normal` | `vertical` | `icon` | `icon-bordered` | `borderless`. Default: `normal`
 * - borderColor - Transparent when `buttonType` is `borderless`. Accepts a string key from the `theme.color` object. Default: `textPrimary`
 * - backgroundColor - Accepts a string key from the `theme.color` object. Default: `unset`
 * - paddingInline - Sets a padding at the start and end on the button (top/bottom for `buttonType` `vertical`, left/right for others). Default: `0px`.
 * -- Note 1: This is used to calculate the position of the icon if present. 
 * -- Note 2: The unit is required.
 * - paddingBlock - Sets a padding at the logical block start and end on the button (left/right for `buttonType` `vertical` , top/bottom for others). Default: `0px`. Same notes as `paddingInline`.
 * - icon: SVG icon that can be rendered. Ideally do not set the height or width.
 * - iconFillColor - Accepts a string key from the `theme.color` object.
 * - iconStrokeColor -  Accepts a string key from the `theme.color` object.
 * - iconColor - Shorthand for both iconFillColor and iconStrokeColor. Accepts a string key from the `theme.color` object. Default: `textPrimary`
 * - textAlign - Text alignment for both `title` and `description`. Value: `start` | `end` | `left` | `right` | `center` | `justify`. Default: `left` 
 * - cssForTextWrapper - styled-components's css array to pass to the wrapper of the `title` and `description`. _TIP: Use styled-component's `css` function._
 * - hideTextOnMobile - Hides the text when screen size is 575px or less. Default: `false`.
 * - title - Main text for the button. This is not shown when `buttonType` is `vertical`, `description` is used instead. 
    titleColor = 'textPrimary',
    titleFontSize = 'buttonTitle',
    titleFontFamily = 'buttonTitleFontFamily',
    titleLetterSpacing = '2px',
    cssForTitle,
    description,
    descriptionColor = 'textSecondary',
    descriptionFontSize = 'extraSmall',
    cssForDescription,

    onHoverBackgroundColor,
    onHoverTextColor, // When set, overrides ButtonText color for both title and description
    onHoverBorderColor,
    onHoverBorderShadowColor,
    onHoverIconFillColor,
    onHoverIconStrokeColor,
    onHoverIconColor, // shorthand for both fill and stroke
    onHoverIconBorderShadowColor,
    onHoverIconShadowColor,

 * 
 */
const Button = (props) => {
  const {
    buttonSize,
    buttonType,
    borderColor,
    borderWidth,
    borderRadius,

    backgroundColor,
    paddingInline,
    paddingBlock,

    icon,
    iconFillColor,
    iconStrokeColor,
    iconColor,
    iconBackgroundColor,
    cssForIconWrapper,

    textAlign,
    cssForTextWrapper,
    hideTextOnMobile,

    title,
    titleColor,
    titleFontSize,
    titleFontFamily,
    titleLetterSpacing,
    cssForTitle,
    description,
    descriptionColor,
    descriptionFontSize,
    cssForDescription,

    onHoverBackgroundColor,
    onHoverTextColor,
    onHoverBorderColor,
    onHoverBorderShadowColor,
    onHoverIconFillColor,
    onHoverIconStrokeColor,
    onHoverIconColor,
    onHoverIconBackgroundColor,
    onHoverIconBorderShadowColor,
    onHoverIconShadowColor,

    className,
    ...rest
  } = props
  const isVerticalButton = buttonType === 'vertical' || buttonType === 'vertical-borderless'
  const hasIconBorder = buttonType === 'bordered-icon'
  const hasOuterBorder = buttonType === 'normal' || buttonType === 'vertical'

  let buttonMinWidthHeight = buttonSize === 'small' ? '2.25rem' : buttonSize === 'extra-small' ? '1.5rem' : '2.813rem'

  let iconHeight = isVerticalButton
    ? '2rem'
    : buttonSize === 'small'
    ? '2.25rem'
    : buttonSize === 'extra-small'
    ? '1.5rem'
    : '2.813rem'

  let iconWidth = isVerticalButton
    ? '2rem'
    : buttonSize === 'small'
    ? '2.25rem'
    : buttonSize === 'extra-small'
    ? '1.5rem'
    : '2.813rem'

  let iconPadding = props.iconPadding
    ? props.iconPadding
    : isVerticalButton
    ? '0.25rem 0.2rem 0.15rem 0.2rem'
    : buttonSize === 'extra-small'
    ? '0rem'
    : '0.4rem'
  let textPadding = props.textPadding ? props.textPadding : isVerticalButton ? '0 0.2rem 0.2rem' : '0 0.5rem'

  return (
    <DefaultButton
      $isVertical={isVerticalButton}
      $hasIcon={icon !== undefined}
      $borderColor={hasOuterBorder ? borderColor : 'transparent'}
      $borderWidth={borderWidth}
      $borderRadius={borderRadius}
      $backgroundColor={backgroundColor}
      $paddingInline={paddingInline}
      $paddingBlock={paddingBlock}
      $iconBorderColor={icon !== undefined && hasIconBorder ? borderColor : 'transparent'}
      $onHoverBorderShadowColor={onHoverBorderShadowColor}
      $onHoverBorderColor={onHoverBorderColor}
      $onHoverBackgroundColor={onHoverBackgroundColor}
      $onHoverIconBackgroundColor={onHoverIconBackgroundColor}
      $onHoverTextColor={onHoverTextColor}
      $onHoverIconFillColor={onHoverIconFillColor || onHoverIconColor}
      $onHoverIconStrokeColor={onHoverIconStrokeColor || onHoverIconColor}
      $onHoverIconBorderShadowColor={onHoverIconBorderShadowColor}
      $onHoverIconShadowColor={onHoverIconShadowColor}
      $buttonMinWidthHeight={buttonMinWidthHeight}
      $iconHeight={iconHeight}
      $iconWidth={iconWidth}
      $iconFillColor={iconFillColor || iconColor}
      $iconStrokeColor={iconStrokeColor || iconColor}
      className={className}
      {...rest}
    >
      {icon && (
        <IconWrapper
          $iconBackgroundColor={iconBackgroundColor}
          $iconFillColor={iconFillColor || iconColor}
          $iconStrokeColor={iconStrokeColor || iconColor}
          $borderColor={hasIconBorder ? borderColor : 'transparent'}
          $borderWidth={borderWidth}
          $borderRadius={borderRadius}
          $paddingInline={paddingInline}
          $isVertical={isVerticalButton}
          $iconHeight={iconHeight}
          $iconWidth={iconWidth}
          $iconPadding={iconPadding}
          css={`
            ${cssForIconWrapper}
          `}
        >
          {icon}
        </IconWrapper>
      )}
      {(title || description) && (
        <ButtonText
          title={title}
          description={description}
          textAlign={textAlign}
          titleLetterSpacing={titleLetterSpacing}
          titleFontFamily={titleFontFamily}
          titleFontSize={titleFontSize}
          titleColor={titleColor}
          descriptionFontSize={descriptionFontSize}
          descriptionColor={descriptionColor}
          cssForTextWrapper={styledCSS`
            line-height: ${isVerticalButton ? 1 : null};
            padding: ${textPadding};
            ${cssForTextWrapper}
            ${
              hideTextOnMobile &&
              `@media (max-width: 575px) {
                display: none;
              }`
            }
          `}
          cssForTitle={cssForTitle}
          cssForDescription={cssForDescription}
        />
      )}
    </DefaultButton>
  )
}

Button.propTypes = {
  buttonSize: PropTypes.oneOf(['extra-small', 'small', 'normal']),
  buttonType: PropTypes.oneOf(['normal', 'borderless', 'vertical', 'vertical-borderless', 'bordered-icon']),
  borderColor: PropTypes.string,
  borderWidth: PropTypes.string,
  borderRadius: PropTypes.string,
  backgroundColor: PropTypes.string,
  paddingInline: PropTypes.string,
  paddingBlock: PropTypes.string,
  icon: PropTypes.node,
  iconPadding: PropTypes.string,
  iconFillColor: PropTypes.string,
  iconStrokeColor: PropTypes.string,
  iconColor: PropTypes.string,
  iconBackgroundColor: PropTypes.string,
  cssForIconWrapper: PropTypes.array,
  textAlign: PropTypes.oneOf(['start', 'end', 'left', 'right', 'center', 'justify']),
  textPadding: PropTypes.string,
  cssForTextWrapper: PropTypes.array,
  title: PropTypes.node,
  titleColor: PropTypes.string,
  titleFontSize: PropTypes.string,
  titleFontFamily: PropTypes.string,
  titleLetterSpacing: PropTypes.string,
  cssForTitle: PropTypes.array,
  description: PropTypes.node,
  descriptionColor: PropTypes.string,
  descriptionFontSize: PropTypes.string,
  cssForDescription: PropTypes.array,
  onHoverBackgroundColor: PropTypes.string,
  onHoverTextColor: PropTypes.string,
  onHoverBorderColor: PropTypes.string,
  onHoverBorderShadowColor: PropTypes.string,
  onHoverIconFillColor: PropTypes.string,
  onHoverIconStrokeColor: PropTypes.string,
  onHoverIconColor: PropTypes.string,
  onHoverIconBackgroundColor: PropTypes.string,
  onHoverIconBorderShadowColor: PropTypes.string,
  onHoverIconShadowColor: PropTypes.string,
}

Button.defaultProps = {
  size: 'normal',
  buttonType: 'normal',
  borderWidth: '2pt',
  borderRadius: '6px',
  paddingInline: '0px',
  paddingBlock: '0px',
  textAlign: 'left',
  hideTextOnMobile: false,
  titleColor: 'textPrimary',
  titleFontSize: 'buttonTitle',
  titleFontFamily: 'buttonTitleFontFamily',
  descriptionColor: 'textSecondary',
  descriptionFontSize: 'extraSmall',
}
export default Button

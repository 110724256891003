import styled, { css } from 'styled-components'

export const Label = styled.label`
  color: ${({ theme }) => theme.color.label};
  font-family: ${({ theme }) => theme.font.family.label};
  font-size: ${({ theme }) => theme.font.size.label};
  display: block;
  margin-bottom: 0.25rem;
`

export const Description = styled.span`
  margin-top: 0.4rem;
  font-family: ${({ theme }) => theme.font.family.regular};
  color: ${({ theme }) => theme.color.textSecondary};
  font-size: ${({ theme }) => theme.font.size.extraSmall};
  display: block;
`

export const ErrorMessage = styled(Description)`
  padding-left: 2rem;
  color: ${({ theme }) => theme.color.error};
`

export const DisplayOnlyText = styled.div`
  font-size: ${({ theme }) => theme.font.size.normal};
  font-family: ${({ theme }) => theme.font.family.regular};
  color: ${({ theme }) => theme.color.success};
  border-width: 0;
  border-bottom-width: 1px;
  border-bottom-color: transparent;
  display: block;
  margin-top: ${(props) => (props.label ? '0.125rem' : '0')};
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 100%;
  height: 1.5rem;
`

/**
 * Adds spin animation
 */
export const spinMixin = css`
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

/**
 * Opinionated grid for input elements.
 *
 * AddressInput uses this to set the layout of the various elements, however, one can override the css by passing in the prop `gridCSS`.
 */
export const InputGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(288px, 328px));
  gap: 2rem;
  justify-content: center;
`

import React from 'react'

import { getCardImage } from './cardsByBrand'

const PhysicalCardDetails = ({ cardholderName = '', expiryDate, cardNumber, ...props }) => (
  <svg width='300' height='190' viewBox='0 0 300 190' fill='none' {...props}>
    <g id='physical_card_details'>
      <path
        id='Vector'
        d='M34.5798 45.1432V53.4404C34.5798 54.702 33.5563 55.7255 32.2944 55.7255H28.1149C27.4971 55.7255 26.914 55.4786 26.4884 55.0395L22 50.4824C22 50.4824 22.7206 49.7755 22.7413 49.7688C22.8648 49.6588 23.0227 49.5971 23.1942 49.5971C23.3177 49.5971 23.4344 49.6313 23.5374 49.693C23.5578 49.7001 26.0012 51.1 26.0012 51.1V44.2852C26.0015 43.8116 26.3856 43.4274 26.8593 43.4274C27.3329 43.4274 27.7171 43.8116 27.7171 44.2852L27.7168 48.2932H28.2864V42.8578C28.2869 42.3842 28.6708 42 29.1445 42C29.6248 42 30.0022 42.3842 30.0022 42.8578V48.2932H30.5786V43.4274C30.5786 42.9538 30.9562 42.5696 31.4366 42.5696C31.9102 42.5696 32.2944 42.9538 32.2944 43.4274V48.2932H32.864V45.1432C32.864 44.6696 33.2481 44.2854 33.7218 44.2854C34.1954 44.2854 34.5798 44.6696 34.5798 45.1432Z'
        fill='#FED683'
      />
      <g id='Physical Card'>
        <path
          d='M39.96 55V46.48H43.536C43.92 46.48 44.272 46.56 44.592 46.72C44.92 46.88 45.2 47.092 45.432 47.356C45.672 47.62 45.856 47.916 45.984 48.244C46.112 48.572 46.176 48.908 46.176 49.252C46.176 49.732 46.068 50.184 45.852 50.608C45.636 51.032 45.336 51.376 44.952 51.64C44.568 51.904 44.12 52.036 43.608 52.036H41.304V55H39.96ZM41.304 50.848H43.524C43.9 50.848 44.208 50.696 44.448 50.392C44.688 50.088 44.808 49.708 44.808 49.252C44.808 48.94 44.744 48.668 44.616 48.436C44.488 48.196 44.32 48.008 44.112 47.872C43.912 47.736 43.692 47.668 43.452 47.668H41.304V50.848Z'
          fill='#FED683'
        />
        <path
          d='M52.8966 55H51.5766V51.484C51.5766 50.916 51.4726 50.492 51.2646 50.212C51.0566 49.932 50.7646 49.792 50.3886 49.792C50.1486 49.792 49.9046 49.856 49.6566 49.984C49.4166 50.112 49.2006 50.288 49.0086 50.512C48.8166 50.728 48.6766 50.98 48.5886 51.268V55H47.2686V46.24H48.5886V49.984C48.8286 49.552 49.1566 49.216 49.5726 48.976C49.9886 48.736 50.4366 48.616 50.9166 48.616C51.4366 48.616 51.8406 48.736 52.1286 48.976C52.4166 49.208 52.6166 49.52 52.7286 49.912C52.8406 50.296 52.8966 50.716 52.8966 51.172V55Z'
          fill='#FED683'
        />
        <path
          d='M54.5158 56.476C54.6358 56.5 54.7518 56.52 54.8638 56.536C54.9838 56.552 55.0798 56.56 55.1518 56.56C55.2878 56.56 55.4038 56.516 55.4998 56.428C55.6038 56.348 55.7078 56.196 55.8118 55.972C55.9158 55.748 56.0438 55.424 56.1958 55L53.6158 48.724H54.9958L56.9158 53.656L58.6198 48.724H59.8918L57.0478 56.452C56.9198 56.804 56.6958 57.104 56.3758 57.352C56.0638 57.6 55.6598 57.724 55.1638 57.724C55.0678 57.724 54.9678 57.716 54.8638 57.7C54.7598 57.684 54.6438 57.66 54.5158 57.628V56.476Z'
          fill='#FED683'
        />
        <path
          d='M62.9302 55.12C62.4022 55.12 61.9022 55.032 61.4302 54.856C60.9582 54.68 60.5542 54.424 60.2182 54.088L60.7102 53.2C61.0702 53.52 61.4302 53.752 61.7902 53.896C62.1502 54.04 62.5142 54.112 62.8822 54.112C63.2502 54.112 63.5502 54.044 63.7822 53.908C64.0142 53.764 64.1302 53.556 64.1302 53.284C64.1302 53.092 64.0702 52.944 63.9502 52.84C63.8302 52.736 63.6542 52.652 63.4222 52.588C63.1982 52.524 62.9262 52.444 62.6062 52.348C62.1582 52.212 61.7822 52.072 61.4782 51.928C61.1742 51.776 60.9462 51.592 60.7942 51.376C60.6422 51.16 60.5662 50.884 60.5662 50.548C60.5662 49.94 60.7942 49.468 61.2502 49.132C61.7062 48.788 62.3022 48.616 63.0382 48.616C63.4942 48.616 63.9142 48.688 64.2982 48.832C64.6902 48.968 65.0342 49.184 65.3302 49.48L64.7662 50.356C64.2222 49.86 63.6222 49.612 62.9662 49.612C62.6782 49.612 62.4102 49.668 62.1622 49.78C61.9222 49.892 61.8022 50.1 61.8022 50.404C61.8022 50.66 61.9102 50.844 62.1262 50.956C62.3422 51.068 62.6622 51.188 63.0862 51.316C63.5662 51.46 63.9782 51.604 64.3222 51.748C64.6742 51.884 64.9422 52.064 65.1262 52.288C65.3182 52.504 65.4142 52.804 65.4142 53.188C65.4142 53.796 65.1862 54.272 64.7302 54.616C64.2742 54.952 63.6742 55.12 62.9302 55.12Z'
          fill='#FED683'
        />
        <path d='M66.5928 47.692V46.24H67.9128V47.692H66.5928ZM66.5928 55V48.724H67.9128V55H66.5928Z' fill='#FED683' />
        <path
          d='M72.343 55.12C71.695 55.12 71.127 54.972 70.639 54.676C70.151 54.372 69.771 53.972 69.499 53.476C69.227 52.98 69.091 52.44 69.091 51.856C69.091 51.272 69.223 50.736 69.487 50.248C69.759 49.752 70.135 49.356 70.615 49.06C71.103 48.764 71.679 48.616 72.343 48.616C72.991 48.616 73.547 48.76 74.011 49.048C74.483 49.328 74.835 49.704 75.067 50.176L73.783 50.584C73.639 50.32 73.435 50.116 73.171 49.972C72.915 49.82 72.631 49.744 72.319 49.744C71.967 49.744 71.647 49.836 71.359 50.02C71.079 50.196 70.855 50.444 70.687 50.764C70.527 51.084 70.447 51.448 70.447 51.856C70.447 52.264 70.531 52.628 70.699 52.948C70.875 53.268 71.103 53.524 71.383 53.716C71.671 53.9 71.987 53.992 72.331 53.992C72.651 53.992 72.955 53.908 73.243 53.74C73.531 53.572 73.723 53.368 73.819 53.128L75.115 53.512C74.907 53.984 74.559 54.372 74.071 54.676C73.583 54.972 73.007 55.12 72.343 55.12Z'
          fill='#FED683'
        />
        <path
          d='M75.7461 53.164C75.7461 52.764 75.8581 52.416 76.0821 52.12C76.3061 51.816 76.6181 51.584 77.0181 51.424C77.4261 51.256 77.8941 51.172 78.4221 51.172C78.7021 51.172 78.9861 51.196 79.2741 51.244C79.5701 51.284 79.8301 51.344 80.0541 51.424V51.028C80.0541 50.588 79.9221 50.244 79.6581 49.996C79.3941 49.748 79.0141 49.624 78.5181 49.624C78.1741 49.624 77.8421 49.684 77.5221 49.804C77.2021 49.924 76.8701 50.1 76.5261 50.332L76.0821 49.444C76.4981 49.172 76.9141 48.968 77.3301 48.832C77.7541 48.688 78.1981 48.616 78.6621 48.616C79.5021 48.616 80.1621 48.84 80.6421 49.288C81.1301 49.728 81.3741 50.356 81.3741 51.172V53.512C81.3741 53.664 81.3981 53.772 81.4461 53.836C81.5021 53.9 81.5941 53.936 81.7221 53.944V55C81.6021 55.024 81.4941 55.04 81.3981 55.048C81.3021 55.056 81.2221 55.06 81.1581 55.06C80.8701 55.06 80.6541 54.992 80.5101 54.856C80.3741 54.712 80.2941 54.552 80.2701 54.376L80.2341 54.016C79.9621 54.368 79.6141 54.64 79.1901 54.832C78.7661 55.024 78.3381 55.12 77.9061 55.12C77.4901 55.12 77.1181 55.036 76.7901 54.868C76.4621 54.692 76.2061 54.456 76.0221 54.16C75.8381 53.864 75.7461 53.532 75.7461 53.164ZM79.7421 53.548C79.9501 53.34 80.0541 53.144 80.0541 52.96V52.24C79.5821 52.064 79.1021 51.976 78.6141 51.976C78.1341 51.976 77.7421 52.076 77.4381 52.276C77.1421 52.468 76.9941 52.728 76.9941 53.056C76.9941 53.328 77.1021 53.58 77.3181 53.812C77.5421 54.036 77.8621 54.148 78.2781 54.148C78.5581 54.148 78.8301 54.092 79.0941 53.98C79.3581 53.868 79.5741 53.724 79.7421 53.548Z'
          fill='#FED683'
        />
        <path
          d='M82.9876 46.24H84.3076V53.188C84.3076 53.508 84.3556 53.712 84.4516 53.8C84.5476 53.888 84.6676 53.932 84.8116 53.932C84.9716 53.932 85.1236 53.916 85.2676 53.884C85.4116 53.852 85.5316 53.812 85.6276 53.764L85.8196 54.808C85.6276 54.888 85.3996 54.956 85.1356 55.012C84.8716 55.068 84.6356 55.096 84.4276 55.096C83.9796 55.096 83.6276 54.972 83.3716 54.724C83.1156 54.468 82.9876 54.116 82.9876 53.668V46.24Z'
          fill='#FED683'
        />
        <path
          d='M88.838 50.68C88.838 50.16 88.93 49.648 89.114 49.144C89.298 48.64 89.566 48.184 89.918 47.776C90.278 47.368 90.71 47.044 91.214 46.804C91.726 46.556 92.31 46.432 92.966 46.432C93.734 46.432 94.398 46.604 94.958 46.948C95.518 47.292 95.934 47.74 96.206 48.292L95.15 49C94.99 48.656 94.786 48.388 94.538 48.196C94.298 47.996 94.034 47.856 93.746 47.776C93.466 47.688 93.19 47.644 92.918 47.644C92.47 47.644 92.078 47.736 91.742 47.92C91.406 48.096 91.122 48.332 90.89 48.628C90.666 48.924 90.494 49.256 90.374 49.624C90.262 49.992 90.206 50.36 90.206 50.728C90.206 51.28 90.322 51.796 90.554 52.276C90.794 52.748 91.118 53.132 91.526 53.428C91.942 53.716 92.418 53.86 92.954 53.86C93.226 53.86 93.51 53.812 93.806 53.716C94.102 53.62 94.374 53.472 94.622 53.272C94.87 53.064 95.07 52.792 95.222 52.456L96.338 53.092C96.162 53.516 95.886 53.876 95.51 54.172C95.142 54.468 94.726 54.692 94.262 54.844C93.806 54.996 93.354 55.072 92.906 55.072C92.306 55.072 91.758 54.948 91.262 54.7C90.766 54.444 90.338 54.108 89.978 53.692C89.618 53.268 89.338 52.796 89.138 52.276C88.938 51.748 88.838 51.216 88.838 50.68Z'
          fill='#FED683'
        />
        <path
          d='M96.9571 53.164C96.9571 52.764 97.0691 52.416 97.2931 52.12C97.5171 51.816 97.8291 51.584 98.2291 51.424C98.6371 51.256 99.1051 51.172 99.6331 51.172C99.9131 51.172 100.197 51.196 100.485 51.244C100.781 51.284 101.041 51.344 101.265 51.424V51.028C101.265 50.588 101.133 50.244 100.869 49.996C100.605 49.748 100.225 49.624 99.7291 49.624C99.3851 49.624 99.0531 49.684 98.7331 49.804C98.4131 49.924 98.0811 50.1 97.7371 50.332L97.2931 49.444C97.7091 49.172 98.1251 48.968 98.5411 48.832C98.9651 48.688 99.4091 48.616 99.8731 48.616C100.713 48.616 101.373 48.84 101.853 49.288C102.341 49.728 102.585 50.356 102.585 51.172V53.512C102.585 53.664 102.609 53.772 102.657 53.836C102.713 53.9 102.805 53.936 102.933 53.944V55C102.813 55.024 102.705 55.04 102.609 55.048C102.513 55.056 102.433 55.06 102.369 55.06C102.081 55.06 101.865 54.992 101.721 54.856C101.585 54.712 101.505 54.552 101.481 54.376L101.445 54.016C101.173 54.368 100.825 54.64 100.401 54.832C99.9771 55.024 99.5491 55.12 99.1171 55.12C98.7011 55.12 98.3291 55.036 98.0011 54.868C97.6731 54.692 97.4171 54.456 97.2331 54.16C97.0491 53.864 96.9571 53.532 96.9571 53.164ZM100.953 53.548C101.161 53.34 101.265 53.144 101.265 52.96V52.24C100.793 52.064 100.313 51.976 99.8251 51.976C99.3451 51.976 98.9531 52.076 98.6491 52.276C98.3531 52.468 98.2051 52.728 98.2051 53.056C98.2051 53.328 98.3131 53.58 98.5291 53.812C98.7531 54.036 99.0731 54.148 99.4891 54.148C99.7691 54.148 100.041 54.092 100.305 53.98C100.569 53.868 100.785 53.724 100.953 53.548Z'
          fill='#FED683'
        />
        <path
          d='M107.715 49.864C107.203 49.88 106.747 50 106.347 50.224C105.955 50.44 105.675 50.752 105.507 51.16V55H104.187V48.724H105.411V50.128C105.627 49.696 105.911 49.352 106.263 49.096C106.615 48.832 106.987 48.688 107.379 48.664C107.451 48.664 107.515 48.664 107.571 48.664C107.627 48.664 107.675 48.668 107.715 48.676V49.864Z'
          fill='#FED683'
        />
        <path
          d='M108.197 51.868C108.197 51.268 108.317 50.724 108.557 50.236C108.805 49.74 109.141 49.348 109.565 49.06C109.989 48.764 110.477 48.616 111.029 48.616C111.509 48.616 111.941 48.74 112.325 48.988C112.717 49.236 113.021 49.544 113.237 49.912V46.24H114.557V53.512C114.557 53.664 114.585 53.772 114.641 53.836C114.697 53.9 114.789 53.936 114.917 53.944V55C114.669 55.04 114.477 55.06 114.341 55.06C114.093 55.06 113.881 54.992 113.705 54.856C113.537 54.712 113.449 54.536 113.441 54.328L113.429 53.86C113.189 54.26 112.865 54.572 112.457 54.796C112.049 55.012 111.625 55.12 111.185 55.12C110.609 55.12 110.093 54.972 109.637 54.676C109.189 54.38 108.837 53.988 108.581 53.5C108.325 53.004 108.197 52.46 108.197 51.868ZM113.237 52.744V51.1C113.141 50.844 112.997 50.616 112.805 50.416C112.613 50.208 112.393 50.044 112.145 49.924C111.905 49.804 111.665 49.744 111.425 49.744C111.049 49.744 110.721 49.848 110.441 50.056C110.169 50.256 109.953 50.52 109.793 50.848C109.641 51.176 109.565 51.524 109.565 51.892C109.565 52.276 109.649 52.628 109.817 52.948C109.993 53.268 110.229 53.524 110.525 53.716C110.821 53.9 111.153 53.992 111.521 53.992C111.745 53.992 111.977 53.94 112.217 53.836C112.465 53.724 112.681 53.576 112.865 53.392C113.049 53.2 113.173 52.984 113.237 52.744Z'
          fill='#FED683'
        />
      </g>
      <text
        id='##cardholderName##'
        fill='white'
        xmlSpace='preserve'
        css='white-space: pre'
        fontFamily='Raleway-Medium'
        fontSize='14'
        letterSpacing='0em'
      >
        <tspan x='22' y='174.942'>
          {cardholderName.toUpperCase()}
        </tspan>
      </text>
      <text
        id='##expiryDate##'
        fill='#C0C0C0'
        xmlSpace='preserve'
        css='white-space: pre'
        fontFamily='Raleway-Regular'
        fontSize='12'
        letterSpacing='0em'
      >
        <tspan x='48' y='144.236'>
          {expiryDate}
        </tspan>
      </text>
      <g id='Vector_2'>
        <path
          d='M25.6 144.12C25 144.12 24.464 143.972 23.992 143.676C23.52 143.38 23.148 142.984 22.876 142.488C22.604 141.992 22.468 141.448 22.468 140.856C22.468 140.272 22.6 139.736 22.864 139.248C23.136 138.76 23.508 138.372 23.98 138.084C24.452 137.788 24.988 137.64 25.588 137.64C26.204 137.64 26.744 137.788 27.208 138.084C27.672 138.38 28.032 138.772 28.288 139.26C28.552 139.74 28.684 140.268 28.684 140.844C28.684 140.972 28.68 141.08 28.672 141.168H23.32C23.36 141.616 23.488 142.016 23.704 142.368C23.92 142.712 24.196 142.988 24.532 143.196C24.868 143.396 25.236 143.496 25.636 143.496C26.044 143.496 26.428 143.392 26.788 143.184C27.148 142.976 27.404 142.704 27.556 142.368L28.264 142.56C28.136 142.864 27.936 143.136 27.664 143.376C27.4 143.608 27.092 143.792 26.74 143.928C26.388 144.056 26.008 144.12 25.6 144.12ZM23.296 140.58H27.916C27.884 140.132 27.76 139.736 27.544 139.392C27.328 139.04 27.052 138.768 26.716 138.576C26.38 138.376 26.008 138.276 25.6 138.276C25.192 138.276 24.82 138.376 24.484 138.576C24.148 138.768 23.872 139.04 23.656 139.392C23.448 139.736 23.328 140.132 23.296 140.58Z'
          fill='#C0C0C0'
        />
        <path
          d='M29.9006 137.748L31.7366 140.196L31.8326 140.376L31.9286 140.196L33.7526 137.748H34.6766L32.3006 140.892L34.6766 144H33.7526L31.9286 141.564L31.8326 141.396L31.7366 141.564L29.9126 144H28.9886L31.3526 140.892L28.9886 137.748H29.9006Z'
          fill='#C0C0C0'
        />
        <path
          d='M38.9475 144.12C38.4275 144.12 37.9595 143.984 37.5435 143.712C37.1355 143.44 36.8115 143.104 36.5715 142.704V146.556H35.7555V137.748H36.4875V138.96C36.7355 138.568 37.0635 138.252 37.4715 138.012C37.8875 137.764 38.3355 137.64 38.8155 137.64C39.2475 137.64 39.6435 137.732 40.0035 137.916C40.3635 138.092 40.6755 138.332 40.9395 138.636C41.2115 138.94 41.4195 139.288 41.5635 139.68C41.7075 140.064 41.7795 140.464 41.7795 140.88C41.7795 141.464 41.6595 142.004 41.4195 142.5C41.1875 142.988 40.8555 143.38 40.4235 143.676C39.9995 143.972 39.5075 144.12 38.9475 144.12ZM38.7195 143.4C39.1755 143.4 39.5675 143.28 39.8955 143.04C40.2315 142.792 40.4915 142.476 40.6755 142.092C40.8595 141.708 40.9515 141.304 40.9515 140.88C40.9515 140.432 40.8475 140.016 40.6395 139.632C40.4315 139.248 40.1515 138.94 39.7995 138.708C39.4555 138.476 39.0635 138.36 38.6235 138.36C38.3515 138.36 38.0635 138.432 37.7595 138.576C37.4635 138.712 37.1995 138.9 36.9675 139.14C36.7435 139.372 36.6115 139.628 36.5715 139.908V141.828C36.7555 142.268 37.0435 142.64 37.4355 142.944C37.8275 143.248 38.2555 143.4 38.7195 143.4Z'
          fill='#C0C0C0'
        />
        <path
          d='M102.612 144.12C102.004 144.12 101.464 143.972 100.992 143.676C100.52 143.372 100.148 142.976 99.876 142.488C99.604 141.992 99.468 141.448 99.468 140.856C99.468 140.264 99.6 139.728 99.864 139.248C100.128 138.76 100.496 138.372 100.968 138.084C101.44 137.788 101.984 137.64 102.6 137.64C103.184 137.64 103.696 137.776 104.136 138.048C104.584 138.312 104.92 138.672 105.144 139.128L104.352 139.38C104.176 139.06 103.928 138.812 103.608 138.636C103.296 138.452 102.948 138.36 102.564 138.36C102.14 138.36 101.756 138.468 101.412 138.684C101.076 138.9 100.804 139.2 100.596 139.584C100.396 139.96 100.296 140.384 100.296 140.856C100.296 141.328 100.4 141.756 100.608 142.14C100.816 142.524 101.092 142.832 101.436 143.064C101.78 143.288 102.16 143.4 102.576 143.4C102.848 143.4 103.112 143.352 103.368 143.256C103.624 143.16 103.844 143.032 104.028 142.872C104.212 142.704 104.336 142.524 104.4 142.332L105.204 142.572C105.092 142.868 104.908 143.136 104.652 143.376C104.396 143.608 104.092 143.792 103.74 143.928C103.388 144.056 103.012 144.12 102.612 144.12Z'
          fill='#C0C0C0'
        />
        <path
          d='M109.139 144.12C108.531 144.12 107.991 143.972 107.519 143.676C107.047 143.372 106.675 142.976 106.403 142.488C106.131 141.992 105.995 141.448 105.995 140.856C105.995 140.264 106.127 139.728 106.391 139.248C106.655 138.76 107.023 138.372 107.495 138.084C107.967 137.788 108.511 137.64 109.127 137.64C109.711 137.64 110.223 137.776 110.663 138.048C111.111 138.312 111.447 138.672 111.671 139.128L110.879 139.38C110.703 139.06 110.455 138.812 110.135 138.636C109.823 138.452 109.475 138.36 109.091 138.36C108.667 138.36 108.283 138.468 107.939 138.684C107.603 138.9 107.331 139.2 107.123 139.584C106.923 139.96 106.823 140.384 106.823 140.856C106.823 141.328 106.927 141.756 107.135 142.14C107.343 142.524 107.619 142.832 107.963 143.064C108.307 143.288 108.687 143.4 109.103 143.4C109.375 143.4 109.639 143.352 109.895 143.256C110.151 143.16 110.371 143.032 110.555 142.872C110.739 142.704 110.863 142.524 110.927 142.332L111.731 142.572C111.619 142.868 111.435 143.136 111.179 143.376C110.923 143.608 110.619 143.792 110.267 143.928C109.915 144.056 109.539 144.12 109.139 144.12Z'
          fill='#C0C0C0'
        />
        <path
          d='M114.78 144L112.248 137.748H113.1L115.236 143.184L117.384 137.748H118.188L115.656 144H114.78Z'
          fill='#C0C0C0'
        />
        <path
          d='M125.251 137.448L125.767 136.704L124.951 136.44L125.083 136.008L125.911 136.32L125.887 135.444H126.379L126.355 136.32L127.183 136.008L127.315 136.44L126.499 136.704L127.027 137.448L126.643 137.712L126.139 136.932L125.623 137.712L125.251 137.448Z'
          fill='#C0C0C0'
        />
        <path
          d='M132.165 137.448L132.681 136.704L131.865 136.44L131.997 136.008L132.825 136.32L132.801 135.444H133.293L133.269 136.32L134.097 136.008L134.229 136.44L133.413 136.704L133.941 137.448L133.557 137.712L133.053 136.932L132.537 137.712L132.165 137.448Z'
          fill='#C0C0C0'
        />
        <path
          d='M139.079 137.448L139.595 136.704L138.779 136.44L138.911 136.008L139.739 136.32L139.715 135.444H140.207L140.183 136.32L141.011 136.008L141.143 136.44L140.327 136.704L140.855 137.448L140.471 137.712L139.967 136.932L139.451 137.712L139.079 137.448Z'
          fill='#C0C0C0'
        />
      </g>
      <text
        id='##cardNumber##'
        fill='white'
        xmlSpace='preserve'
        css='white-space: pre'
        fontFamily='Raleway-Regular'
        fontSize='26'
        letterSpacing='0em'
      >
        <tspan x='22' y='114.678'>
          {cardNumber}
        </tspan>
      </text>
    </g>
  </svg>
)

const VirtualCardDetails = ({ cardholderName = '', expiryDate, cardNumber, ...props }) => (
  <svg width='300' height='190' viewBox='0 0 300 190' fill='none' {...props}>
    <g id='virtual_card_details'>
      <path
        id='Vector'
        d='M36.6042 49.132C36.1321 46.7431 34.0278 44.9443 31.5 44.9443C29.4932 44.9443 27.7498 46.0832 26.8819 47.743C24.7915 47.9722 23.1666 49.7362 23.1666 51.889C23.1669 54.1895 25.0328 56.0554 27.3336 56.0554L36.3612 56.0557C38.2785 56.0554 39.8334 54.5006 39.8334 52.5832C39.8334 50.7501 38.4098 49.2638 36.6042 49.132Z'
        fill='#90E4FC'
      />
      <g id='Virtual Card'>
        <path d='M46.5 47.48L49.068 54.356L51.624 47.48H53.052L49.704 56H48.432L45.072 47.48H46.5Z' fill='#90E4FC' />
        <path d='M53.9248 48.692V47.24H55.2448V48.692H53.9248ZM53.9248 56V49.724H55.2448V56H53.9248Z' fill='#90E4FC' />
        <path
          d='M60.3591 50.864C59.8471 50.88 59.3911 51 58.9911 51.224C58.5991 51.44 58.3191 51.752 58.1511 52.16V56H56.8311V49.724H58.0551V51.128C58.2711 50.696 58.5551 50.352 58.9071 50.096C59.2591 49.832 59.6311 49.688 60.0231 49.664C60.0951 49.664 60.1591 49.664 60.2151 49.664C60.2711 49.664 60.3191 49.668 60.3591 49.676V50.864Z'
          fill='#90E4FC'
        />
        <path
          d='M64.8113 55.676C64.6433 55.756 64.4113 55.848 64.1153 55.952C63.8193 56.048 63.5033 56.096 63.1673 56.096C62.7513 56.096 62.3913 55.984 62.0873 55.76C61.7913 55.536 61.6433 55.172 61.6433 54.668V50.744H60.8153V49.724H61.6433V47.66H62.9633V49.724H64.3313V50.744H62.9633V54.248C62.9793 54.472 63.0473 54.636 63.1673 54.74C63.2953 54.836 63.4513 54.884 63.6353 54.884C63.8193 54.884 63.9953 54.852 64.1633 54.788C64.3313 54.724 64.4553 54.672 64.5353 54.632L64.8113 55.676Z'
          fill='#90E4FC'
        />
        <path
          d='M65.6656 53.564V49.724H66.9856V53.3C66.9856 54.428 67.3856 54.992 68.1856 54.992C68.5616 54.992 68.9176 54.876 69.2536 54.644C69.5896 54.404 69.8496 54.068 70.0336 53.636V49.724H71.3536V54.512C71.3536 54.664 71.3816 54.772 71.4376 54.836C71.4936 54.9 71.5856 54.936 71.7136 54.944V56C71.5776 56.024 71.4656 56.04 71.3776 56.048C71.2896 56.056 71.2056 56.06 71.1256 56.06C70.8856 56.06 70.6856 55.996 70.5256 55.868C70.3656 55.74 70.2736 55.568 70.2496 55.352L70.2256 54.728C69.9456 55.184 69.5816 55.532 69.1336 55.772C68.6856 56.004 68.1936 56.12 67.6576 56.12C67.0016 56.12 66.5056 55.904 66.1696 55.472C65.8336 55.04 65.6656 54.404 65.6656 53.564Z'
          fill='#90E4FC'
        />
        <path
          d='M72.5821 54.164C72.5821 53.764 72.6941 53.416 72.9181 53.12C73.1421 52.816 73.4541 52.584 73.8541 52.424C74.2621 52.256 74.7301 52.172 75.2581 52.172C75.5381 52.172 75.8221 52.196 76.1101 52.244C76.4061 52.284 76.6661 52.344 76.8901 52.424V52.028C76.8901 51.588 76.7581 51.244 76.4941 50.996C76.2301 50.748 75.8501 50.624 75.3541 50.624C75.0101 50.624 74.6781 50.684 74.3581 50.804C74.0381 50.924 73.7061 51.1 73.3621 51.332L72.9181 50.444C73.3341 50.172 73.7501 49.968 74.1661 49.832C74.5901 49.688 75.0341 49.616 75.4981 49.616C76.3381 49.616 76.9981 49.84 77.4781 50.288C77.9661 50.728 78.2101 51.356 78.2101 52.172V54.512C78.2101 54.664 78.2341 54.772 78.2821 54.836C78.3381 54.9 78.4301 54.936 78.5581 54.944V56C78.4381 56.024 78.3301 56.04 78.2341 56.048C78.1381 56.056 78.0581 56.06 77.9941 56.06C77.7061 56.06 77.4901 55.992 77.3461 55.856C77.2101 55.712 77.1301 55.552 77.1061 55.376L77.0701 55.016C76.7981 55.368 76.4501 55.64 76.0261 55.832C75.6021 56.024 75.1741 56.12 74.7421 56.12C74.3261 56.12 73.9541 56.036 73.6261 55.868C73.2981 55.692 73.0421 55.456 72.8581 55.16C72.6741 54.864 72.5821 54.532 72.5821 54.164ZM76.5781 54.548C76.7861 54.34 76.8901 54.144 76.8901 53.96V53.24C76.4181 53.064 75.9381 52.976 75.4501 52.976C74.9701 52.976 74.5781 53.076 74.2741 53.276C73.9781 53.468 73.8301 53.728 73.8301 54.056C73.8301 54.328 73.9381 54.58 74.1541 54.812C74.3781 55.036 74.6981 55.148 75.1141 55.148C75.3941 55.148 75.6661 55.092 75.9301 54.98C76.1941 54.868 76.4101 54.724 76.5781 54.548Z'
          fill='#90E4FC'
        />
        <path
          d='M79.8235 47.24H81.1435V54.188C81.1435 54.508 81.1915 54.712 81.2875 54.8C81.3835 54.888 81.5035 54.932 81.6475 54.932C81.8075 54.932 81.9595 54.916 82.1035 54.884C82.2475 54.852 82.3675 54.812 82.4635 54.764L82.6555 55.808C82.4635 55.888 82.2355 55.956 81.9715 56.012C81.7075 56.068 81.4715 56.096 81.2635 56.096C80.8155 56.096 80.4635 55.972 80.2075 55.724C79.9515 55.468 79.8235 55.116 79.8235 54.668V47.24Z'
          fill='#90E4FC'
        />
        <path
          d='M85.6739 51.68C85.6739 51.16 85.7659 50.648 85.9499 50.144C86.1339 49.64 86.4019 49.184 86.7539 48.776C87.1139 48.368 87.5459 48.044 88.0499 47.804C88.5619 47.556 89.1459 47.432 89.8019 47.432C90.5699 47.432 91.2339 47.604 91.7939 47.948C92.3539 48.292 92.7699 48.74 93.0419 49.292L91.9859 50C91.8259 49.656 91.6219 49.388 91.3739 49.196C91.1339 48.996 90.8699 48.856 90.5819 48.776C90.3019 48.688 90.0259 48.644 89.7539 48.644C89.3059 48.644 88.9139 48.736 88.5779 48.92C88.2419 49.096 87.9579 49.332 87.7259 49.628C87.5019 49.924 87.3299 50.256 87.2099 50.624C87.0979 50.992 87.0419 51.36 87.0419 51.728C87.0419 52.28 87.1579 52.796 87.3899 53.276C87.6299 53.748 87.9539 54.132 88.3619 54.428C88.7779 54.716 89.2539 54.86 89.7899 54.86C90.0619 54.86 90.3459 54.812 90.6419 54.716C90.9379 54.62 91.2099 54.472 91.4579 54.272C91.7059 54.064 91.9059 53.792 92.0579 53.456L93.1739 54.092C92.9979 54.516 92.7219 54.876 92.3459 55.172C91.9779 55.468 91.5619 55.692 91.0979 55.844C90.6419 55.996 90.1899 56.072 89.7419 56.072C89.1419 56.072 88.5939 55.948 88.0979 55.7C87.6019 55.444 87.1739 55.108 86.8139 54.692C86.4539 54.268 86.1739 53.796 85.9739 53.276C85.7739 52.748 85.6739 52.216 85.6739 51.68Z'
          fill='#90E4FC'
        />
        <path
          d='M93.793 54.164C93.793 53.764 93.905 53.416 94.129 53.12C94.353 52.816 94.665 52.584 95.065 52.424C95.473 52.256 95.941 52.172 96.469 52.172C96.749 52.172 97.033 52.196 97.321 52.244C97.617 52.284 97.877 52.344 98.101 52.424V52.028C98.101 51.588 97.969 51.244 97.705 50.996C97.441 50.748 97.061 50.624 96.565 50.624C96.221 50.624 95.889 50.684 95.569 50.804C95.249 50.924 94.917 51.1 94.573 51.332L94.129 50.444C94.545 50.172 94.961 49.968 95.377 49.832C95.801 49.688 96.245 49.616 96.709 49.616C97.549 49.616 98.209 49.84 98.689 50.288C99.177 50.728 99.421 51.356 99.421 52.172V54.512C99.421 54.664 99.445 54.772 99.493 54.836C99.549 54.9 99.641 54.936 99.769 54.944V56C99.649 56.024 99.541 56.04 99.445 56.048C99.349 56.056 99.269 56.06 99.205 56.06C98.917 56.06 98.701 55.992 98.557 55.856C98.421 55.712 98.341 55.552 98.317 55.376L98.281 55.016C98.009 55.368 97.661 55.64 97.237 55.832C96.813 56.024 96.385 56.12 95.953 56.12C95.537 56.12 95.165 56.036 94.837 55.868C94.509 55.692 94.253 55.456 94.069 55.16C93.885 54.864 93.793 54.532 93.793 54.164ZM97.789 54.548C97.997 54.34 98.101 54.144 98.101 53.96V53.24C97.629 53.064 97.149 52.976 96.661 52.976C96.181 52.976 95.789 53.076 95.485 53.276C95.189 53.468 95.041 53.728 95.041 54.056C95.041 54.328 95.149 54.58 95.365 54.812C95.589 55.036 95.909 55.148 96.325 55.148C96.605 55.148 96.877 55.092 97.141 54.98C97.405 54.868 97.621 54.724 97.789 54.548Z'
          fill='#90E4FC'
        />
        <path
          d='M104.55 50.864C104.038 50.88 103.582 51 103.182 51.224C102.79 51.44 102.51 51.752 102.342 52.16V56H101.022V49.724H102.246V51.128C102.462 50.696 102.746 50.352 103.098 50.096C103.45 49.832 103.822 49.688 104.214 49.664C104.286 49.664 104.35 49.664 104.406 49.664C104.462 49.664 104.51 49.668 104.55 49.676V50.864Z'
          fill='#90E4FC'
        />
        <path
          d='M105.033 52.868C105.033 52.268 105.153 51.724 105.393 51.236C105.641 50.74 105.977 50.348 106.401 50.06C106.825 49.764 107.313 49.616 107.865 49.616C108.345 49.616 108.777 49.74 109.161 49.988C109.553 50.236 109.857 50.544 110.073 50.912V47.24H111.393V54.512C111.393 54.664 111.421 54.772 111.477 54.836C111.533 54.9 111.625 54.936 111.753 54.944V56C111.505 56.04 111.313 56.06 111.177 56.06C110.929 56.06 110.717 55.992 110.541 55.856C110.373 55.712 110.285 55.536 110.277 55.328L110.265 54.86C110.025 55.26 109.701 55.572 109.293 55.796C108.885 56.012 108.461 56.12 108.021 56.12C107.445 56.12 106.929 55.972 106.473 55.676C106.025 55.38 105.673 54.988 105.417 54.5C105.161 54.004 105.033 53.46 105.033 52.868ZM110.073 53.744V52.1C109.977 51.844 109.833 51.616 109.641 51.416C109.449 51.208 109.229 51.044 108.981 50.924C108.741 50.804 108.501 50.744 108.261 50.744C107.885 50.744 107.557 50.848 107.277 51.056C107.005 51.256 106.789 51.52 106.629 51.848C106.477 52.176 106.401 52.524 106.401 52.892C106.401 53.276 106.485 53.628 106.653 53.948C106.829 54.268 107.065 54.524 107.361 54.716C107.657 54.9 107.989 54.992 108.357 54.992C108.581 54.992 108.813 54.94 109.053 54.836C109.301 54.724 109.517 54.576 109.701 54.392C109.885 54.2 110.009 53.984 110.073 53.744Z'
          fill='#90E4FC'
        />
      </g>
      <text
        id='##cardholderName##'
        fill='white'
        xmlSpace='preserve'
        css='white-space: pre'
        fontFamily='Raleway-Medium'
        fontSize='14'
        letterSpacing='0em'
      >
        <tspan x='22' y='174.942'>
          {cardholderName.toUpperCase()}
        </tspan>
      </text>
      <text
        id='##expiryDate##'
        fill='#C0C0C0'
        xmlSpace='preserve'
        css='white-space: pre'
        fontFamily='Raleway-Regular'
        fontSize='12'
        letterSpacing='0em'
      >
        <tspan x='48' y='144.236'>
          {expiryDate}
        </tspan>
      </text>
      <g id='Vector_2'>
        <path
          d='M26.6 143.12C26 143.12 25.464 142.972 24.992 142.676C24.52 142.38 24.148 141.984 23.876 141.488C23.604 140.992 23.468 140.448 23.468 139.856C23.468 139.272 23.6 138.736 23.864 138.248C24.136 137.76 24.508 137.372 24.98 137.084C25.452 136.788 25.988 136.64 26.588 136.64C27.204 136.64 27.744 136.788 28.208 137.084C28.672 137.38 29.032 137.772 29.288 138.26C29.552 138.74 29.684 139.268 29.684 139.844C29.684 139.972 29.68 140.08 29.672 140.168H24.32C24.36 140.616 24.488 141.016 24.704 141.368C24.92 141.712 25.196 141.988 25.532 142.196C25.868 142.396 26.236 142.496 26.636 142.496C27.044 142.496 27.428 142.392 27.788 142.184C28.148 141.976 28.404 141.704 28.556 141.368L29.264 141.56C29.136 141.864 28.936 142.136 28.664 142.376C28.4 142.608 28.092 142.792 27.74 142.928C27.388 143.056 27.008 143.12 26.6 143.12ZM24.296 139.58H28.916C28.884 139.132 28.76 138.736 28.544 138.392C28.328 138.04 28.052 137.768 27.716 137.576C27.38 137.376 27.008 137.276 26.6 137.276C26.192 137.276 25.82 137.376 25.484 137.576C25.148 137.768 24.872 138.04 24.656 138.392C24.448 138.736 24.328 139.132 24.296 139.58Z'
          fill='#C0C0C0'
        />
        <path
          d='M30.9006 136.748L32.7366 139.196L32.8326 139.376L32.9286 139.196L34.7526 136.748H35.6766L33.3006 139.892L35.6766 143H34.7526L32.9286 140.564L32.8326 140.396L32.7366 140.564L30.9126 143H29.9886L32.3526 139.892L29.9886 136.748H30.9006Z'
          fill='#C0C0C0'
        />
        <path
          d='M39.9475 143.12C39.4275 143.12 38.9595 142.984 38.5435 142.712C38.1355 142.44 37.8115 142.104 37.5715 141.704V145.556H36.7555V136.748H37.4875V137.96C37.7355 137.568 38.0635 137.252 38.4715 137.012C38.8875 136.764 39.3355 136.64 39.8155 136.64C40.2475 136.64 40.6435 136.732 41.0035 136.916C41.3635 137.092 41.6755 137.332 41.9395 137.636C42.2115 137.94 42.4195 138.288 42.5635 138.68C42.7075 139.064 42.7795 139.464 42.7795 139.88C42.7795 140.464 42.6595 141.004 42.4195 141.5C42.1875 141.988 41.8555 142.38 41.4235 142.676C40.9995 142.972 40.5075 143.12 39.9475 143.12ZM39.7195 142.4C40.1755 142.4 40.5675 142.28 40.8955 142.04C41.2315 141.792 41.4915 141.476 41.6755 141.092C41.8595 140.708 41.9515 140.304 41.9515 139.88C41.9515 139.432 41.8475 139.016 41.6395 138.632C41.4315 138.248 41.1515 137.94 40.7995 137.708C40.4555 137.476 40.0635 137.36 39.6235 137.36C39.3515 137.36 39.0635 137.432 38.7595 137.576C38.4635 137.712 38.1995 137.9 37.9675 138.14C37.7435 138.372 37.6115 138.628 37.5715 138.908V140.828C37.7555 141.268 38.0435 141.64 38.4355 141.944C38.8275 142.248 39.2555 142.4 39.7195 142.4Z'
          fill='#C0C0C0'
        />
        <path
          d='M103.612 143.12C103.004 143.12 102.464 142.972 101.992 142.676C101.52 142.372 101.148 141.976 100.876 141.488C100.604 140.992 100.468 140.448 100.468 139.856C100.468 139.264 100.6 138.728 100.864 138.248C101.128 137.76 101.496 137.372 101.968 137.084C102.44 136.788 102.984 136.64 103.6 136.64C104.184 136.64 104.696 136.776 105.136 137.048C105.584 137.312 105.92 137.672 106.144 138.128L105.352 138.38C105.176 138.06 104.928 137.812 104.608 137.636C104.296 137.452 103.948 137.36 103.564 137.36C103.14 137.36 102.756 137.468 102.412 137.684C102.076 137.9 101.804 138.2 101.596 138.584C101.396 138.96 101.296 139.384 101.296 139.856C101.296 140.328 101.4 140.756 101.608 141.14C101.816 141.524 102.092 141.832 102.436 142.064C102.78 142.288 103.16 142.4 103.576 142.4C103.848 142.4 104.112 142.352 104.368 142.256C104.624 142.16 104.844 142.032 105.028 141.872C105.212 141.704 105.336 141.524 105.4 141.332L106.204 141.572C106.092 141.868 105.908 142.136 105.652 142.376C105.396 142.608 105.092 142.792 104.74 142.928C104.388 143.056 104.012 143.12 103.612 143.12Z'
          fill='#C0C0C0'
        />
        <path
          d='M110.139 143.12C109.531 143.12 108.991 142.972 108.519 142.676C108.047 142.372 107.675 141.976 107.403 141.488C107.131 140.992 106.995 140.448 106.995 139.856C106.995 139.264 107.127 138.728 107.391 138.248C107.655 137.76 108.023 137.372 108.495 137.084C108.967 136.788 109.511 136.64 110.127 136.64C110.711 136.64 111.223 136.776 111.663 137.048C112.111 137.312 112.447 137.672 112.671 138.128L111.879 138.38C111.703 138.06 111.455 137.812 111.135 137.636C110.823 137.452 110.475 137.36 110.091 137.36C109.667 137.36 109.283 137.468 108.939 137.684C108.603 137.9 108.331 138.2 108.123 138.584C107.923 138.96 107.823 139.384 107.823 139.856C107.823 140.328 107.927 140.756 108.135 141.14C108.343 141.524 108.619 141.832 108.963 142.064C109.307 142.288 109.687 142.4 110.103 142.4C110.375 142.4 110.639 142.352 110.895 142.256C111.151 142.16 111.371 142.032 111.555 141.872C111.739 141.704 111.863 141.524 111.927 141.332L112.731 141.572C112.619 141.868 112.435 142.136 112.179 142.376C111.923 142.608 111.619 142.792 111.267 142.928C110.915 143.056 110.539 143.12 110.139 143.12Z'
          fill='#C0C0C0'
        />
        <path
          d='M115.78 143L113.248 136.748H114.1L116.236 142.184L118.384 136.748H119.188L116.656 143H115.78Z'
          fill='#C0C0C0'
        />
        <path
          d='M126.251 136.448L126.767 135.704L125.951 135.44L126.083 135.008L126.911 135.32L126.887 134.444H127.379L127.355 135.32L128.183 135.008L128.315 135.44L127.499 135.704L128.027 136.448L127.643 136.712L127.139 135.932L126.623 136.712L126.251 136.448Z'
          fill='#C0C0C0'
        />
        <path
          d='M133.165 136.448L133.681 135.704L132.865 135.44L132.997 135.008L133.825 135.32L133.801 134.444H134.293L134.269 135.32L135.097 135.008L135.229 135.44L134.413 135.704L134.941 136.448L134.557 136.712L134.053 135.932L133.537 136.712L133.165 136.448Z'
          fill='#C0C0C0'
        />
        <path
          d='M140.079 136.448L140.595 135.704L139.779 135.44L139.911 135.008L140.739 135.32L140.715 134.444H141.207L141.183 135.32L142.011 135.008L142.143 135.44L141.327 135.704L141.855 136.448L141.471 136.712L140.967 135.932L140.451 136.712L140.079 136.448Z'
          fill='#C0C0C0'
        />
      </g>
      <text
        id='##cardNumber##'
        fill='white'
        xmlSpace='preserve'
        css='white-space: pre'
        fontFamily='Raleway-Regular'
        fontSize='26'
        letterSpacing='0em'
      >
        <tspan x='22' y='114.678'>
          {cardNumber}
        </tspan>
      </text>
    </g>
  </svg>
)

const Card = ({ card, width = '300px' }) => {
  return (
    <div
      css={`
        position: relative;
      `}
    >
      <img
        src={getCardImage(card)}
        $card={card}
        css={`
          width: ${width};
          border-radius: 16px;
          box-shadow: 0px 0px 12px 0px ${(props) => props.theme.color[props.$card.type + 'CardHighlight']};
        `}
      />
      {card.type === 'physical' ? (
        <PhysicalCardDetails
          css={`
            position: absolute;
            top: 0;
            left: 0;
          `}
          cardholderName={card.display_name}
          expiryDate={card.exp_month + '/' + card.exp_year}
          cardNumber={'**** **** ****' + card.last4}
        />
      ) : (
        <VirtualCardDetails
          css={`
            position: absolute;
            top: 0;
            left: 0;
          `}
          cardholderName={card.display_name}
          expiryDate={card.exp_month + '/' + card.exp_year}
          cardNumber={'**** **** ****' + card.last4}
        />
      )}
    </div>
  )
}

export default Card

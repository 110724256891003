import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from 'stream-ui'
import styled from 'styled-components'
import { Container, Header, marginXAuto } from '../cardnoirStyledElements'
import Footer from '../layout/Footer'
import Navbar from '../layout/Navbar'

const TextWrapper = styled.div`
  ${marginXAuto}
  text-align: center;
  max-width: 450px;
`

const Email = styled.div`
  color: ${({ theme }) => theme.color.primary};
  font-family: ${({ theme }) => theme.font.family.bold};
`
const VerifyEmail = () => {
  const { state } = useLocation()
  const navigate = useNavigate()

  return (
    <>
      <Helmet>Card Noir - Sign Up: Verify Email Address</Helmet>
      <Navbar smaller={true}/>
      <Header>Great, please verify your email address to continue</Header>
      <Container>
        <TextWrapper>
          An email verification link has been sent to the email address you provided:
          <br />
          <br />
          <Email>{state.email}</Email>
          <br />
          Please click on the link in the email to verify your email address and continue with your application setup.
          <br />
          <br />
          If the confirmation email does not arrive within the next few minutes, please check your junk, bulk or spam
          email folder.
          <br />
          <br />
          <Button
            title='&nbsp;&nbsp;SKIP&nbsp;&nbsp;'
            size='extraSmall'
            borderColor='success'
            onClick={() => {
              navigate(`/sign-up/verify/${state.token}/${encodeURI(state.email)}`)
            }}
          />
        </TextWrapper>
      </Container>

      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </>
  )
}

export default VerifyEmail

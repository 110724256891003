import styled, { css } from 'styled-components'

export function onDesktop(cssStrings, ...interpolations) {
  return css`
    @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
      ${css(cssStrings, ...interpolations)}
    }
  `
}
export const Container = styled.div`
  width: 100%;
  padding: 0rem 11px;
  max-width: 414px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 414px) {
    max-width: min(calc(100vw - 22px), 414px);
    padding: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    max-width: min(calc(100vw - 6rem), 1375px);
  }
`

export const paddingBottom = css`
  padding-bottom: 2rem;
`
export const paddingTop = css`
  padding-top: 2rem;
`
export const accentBorderTop = css`
  border-top: ${({ theme }) => theme.border.accent};
`
export const marginXAuto = css`
  margin-right: auto;
  margin-left: auto;
`
export const marginXZero = css`
  margin-right: 0;
  margin-left: 0;
`
export const inputGridPaddingX = css`
  padding-left: 1rem;
  padding-right: 1rem;
  ${onDesktop`
    padding-left: 2rem;
    padding-right: 0rem;
  `}
`

/**
 * The Header styles a h1 element to use the primary color, centered in full width of parent (+ margin/padding)
 */
export const Header = styled.h1`
  font-weight: unset;
  width: 100%;
  text-align: center;
  padding: 0.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.color.primary};
  font-size: 2rem;
  font-family: ${({ theme }) => theme.font.family.semiBold};
`

/**
 * The HeaderSecondary styles a h2 element to use the primary color, left-aligned in full width of parent (+ margin/padding)
 */
export const HeaderSecondary = styled.h2`
  font-weight: unset;
  width: 100%;
  text-align: left;
  padding: 0.5rem;
  margin: 0;
  color: ${({ theme }) => theme.color.primary};
  font-size: 1.25rem;
  font-family: ${({ theme }) => theme.font.family.semiBold};
`

/**
 * TODO rename this.
 *
 * Wraps submit button along with the PageEncriptedReminder. Switches order when appropriate
 */
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  flex-direction: column-reverse;
  ${onDesktop`
    padding: 0 0 0 2rem;
    flex-direction: row;
  `}
`

export const GlowingBox = styled.div`
  background-color: ${(props) => props.theme.color.background};
  border-radius: 5px;
  box-shadow: 0px 0px 30px 0px ${(props) => props.theme.color.shadow};
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
`

export const buttonStyles = {
  appNavbar: {
    iconColor: 'primary',
    buttonType: 'borderless',
    titleFontSize: 'small',
    titleFontFamily: 'normal',
    onHoverTextColor: 'primary',
    textPadding: '0',
    iconPadding: '0.4rem',
  },
  statementCentre: {
    buttonType: 'borderless',
    titleFontSize: 'small',
    titleFontFamily: 'normal',
    titleColor: 'muted',
    onHoverTextColor: 'textPrimary',
    textPadding: '0',
    iconPadding: '0.3rem',
    cssForTextWrapper: ['line-height: 1.1;'],
  },
  formButton: {
    buttonSize: 'small',
    textAlign: 'center',
    paddingInline: '0.5rem',
    titleLetterSpacing: '2px',
    cssForTextWrapper: ['min-width: 130px;'],
  },
  cardOptionsStyle: {
    buttonType: 'bordered-icon',
    titleFontSize: 'normal',
    cssForTextWrapper: css`
      > div {
        flex-shrink: 0;
      }
    `,
  },
}

import React from 'react'
import styled from 'styled-components'
import { Container, Header, onDesktop } from '../cardnoirStyledElements'
import NavbarBackground from '../assets/top_decco.svg'
import { Button } from 'stream-ui'

const GradientDivider = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5px;
  background: #fbb03b;
  background: linear-gradient(225deg, #fbb03b, #ed1c24);
`

const HeaderBackground = styled(NavbarBackground)`
  height: 120px;
  width: 100%;
`

const AppHeader = ({ title, description, icon, iconOnClick, buttons = [], className, blur = false }) => {
  return (
    <div
      css={`
        position: relative;
        height: 125px;
        z-index: 1;
      `}
      className={className}
    >
      <div
        css={`
          position: fixed;
          left: 0;
          right: 0;
          height: 125px;
          filter: ${blur ? 'blur(5px)' : null};
        `}
      >
        <div
          css={`
            position: fixed;
            left: 0;
            right: 0;
            height: 120px;
          `}
        >
          <Container
            css={`
              display: flex;
              align-items: center;
              height: 100%;
              justify-content: space-between;
            `}
          >
            <div
              css={`
                display: flex;
                align-items: center;
                gap: 0.625rem;
              `}
            >
              {icon && (
                <Button
                  icon={icon}
                  onClick={iconOnClick}
                  iconColor={'primary'}
                  borderColor='primary'
                  onHoverIconShadowColor={iconOnClick ? null : 'transparent'}
                  onHoverBorderShadowColor={iconOnClick ? null : 'transparent'}
                />
              )}
              <div css='width: 100%;'>
                <Header
                  css={`
                    margin: 0;
                    padding-bottom: 0;
                    text-align: left;
                    font-size: 1.375rem;
                    line-height: 1;
                    ${onDesktop`
                      font-size: 1.875rem;
                    `}
                  `}
                >
                  {title}
                </Header>
                <div
                  css={`
                    font-size: ${(props) => props.theme.font.size.extraSmall};
                    color: ${(props) => props.theme.color.textSecondary};
                    text-align: center;
                    padding-bottom: 0.3rem;
                    line-height: 1.8;
                    padding-left: 0.5rem;
                    ${onDesktop`
                      font-size: ${(props) => props.theme.font.size.small};
                      text-align: left;
                    `}
                  `}
                >
                  {description}
                </div>
              </div>
            </div>
            <div
              css={`
                gap: 0.5rem;
                display: flex;
              `}
            >
              {buttons.map((b, i) => (
                <div key={i}>{b}</div>
              ))}
            </div>
          </Container>
        </div>
        <HeaderBackground />
        <GradientDivider />
      </div>
    </div>
  )
}

export default AppHeader

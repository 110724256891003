import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Input, PageEncryptedReminder, InputGrid, onStringChange } from 'stream-ui'
import API from '../api'
import FAEyeIcon from '../assets/icons/FA_eye.svg'
import FAEyeSlashIcon from '../assets/icons/FA_eye_slash.svg'
import {
  buttonStyles,
  ButtonWrapper,
  Container,
  Header,
  inputGridPaddingX,
  paddingBottom,
} from '../cardnoirStyledElements'
import ErrorMessage from '../ErrorMessage'
import Footer from '../layout/Footer'
import Navbar from '../layout/Navbar'
import SessionContext from '../SessionContext'
import AuthContext from '../Auth'
import SiderLayout from './SiderLayout'
import SignUpSteps from './SignUpSteps'

const CompleteSignUp = () => {
  const navigate = useNavigate()
  const { session, dispatch } = useContext(SessionContext)
  const { setToken } = useContext(AuthContext)

  /** the verification token.  */
  const params = useParams()
  const email = window.decodeURI(params.email || '')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [signUpError, setSignUpError] = useState('')
  const [showingPassword, setShowingPassword] = useState(false)
  const toggleShowingPassword = () => setShowingPassword((v) => !v)
  const [hideErrors, setHideErrors] = useState(true)

  const longEnough = password.length > 9
  const hasNumber = /\d/.test(password)
  const hasUpperAndLower = /[a-z]/.test(password) && /[A-Z]/.test(password)
  // const hasSymbol = /[!@#$%^&*]/.test(password)
  const notPartOfEmail = !email.includes(password)
  const valid = hasNumber && hasUpperAndLower && longEnough && notPartOfEmail

  const completeSignUp = async () => {
    if (valid && password === confirmPassword) {
      try {
        const individual = await API.completeSignUp(params.token || '', email, password)

        const { token } = await API.authenticateUser({ email, password })
        setToken(token)

        dispatch({
          type: 'setUser',
          user: individual,
        })
      } catch (err) {
        console.error(err)
        setSignUpError(err)
      }
    } else {
      window.scrollTo(0, document.getElementById('top-of-form').getBoundingClientRect().top)
      setHideErrors(false)
    }
  }

  /** FIXME: Identify a better way to handle waiting for dispatch to complete.
   * the Router in App.jsx will most likely need some refactoring to handle redirect logic in a better way
   * Currently, if navigate is fired before dispatch completes, the user is redirected to login page rather
   * than completeProfile page
   */
  useEffect(() => {
    if (valid && password === confirmPassword) {
      navigate('/sign-up/profile')
    }
  }, [session])

  return (
    <>
      <Helmet>Card Noir - Complete Sign Up</Helmet>
      <Navbar smaller={true} />
      <Header>
        Email verified!
        <br />
        Please create a password for your new profile
      </Header>
      <Container>
        <SignUpSteps step={1} />

        <SiderLayout>
          <div
            css={`
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            `}
          >
            <div>
              <InputGrid
                id='top-of-form'
                css={`
                  ${inputGridPaddingX}${paddingBottom}
                `}
              >
                <Input name='email' value={email} css='display:none' />
                <Input
                  label='Create password'
                  type={showingPassword ? 'text' : 'password'}
                  id='password'
                  description='Create a password that you will remember. Your password should be more than 9 characters long. It should contain at least one uppercase letter, one lowercase letter and a number. It cannot be a part of your email address.'
                  value={password}
                  onChange={onStringChange(setPassword)}
                  errorMessage={
                    /* prettier-ignore */
                    password === ''
                      ? 'This field is required'
                      : valid
                        ? ''
                        : 'Please select a new password according to the description above'
                  }
                  showErrorOnBlur={hideErrors}
                  iconOnRight={
                    showingPassword ? (
                      <FAEyeIcon
                        css={`
                          fill: ${({ theme }) => theme.color.primary};
                        `}
                        onClick={toggleShowingPassword}
                      />
                    ) : (
                      <FAEyeSlashIcon
                        css={`
                          fill: ${({ theme }) => theme.color.primary};
                        `}
                        onClick={toggleShowingPassword}
                      />
                    )
                  }
                />
                <Input
                  label='Confirm your password'
                  type='password'
                  id='confirmPassword'
                  description='Enter your password again to confirm your selection'
                  value={confirmPassword}
                  errorMessage={
                    /* prettier-ignore */
                    confirmPassword === ''
                      ? 'This field is required'
                      : (confirmPassword || password) && password !== confirmPassword
                        ? 'The passwords do not match.'
                        : ''
                  }
                  showErrorOnBlur={hideErrors}
                  onChange={onStringChange(setConfirmPassword)}
                />
              </InputGrid>
              <InputGrid>
                <ErrorMessage
                  css={`
                    margin-left: 2rem;
                  `}
                  message={signUpError}
                />
              </InputGrid>
            </div>
            <ButtonWrapper>
              <PageEncryptedReminder
                css={`
                  @media (min-width: 1100px) {
                    margin: 0;
                    padding: 0;
                  }
                `}
              />
              <div>
                <Button
                  onClick={completeSignUp}
                  title='NEXT'
                  {...buttonStyles.formButton}
                  borderColor={valid && password === confirmPassword ? 'success' : 'buttonDisabled'}
                  titleColor={valid && password === confirmPassword ? 'success' : 'buttonDisabled'}
                />
              </div>
            </ButtonWrapper>
          </div>
        </SiderLayout>
      </Container>
      <br />
      <br />
      <br />
      <Footer />
    </>
  )
}

export default CompleteSignUp

import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import {
  AddressInput,
  Button,
  CountryCodeDropdown,
  Dropdown,
  Input,
  InputGrid,
  onStringChange,
  PageEncryptedReminder,
  PhoneNumberInput,
} from 'stream-ui'
import {
  accentBorderTop,
  buttonStyles,
  ButtonWrapper,
  Container,
  Header,
  inputGridPaddingX,
  paddingBottom,
  paddingTop,
} from '../cardnoirStyledElements'
import LanguageDropdown from '../LanguageDropdown'
import Footer from '../layout/Footer'
import Navbar from '../layout/Navbar'
import API from '../api'
import SessionContext from '../SessionContext'
import SiderLayout from './SiderLayout'
import SignUpSteps from './SignUpSteps'
import ErrorMessage from '../ErrorMessage'

const cardUseList = [
  'Banking like with a bank account',
  'Paying without risking debt like with a credit card',
  'Managing expenses aside my bank account',
  'Spending money when travelling',
  'Receiving money from abroad',
  'Other',
]
const cardFundingList = [
  'Salary',
  'Pension',
  'Personal savings',
  'Support from family',
  'Freelance/contractor services',
  'Business income',
  'Personal sales (e.g. eBay)',
  'Investments/trading income',
  'Other',
]

const CompleteProfile = () => {
  const navigate = useNavigate()
  const { session, dispatch } = useContext(SessionContext)

  const [firstName, setFirstName] = useState(session.user.first_name)
  const [lastName, setLastName] = useState(session.user.last_name)
  const [nationality, setNationality] = useState({
    alpha2code: session.user.nationality[0],
  })
  const [countryOfBirth, setCountryOfBirth] = useState({
    alpha2code: session.user.nationality[0],
  })
  const [phoneNumber, setPhoneNumber] = useState()
  const [language, setLanguage] = useState({
    alpha2code: 'GB',
    name: 'English',
    key: 0,
  })
  const [physicalAddress, setPhysicalAddress] = useState({
    country: { alpha2code: session.user.address.country_code },
    buildingOrHouseNameOrSuiteNumber: '',
    streetName: '',
    city: '',
    postalCode: '',
    isValid: false,
  })
  const shippingAddress = {
    country: {
      name: 'Gibraltar',
      phoneCode: '+350',
      alpha2code: 'GI',
      alpha3code: 'GIB',
      key: 77,
    },
    buildingOrHouseNameOrSuiteNumber: 'Suite 1, Quay 29 Offices',
    streetName: '1 Rockface Road',
    city: 'Gibraltar',
    postalCode: 12345,
    isValid: true,
  }
  const [cardUse, setCardUse] = useState()
  const [cardFunding, setCardFunding] = useState()

  const [hideErrors, setHideErrors] = useState(true)

  const [submitting, setSubmitting] = useState(false)
  const [completeProfileError, setCompleteProfileError] = useState()

  const isValid =
    firstName !== undefined &&
    lastName !== undefined &&
    phoneNumber !== undefined &&
    nationality !== undefined &&
    countryOfBirth !== undefined &&
    physicalAddress !== undefined &&
    cardUse !== undefined &&
    cardFunding !== undefined &&
    firstName !== '' &&
    lastName !== '' &&
    physicalAddress.isValid

  let updateProfile = async () => {
    if (!submitting) {
      if (isValid) {
        setCompleteProfileError()
        setSubmitting(true) // hack to disable signup button when processing
        try {
          const individual = await API.completeProfile({
            id: session.user.id,
            firstName,
            lastName,
            phoneNumber,
            nationality,
            countryOfBirth,
            language,
            physicalAddress,
            shippingAddress: session.user.promo_code && session.user.promo_code !== '' ? shippingAddress : undefined,
            cardUse,
            cardFunding,
          })

          setSubmitting(false)

          dispatch({
            type: 'setUser',
            user: individual,
          })
        } catch (err) {
          setSubmitting(false)
          setCompleteProfileError(err)
        }
      } else {
        window.scrollTo(0, document.getElementById('top-of-form').getBoundingClientRect().top)
        setHideErrors(false)
      }
    }
  }

  /** FIXME: Identify a better way to handle waiting for dispatch to complete.
   * See CompleteSignUp.jsx for more info
   */
  useEffect(() => {
    if (isValid && session.user.phone_number) {
      navigate('/sign-up/confirm-order')
    }
  }, [session])

  return (
    <>
      <Helmet>Card Noir - Enter Profile Information</Helmet>
      <Navbar smaller={true}/>
      <Header>Next, complete your application details</Header>
      <Container>
        <SignUpSteps step={2} />
        <SiderLayout
          extraInfo={
            <div
              css={`
                color: ${({ theme }) => theme.color.primary};
                font-variant-numeric: lining-nums;
              `}
            >
              Annual fee is 33 EUR.
              <br />
              Account initiation and minimum sign up period of 3 years:
              <br />
              <br />
              <div
                css={`
                  font-family: ${({ theme }) => theme.font.family.bold};
                `}
              >
                TOTAL FEE DUE TODAY<span css='float:right;'>EUR 99</span>
              </div>
            </div>
          }
        >
          <InputGrid
            id='top-of-form'
            css={`
              ${paddingBottom}${inputGridPaddingX}
            `}
          >
            <Input
              label='First name'
              id='firstname'
              description='First name as it appears on your ID'
              value={firstName}
              onChange={onStringChange(setFirstName)}
              errorMessage={firstName === '' ? 'This field is required' : ''}
              showErrorOnBlur={hideErrors}
            />
            <Input
              label='Last name'
              id='lastname'
              description='Last name as it appears on your ID'
              value={lastName}
              onChange={onStringChange(setLastName)}
              errorMessage={lastName === '' ? 'This field is required' : ''}
              showErrorOnBlur={hideErrors}
            />
            <Input
              type='email'
              label='Email address'
              id='email'
              description='Your email address has already been verified'
              value={session.user.email}
              readOnly={true}
              color='success'
            />
            <PhoneNumberInput
              label='Mobile phone number'
              description='A verification SMS will be sent to this phone number'
              value={phoneNumber}
              onChange={setPhoneNumber}
              required={true}
              showErrorOnBlur={hideErrors}
            />
            <CountryCodeDropdown
              label='Nationality'
              description='Country in which you hold citizenship'
              value={nationality}
              onChange={setNationality}
            />
            <CountryCodeDropdown
              label='Country of birth'
              description='Where you were born and took your first breath'
              value={countryOfBirth}
              onChange={setCountryOfBirth}
            />
            <LanguageDropdown
              label='Language'
              description='Your primary language of communication'
              value={language}
              onChange={setLanguage}
            />
          </InputGrid>

          <AddressInput
            css={`
              ${paddingTop}${paddingBottom}${accentBorderTop}${inputGridPaddingX}
            `}
            label='Permanent Address'
            description='Your permanent address which will be verified later'
            value={physicalAddress}
            onChange={setPhysicalAddress}
            required={true}
            showErrorOnBlur={hideErrors}
          />
          {session.user.promo_code && session.user.promo_code !== '' && (
            <AddressInput
              css={`
                ${paddingTop}${paddingBottom}${accentBorderTop}${inputGridPaddingX}
              `}
              label={
                <>
                  Signing up with Card Noir under the Excelsior Travel Club, means that the address of your new Card
                  Noir card will be listed as the travel clubs main address.
                  <br />
                  This can&apos;t be changed.
                </>
              }
              description='Country where the travel club is located'
              value={shippingAddress}
              readOnly={true}
              color='success'
            />
          )}
          <InputGrid
            css={`
              ${accentBorderTop}${paddingTop}${inputGridPaddingX}
              padding-bottom: 4rem;
            `}
          >
            <Dropdown
              label='What is your card mainly used for?'
              description='Indicate what you intend to be using your card for'
              list={cardUseList}
              value={cardUse}
              onChange={setCardUse}
              errorMessage={!hideErrors && cardUse === undefined ? 'This field is required' : false}
            />
            <Dropdown
              label='How will your card mainly be funded?'
              description='Indicate how fund will usually be added to your card'
              list={cardFundingList}
              value={cardFunding}
              onChange={setCardFunding}
              errorMessage={!hideErrors && cardFunding === undefined ? 'This field is required' : false}
            />
          </InputGrid>
          <ErrorMessage message={completeProfileError} css={'text-align:right; margin-bottom: 1rem;'} />

          <ButtonWrapper>
            <PageEncryptedReminder
              css={`
                @media (min-width: 1100px) {
                  margin: 0;
                  padding: 0;
                }
              `}
            />
            <div>
              <Button
                onClick={updateProfile}
                title='NEXT'
                {...buttonStyles.formButton}
                borderColor={isValid || submitting ? 'success' : 'buttonDisabled'}
                titleColor={isValid || submitting ? 'success' : 'buttonDisabled'}
              />
            </div>
          </ButtonWrapper>
        </SiderLayout>
      </Container>
      <br />
      <br />
      <br />
      <Footer />
    </>
  )
}

export default CompleteProfile

import { DateTime } from 'luxon'
import React, { useContext, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import AuthContext from '../Auth'
import API from '../api'
import SessionContext from '../SessionContext'

const Authenticator = () => {
  const navigate = useNavigate()
  const { user, setUser, dispatch } = useContext(SessionContext)
  const { token } = useContext(AuthContext)
  const [loadingError, setLoadingError] = useState()

  useEffect(async () => {
    if (user === undefined || user?.lastRefresh?.plus({ minutes: 45 }) < DateTime.now() || user.accounts.length === 0) {
      try {
        const u = await API.getUser(token)
        setUser({ ...u, lastRefresh: DateTime.now() })
        dispatch({
          type: 'updateTransactions',
          user: u,
        })
        if (u.status !== 'activated') {
          dispatch({
            type: 'setUser',
            user: u,
          })
          switch (u.status) {
            case 'onboarding_started':
              navigate('/sign-up/profile')
              break
            case 'onboarding_profile_created':
              navigate('/sign-up/confirm-order')
              break
            case 'onboarding_profile_confirmed':
            case 'onboarding_payment_confirmed':
            case 'onboarding_kyc_data_provided':
            case 'pending_activation':
              navigate('/sign-up/last-steps')
              break
          }
        }
      } catch (err) {
        if (err === 'Session expired') {
          setUser()
          navigate('/sign-in')
        } else {
          setLoadingError(err)
        }
      }
    }
  }, [])

  return <Outlet context={{ loadingError }} />
}

export default Authenticator

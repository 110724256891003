import React from 'react'
import ReactCountryFlag from 'react-country-flag'
import styled from 'styled-components'

import { Dropdown, OptionItem, OptionLabel } from 'stream-ui'

const CustomLabel = styled(OptionLabel)`
  display: flex;
  padding: 0;
`

const supportedLanguages = [
  {
    alpha2code: 'GB',
    name: 'English',
  },
  {
    alpha2code: 'FR',
    name: 'French',
  },
]

const LanguageDropdown = ({ label, value, onChange, description, color, readOnly, ...rest }) => {
  const appendKey = (k) => onChange({ ...supportedLanguages[k], key: k })

  return (
    <Dropdown
      {...rest}
      id='countryCode'
      value={value ? value.key : value}
      valueFieldFunction={(v, i) => i}
      list={supportedLanguages}
      label={label}
      description={description}
      onChange={readOnly ? () => {} : appendKey}
      filterOption={(input, val) => val.name.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      optionLabelRenderFunction={(v) => (
        <CustomLabel color={color}>
          <ReactCountryFlag
            countryCode={v.alpha2code}
            svg
            style={{
              fontSize: '1.5em',
              lineHeight: '1.5em',
            }}
          />
          &nbsp;&nbsp;
          <span>{v.name}</span>
        </CustomLabel>
      )}
      optionItemRenderFunction={(v) => (
        <OptionItem>
          <ReactCountryFlag
            countryCode={v.alpha2code}
            svg
            style={{
              fontSize: '1.5em',
              lineHeight: '1.5em',
            }}
          />
          &nbsp;&nbsp;
          <span>{v.name}</span>
        </OptionItem>
      )}
    />
  )
}

export default LanguageDropdown

import React, { useState } from 'react'
import CountryCodeDropdown from './CountryCodeDropdown'
import Input from './Input'
import { DisplayOnlyText, ErrorMessage, InputGrid, Label } from './styledElements'

const AddressInput = ({
  label,
  value = {
    country: {},
    buildingOrHouseNameOrSuiteNumber: '',
    streetName: '',
    city: '',
    postalCode: '',
    isValid: false,
  },
  onChange,
  description,
  countryReadOnly = false,
  countryColor,
  readOnly = false,
  displayOnly = false,
  color,
  gridCSS,
  validator = () => '', // returns an error message, empty message if valid
  errorMessage = 'Please give a valid address',
  required = false,
  requiredMessage = 'The fields in red are required',
  showErrorOnBlur = true,
  ...props
}) => {
  /* set individual variables for the input rather than setting default */
  const isCompletelyEmpty = (val) =>
    (val.country?.alpha2code === undefined || val.country?.alpha2code === '') &&
    (val.streetName === undefined || val.streetName === '') &&
    (val.cty === undefined || val.city === '')

  const checkValidity = (val) =>
    (!required && isCompletelyEmpty(val)) ||
    (val.country !== undefined &&
      val.country.alpha2code !== undefined &&
      val.streetName !== undefined &&
      val.streetName !== '' &&
      val.city !== undefined &&
      val.city !== '' &&
      validator(val) === '')

  const [isValid, setIsValid] = useState(checkValidity(value))

  const updateAddress = (newValue) => {
    const isValid = checkValidity(newValue)
    onChange({ ...newValue, isValid })
    setIsValid(isValid)
  }

  const [blurredCountry, setBlurredCountry] = useState(value.country?.alpha2code !== undefined)
  const [blurredStreetName, setBlurredStreetName] = useState(value.streetName && value.streetName !== '')
  const [blurredCity, setBlurredCity] = useState(value.city && value.city !== '')
  const isTouched = blurredCountry && blurredStreetName && blurredCity

  return (
    <div {...props}>
      {label && (
        <Label
          css={`
            max-width: 328px;
            margin-left: auto;
            margin-right: auto;

            @media (min-width: 740px) {
              max-width: 720px;
              margin-left: unset;
              margin-right: unset;
            }
          `}
        >
          {label}
        </Label>
      )}
      {displayOnly ? (
        <InputGrid
          css={`
            row-gap: 0rem;
            grid-template-columns: minmax(288px, 328px);
            @media (min-width: 740px) {
              grid-template-columns: repeat(auto-fit, minmax(288px, 328px));
            }
            ${gridCSS}
          `}
        >
          {value.country?.name && (
            <>
              <DisplayOnlyText>{value.country?.name}</DisplayOnlyText>
              <div
                css={`
                  display: none;
                  @media (min-width: 740px) {
                    display: unset;
                  }
                `}
              />
            </>
          )}
          {value.streetName && (
            <>
              <DisplayOnlyText>{value.streetName}</DisplayOnlyText>
              <div
                css={`
                  display: none;
                  @media (min-width: 740px) {
                    display: unset;
                  }
                `}
              />
            </>
          )}
          {value.buildingOrHouseNameOrSuiteNumber && (
            <>
              <DisplayOnlyText>{value.buildingOrHouseNameOrSuiteNumber}</DisplayOnlyText>
              <div
                css={`
                  display: none;
                  @media (min-width: 740px) {
                    display: unset;
                  }
                `}
              />
            </>
          )}
          {value.city && (
            <>
              <DisplayOnlyText>
                {value.city}
                {value.postalCode ? `, ${value.postalCode}` : ''}
              </DisplayOnlyText>
              <div
                css={`
                  display: none;
                  @media (min-width: 740px) {
                    display: unset;
                  }
                `}
              />
            </>
          )}
        </InputGrid>
      ) : (
        <InputGrid
          css={`
            row-gap: 1rem;
            ${gridCSS}
          `}
        >
          <CountryCodeDropdown
            value={value.country}
            disabled={readOnly || countryReadOnly}
            onChange={(newCountry) => updateAddress({ ...value, country: newCountry })}
            description={description}
            color={countryColor ? countryColor : color}
            onBlur={() => setBlurredCountry(true)}
            errorMessage={!isValid && (!showErrorOnBlur || isTouched) ? '' : false}
          />
          <div
            css={`
              display: none;
              @media (min-width: 740px) {
                display: unset;
              }
            `}
          />
          <Input
            description='Street address'
            value={value.streetName}
            onChange={(e) => updateAddress({ ...value, streetName: e.target.value })}
            color={color}
            readOnly={readOnly}
            onBlur={() => setBlurredStreetName(true)}
            invalid={!isValid && (!showErrorOnBlur || isTouched)}
          />
          <Input
            description='Building, house name or suite number'
            value={value.buildingOrHouseNameOrSuiteNumber}
            onChange={(e) => updateAddress({ ...value, buildingOrHouseNameOrSuiteNumber: e.target.value })}
            color={color}
            readOnly={readOnly}
          />
          <Input
            description='Town or city'
            value={value.city}
            onChange={(e) => updateAddress({ ...value, city: e.target.value })}
            color={color}
            readOnly={readOnly}
            onBlur={() => setBlurredCity(true)}
            invalid={!isValid && (!showErrorOnBlur || isTouched)}
          />
          <Input
            description='Postal or zip code'
            value={value.postalCode}
            onChange={(e) => updateAddress({ ...value, postalCode: e.target.value })}
            color={color}
            readOnly={readOnly}
          />
          <ErrorMessage css={!isValid && (!showErrorOnBlur || isTouched) ? '' : 'display:none'}>
            {isCompletelyEmpty(value) ? requiredMessage : validator(value) || errorMessage}
          </ErrorMessage>
        </InputGrid>
      )}
    </div>
  )
}

export default AddressInput

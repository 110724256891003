import { DateTime } from 'luxon'
import React, { useContext, useRef, useState } from 'react'
import { Button, Flag, getKeyedCountry } from 'stream-ui'
import { css } from 'styled-components'
import NameIcon from '../assets/icons/icon-beneficiary-name-bookmark.svg'
import ProofOfPaymentIcon from '../assets/icons/icon-download-proof-payment.svg'
import DownArrowIcon from '../assets/icons/Icon_Arrow_Drop_Down.svg'
import AddNewBeneficiaryIcon from '../assets/icons/Icon_Beneficiary_Add.svg'
import CardNoirIcon from '../assets/icons/Icon_Beneficiary_CARDN.svg'
import SepaIcon from '../assets/icons/Icon_Beneficiary_SEPA.svg'
import SwiftIcon from '../assets/icons/Icon_Beneficiary_SWIFT.svg'
import EditIcon from '../assets/icons/Icon_Card_Name_Edit.svg'
import DeleteIcon from '../assets/icons/Icon_Close_Delete_No.svg'
import SendIcon from '../assets/icons/Icon_Funds_Send.svg'
import SaveExportIcon from '../assets/icons/Icon_Statement_Save_Export.svg'
import { buttonStyles, onDesktop } from '../cardnoirStyledElements'
import ChatButton from '../ChatButton'
import { currencyFormat } from '../currencies'
import LightboxContext from '../LightboxContext'
import SessionContext from '../SessionContext'
import { formatIBAN } from '../utils'
import LBAddBeneficiary from './lightboxes/LBAddBeneficiary'
import LBSendFunds from './lightboxes/LBSendFunds'

const Beneficiary = ({ data, isLastInGroup, isEven }) => {
  const { user, session } = useContext(SessionContext)
  const { setLightbox } = useContext(LightboxContext)
  const [isOpened, setIsOpened] = useState(false)
  const collapsibleElement = useRef()

  const paymentTypeDescription = {
    sepa: 'European IBAN - SEPA',
    swift: 'International SWIFT',
    cardnoir: 'Card Noir beneficiary',
  }
  const transactionsByBeneficiary = session.transactions
    .filter((t) => {
      if (data.account_details.account_id !== undefined) {
        return t.counterparty.account_details?.account_id === data.account_details.account_id
      } else {
        return t.counterparty.account_details?.iban === data.account_details?.account?.iban
      }
    })

    .sort((f, s) => DateTime.fromISO(f.created_at) < DateTime.fromISO(s.created_at))

  return (
    <div
      $isEven={isEven}
      css={`
        cursor: pointer;
        border-bottom: 1pt solid #aaaaaa;
        margin-bottom: ${isLastInGroup ? '0.35rem' : ''};
        background: ${(props) => (props.$isEven ? props.theme.color.backgroundMuted : props.theme.color.background)};
      `}
      onClick={() => setIsOpened((v) => !v)}
    >
      <div
        css={`
          padding: 9.6px 0.75rem;
          height: 110px;
          display: grid;
          row-gap: 0.5rem;
          column-gap: 1rem;
          grid-template-columns: auto 1fr auto;
          grid-template-areas:
            'icon name button'
            'icon typeAndLocation typeAndLocation';

          ${onDesktop`
            height: 90px;
            grid-template-columns: auto 1fr auto;
            grid-template-areas: 
              'icon name button' 
              'icon typeAndLocation button'; 
          `}
        `}
      >
        <div css='width: 1.5rem; grid-area: icon;'>
          {data.payment_type === 'swift' ? (
            <SwiftIcon
              css={`
                fill: ${(props) => props.theme.color.white};
              `}
            />
          ) : data.payment_type === 'cardnoir' ? (
            <CardNoirIcon
              css={`
                fill: ${(props) => props.theme.color.white};
              `}
            />
          ) : (
            <SepaIcon
              css={`
                fill: ${(props) => props.theme.color.white};
              `}
            />
          )}
        </div>
        <div
          css={`
            display: flex;
            grid-area: name;
            flex-direction: column;
            gap: 0.25rem;
            ${onDesktop`
              justify-content: space-between;
              flex-direction: row;
            `}
          `}
        >
          <div
            css={`
              font-family: ${(props) => props.theme.font.family.bold};
            `}
          >
            {data.alias}
          </div>
          <div
            css={`
              display: flex;
              align-items: center;
              gap: 0.5rem;
              font-size: 0.875rem;

              ${onDesktop`
                flex-direction: row-reverse;
              `}
            `}
          >
            <NameIcon
              css={`
                width: 0.875rem;
                fill: ${(props) => props.theme.color.primaryHighlight};
              `}
            />
            <div>{data.name}</div>
          </div>
        </div>
        <div
          css={`
            grid-area: button;
            ${onDesktop`
              width: 200px;
              display: unset;
            `}
          `}
        >
          <Button
            buttonType='borderless'
            buttonSize='extra-small'
            icon={
              <DownArrowIcon
                css={`
                  transform: ${isOpened ? 'rotate(180deg)' : null};
                  transition-duration: 0.3s;
                  transition-timing-function: ease-in-out;
                  transition-delay: 0s;
                  transition-property: transform;
                `}
              />
            }
            iconColor='primaryHighlight'
            cssForIconWrapper={css`
              width: 1.25rem;
              height: 1.25rem;
            `}
            css={`
              flex-shrink: 0;
              min-width: 1.25rem;
              min-height: 1.25rem;
              ${onDesktop`
                display: none;
              `}
            `}
          />
          <Button
            buttonType='normal'
            buttonSize='small'
            borderColor='successHighlight'
            borderRadius={'5px'}
            icon={<SendIcon />}
            iconColor='white'
            backgroundColor={isEven ? 'backgroundMuted' : 'background'}
            title='Send funds'
            titleColor='white'
            titleFontSize='small'
            css={`
              width: 100%;
              justify-content: center;
              min-height: 1.875rem;
              display: none;
              ${onDesktop`
                display: inline-flex;
              `}
            `}
            cssForIconWrapper={css`
              width: 1.875rem;
              height: 1.875rem;
            `}
            iconPadding='0.3rem'
            textAlign='center'
            paddingInline='1.8rem'
            onClick={(e) => {
              e.stopPropagation()
              setLightbox(<LBSendFunds />)
            }}
          />
          <Button
            buttonType='normal'
            buttonSize='small'
            borderColor={isOpened ? 'success' : 'muted'}
            borderRadius={'5px'}
            icon={
              <DownArrowIcon
                css={`
                  transform: ${isOpened ? 'rotate(180deg)' : 'none'};
                  transition: transform 0.3s ease-in-out;
                `}
              />
            }
            iconColor={isOpened ? 'white' : 'primaryHighlight'}
            backgroundColor={isOpened ? 'success' : isEven ? 'backgroundMuted' : 'background'}
            titleColor={'white'}
            title={isOpened ? 'Close beneficiary info' : 'View beneficiary info'}
            titleFontSize='small'
            titleFontFamily='normal'
            css={`
              width: 100%;
              justify-content: center;
              min-height: 1.875rem;
              margin-top: 0.6rem;
              padding: 0 1rem 0 0;
              display: none;
              ${onDesktop`
                display: inline-flex;
              `}
            `}
            cssForIconWrapper={css`
              width: 1.875rem;
              height: 1.875rem;
              left: unset;
              right: 8px;
            `}
            iconPadding='0.3rem'
            textAlign='center'
          />
        </div>
        <div
          css={`
            grid-area: typeAndLocation;
            font-size: 12px;
            display: flex;
            justify-content: space-between;
            ${onDesktop`
              padding-left: 0.4rem;
            `}
          `}
        >
          <div>
            <div
              css={`
                font-family: ${(props) => props.theme.font.family.bold};
              `}
            >
              {paymentTypeDescription[data.payment_type]}
            </div>
            {data.country && (
              <div css='display: flex; align-items: center; line-height: 1.3;'>
                <Flag countryCode={data.country} square={true} css='height: 12px; width: 12px; border-radius: 2px;' />
                &nbsp;&nbsp;
                <div>
                  <span
                    css={`
                      font-family: ${(props) => props.theme.font.family.bold};
                    `}
                  >
                    {data.country}
                  </span>
                  &nbsp;&nbsp;{getKeyedCountry(data.country).name}
                </div>
              </div>
            )}
          </div>
          <div css='text-align: right; font-size: 0.75rem;'>
            <div>Default reference</div>
            <div
              css={`
                font-family: ${(props) => props.theme.font.family.bold};
              `}
            >
              {data.reference || '-'}
            </div>
          </div>
        </div>
      </div>
      <div
        ref={collapsibleElement}
        css={`
          padding-right: 0.75rem;
          height: ${isOpened ? collapsibleElement.current?.scrollHeight : 0}px;
          margin: ${isOpened ? '0.5rem 0rem 0.75rem 0rem' : '0'};
          opacity: ${isOpened ? 1 : 0};
          overflow: hidden;
          transition-duration: 0.3s;
          transition-timing-function: ease;
          transition-delay: 0s;
          transition-property: height opacity;

          ${onDesktop`
            margin: ${isOpened ? '0.5rem 3rem 0.75rem 3rem' : '0 0 0 3rem'};
          `}
        `}
      >
        <div
          css={`
            border-top: 1pt solid ${(props) => props.theme.color.successHighlight};
            border-left: 1pt solid ${(props) => props.theme.color.successHighlight};
            padding: 0.5rem 0 0.5rem 1rem;
            font-size: ${(props) => props.theme.font.size.extraSmall};
          `}
        >
          <div
            css={`
              font-family: ${(props) => props.theme.font.family.bold};
              font-size: ${(props) => props.theme.font.size.small};
              padding-bottom: 0.25rem;
            `}
          >
            Beneficiary details
          </div>
          <div
            css={`
              padding-left: 0.4rem;
              padding-bottom: 0.75rem;
              ${onDesktop`padding-bottom: 1rem;`}
            `}
          >
            <div
              css={`
                display: grid;
                grid-template-columns: 1fr;
                gap: 1rem;

                ${onDesktop`
                  grid-template-columns: 1fr 1fr;
                  gap: 1.5rem;
                `}

                span {
                  font-family: ${(props) => props.theme.font.family.bold};
                }
              `}
            >
              <div
                css={`
                  > div {
                    display: flex;
                    justify-content: space-between;
                  }
                `}
              >
                <div>
                  Name<span>{data.name}</span>
                </div>
                <div>
                  Contact<span>{data.email}</span>
                </div>
              </div>

              <div
                css={`
                  > div {
                    display: flex;
                    justify-content: space-between;
                  }
                `}
              >
                <div>
                  IBAN<span>{formatIBAN(data.account_details.account?.iban) || 'N/A'} </span>
                </div>
                <div>
                  Network<span css='text-transform: uppercase;'>{data.payment_type}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            css={`
              border-top: 1pt ${isOpened ? 'solid' : 'none'} ${(props) => props.theme.color.successHighlight};
              display: grid;
              grid-template-columns: 1fr 1fr;
              row-gap: 0.75rem;
              justify-content: start;
              padding-top: 0.75rem;
              padding-bottom: 0.75rem;
              ${onDesktop`
                gap: 0.75rem;
                grid-template-columns: auto auto auto;
                padding-top: 1rem;
                padding-bottom: 1rem;
              `}
            `}
          >
            <Button
              buttonType='bordered-icon'
              icon={<SendIcon />}
              iconColor='textPrimary'
              titleColor='textPrimary'
              borderColor='primaryHighlight'
              title='Send funds'
              description='to this beneficiary'
              textPadding='0 0.75rem'
              onClick={(e) => {
                e.stopPropagation()
                setLightbox(<LBSendFunds />)
              }}
            />
            <Button
              buttonType='bordered-icon'
              icon={<EditIcon />}
              iconColor='textPrimary'
              titleColor='textPrimary'
              borderColor='successHighlight'
              title='Edit details'
              description='beneficiary info'
              textPadding='0 0.75rem'
            />
            <Button
              buttonType='bordered-icon'
              icon={<DeleteIcon />}
              iconColor='error'
              titleColor='textPrimary'
              borderColor='errorHighlight'
              title='Delete'
              description='beneficiary'
              textPadding='0 0.75rem'
            />
          </div>
          <div
            css={`
              border-top: 1pt ${isOpened ? 'solid' : 'none'} ${(props) => props.theme.color.successHighlight};
              font-family: ${(props) => props.theme.font.family.bold};
              font-size: ${(props) => props.theme.font.size.small};
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            Transaction history
            <Button
              icon={<SaveExportIcon />}
              buttonType='borderless'
              buttonSize='small'
              iconColor='success'
              title='Save/Export'
              titleFontSize='small'
              titleFontFamily='normal'
              titleColor='muted'
              textPadding='0'
            />
          </div>
          {transactionsByBeneficiary ? (
            <div css='padding-left: 0.4rem;'>
              {transactionsByBeneficiary.map((t) => (
                <div
                  key={t.id}
                  css={`
                    padding: 0.5rem 0.5rem 0.5rem 0;
                    display: grid;
                    grid-template-columns: 1fr auto;
                    grid-template-areas: 'date button' 'nameAndBalance button';
                    column-gap: 0.25rem;
                    row-gap: 0.25rem;

                    ${onDesktop`
                      grid-template-columns: auto 1fr auto;
                      grid-template-areas: 'date nameAndBalance button';
                      column-gap: 1rem;
                    `}
                  `}
                >
                  <div
                    css={`
                      grid-area: date;
                      color: ${(props) => props.theme.color.muted};
                    `}
                  >
                    {DateTime.fromISO(t.created_at).toFormat('yyyy.LL.dd')}
                  </div>

                  <div
                    css={`
                      grid-area: nameAndBalance;
                      display: flex;
                      justify-content: space-between;
                      align-items: top;
                    `}
                  >
                    <div
                      css={`
                        line-height: 1.4;
                      `}
                    >
                      <div
                        css={`
                          padding-left: 0.4rem;
                          ${onDesktop`padding-left: 0;`}
                        `}
                      >
                        {user.accounts.find((acc) => acc.id === t.account_id).name}
                      </div>
                      <div
                        css={`
                          color: ${(props) => props.theme.color.muted};
                          padding-left: 0.4rem;
                        `}
                      >
                        {t.reference}
                      </div>
                    </div>
                    <div
                      css={`
                        font-size: ${(props) => props.theme.font.size.small};
                        font-family: ${(props) => props.theme.font.family.bold};
                      `}
                    >
                      {currencyFormat.format(t.amount < 0 ? 0 - t.amount : t.amount) + '  ' + t.currency}
                    </div>
                  </div>

                  <Button
                    icon={<ProofOfPaymentIcon css='path { stroke: white; }' />}
                    buttonType='borderless'
                    title={
                      <>
                        Proof of
                        <br />
                        payment
                      </>
                    }
                    titleColor='muted'
                    iconColor='success'
                    titleFontFamily='normal'
                    titleFontSize='transactionSummaryButtonTitle'
                    iconPadding='0.3rem 0.4rem'
                    textPadding='0'
                    cssForTextWrapper={css`
                      line-height: 1.1;
                    `}
                    css={`
                      grid-area: button;
                      padding-top: 14px;

                      ${onDesktop`
                        padding-top: 0px;
                        margin-top: -10px;
                      `}
                    `}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div css='padding-left: 0.4rem;'>No recent transaction.</div>
          )}
        </div>
      </div>
    </div>
  )
}

const BeneficiaryCentreList = ({ className, setGroupFilter }) => {
  const { session } = useContext(SessionContext)

  const { setLightbox } = useContext(LightboxContext)

  return (
    <div>
      <div
        className={className}
        css={`
          box-shadow: 0 0 30px 0 ${(props) => props.theme.color.shadow};
          background-color: ${(props) => props.theme.color.background};
          border-radius: 5px;
          width: 100%;
          padding: 1rem;
        `}
      >
        <div
          css={`
            display: flex;
            justify-content: space-between;
            padding-bottom: 0.75rem;
            border-bottom: 2pt solid ${(props) => props.theme.color.successHighlight};
          `}
        >
          <div
            css={`
              font-family: ${(props) => props.theme.font.family.bold};
              font-size: 1.125rem;
              color: ${(props) => props.theme.color.textPrimary};
            `}
          >
            List of
            <br />
            beneficiaries
          </div>
          <div
            css={`
              display: none;
              ${onDesktop`display: block;`}
            `}
          >
            <Button
              title={
                <>
                  Add&nbsp;new
                  <br />
                  beneficiary
                </>
              }
              {...buttonStyles.statementCentre}
              icon={<AddNewBeneficiaryIcon />}
              iconColor={'primary'}
              onClick={(e) => {
                e.stopPropagation()
                setLightbox(<LBAddBeneficiary />)
              }}
            />
          </div>
        </div>
        <div>
          {session.beneficiaries.map((b, i) => (
            <Beneficiary
              data={b}
              isEven={i % 2 === 0}
              isLastInGroup={i === session.beneficiaries.length - 1}
              key={b.id}
              setGroupFilter={setGroupFilter}
            />
          ))}
        </div>
      </div>

      <ChatButton css='margin-top: 2rem;' />
    </div>
  )
}

export default BeneficiaryCentreList

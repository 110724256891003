import React from 'react'
import styled from 'styled-components'
import CNCardVisa from '../assets/CN_card_Visa2_resized-min.png'
import { marginXAuto, marginXZero } from '../cardnoirStyledElements'

export const Layout = styled.div`
  margin-right: auto;
  margin-left: auto;
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;

  > div:first-child {
    grid-row: 2;
  }

  @media (min-width: 740px) {
    > div:first-child {
      grid-row: 1;
    }
    grid-template-columns: 1fr;
    width: 720px;
  }

  @media (min-width: 1100px) {
    grid-template-columns: 1fr 2fr;
    width: 1080px;
  }

`

const StyledSider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: calc(320px - 2rem);

  @media (min-width: 360px) {
    width: 360px;
  }

  @media (min-width: 740px) {
    width: 720px;
    border-bottom: ${({ theme }) => theme.border.accent};
  }

  @media (min-width: 1100px) {
    width: 360px;
    border-bottom: unset;
    border-right: ${({ theme }) => theme.border.accent};
  }
`

const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: ${({ hasExtraInfo, theme }) => (hasExtraInfo ? theme.border.accent : 'unset')};

  @media (min-width: 740px) {
    flex-direction: row;
  }
  @media (min-width: 1100px) {
    border-bottom: unset;
    flex-direction: column;
    margin-top: -0.8rem;
  }
`

const Image = styled.img`
  margin-top: -2rem;
  width: calc(320px - 2rem);

  @media (min-width: 360px) {
    width: 360px;
    /* padding: 0 21px; */
  }
`
const List = styled.ul`
  margin: 0;
  padding-left: 1.5rem;
  width: calc(320px - 2rem);
  padding-bottom: ${({ hasExtraInfo }) => (hasExtraInfo ? '1rem' : 'unset')};

  li {
    margin-bottom: 1rem;

    &::marker {
      font-size: 24px;
      color: ${({ theme }) => theme.color.primary};
    }
  }

  @media (min-width: 360px) {
    width: 275px;
    ${marginXAuto}
  }

  @media (min-width: 740px) {
    padding-bottom: unset;
    width: 100%;
    margin-top: 1rem;
    ${marginXZero}
  }

  @media (min-width: 1100px) {
    padding-bottom: ${({ hasExtraInfo }) => (hasExtraInfo ? '1rem' : 'unset')};
    border-bottom: ${({ hasExtraInfo, theme }) => (hasExtraInfo ? theme.border.accent : 'unset')};
    width: 275px;
    margin-top: 0;
    ${marginXAuto}
  }
`

const ExtraInfo = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin: 0;
  ${marginXAuto}

  width: calc(320px - 2rem);
  @media (min-width: 360px) {
    width: 275px;
  }
  @media (min-width: 740px) {
    width: 100%;
    margin-top: 0;
    padding: 2rem 1rem;
    ${marginXZero}
  }
  @media (min-width: 1100px) {
    width: 275px;
    padding: 2rem 0;
    ${marginXAuto}
  }
`

export const Form = styled.div`
  min-width: 320px;
  @media (min-width: 360px) {
    min-width: 360px;
  }
  @media (min-width: 740px) {
    /* 360*2 + 20*/
    min-width: 720px;
    padding-top: 2rem;
  }
  @media (min-width: 1100px) {
    padding-top: unset;
  }
`

const SiderLayout = ({ extraInfo, children }) => {
  return (
    <Layout>
      <StyledSider>
        <MainInfo hasExtraInfo={extraInfo !== undefined}>
          <Image className='card-holder' src={CNCardVisa} alt='' />
          <List hasExtraInfo={extraInfo !== undefined}>
            <li>Personal IBAN</li>
            <li>Secure & safe transactions</li>
            <li>Multiple Cards as needed</li>
            <li>Several loading & funding methods</li>
            <li>
              Virtual card available on approval.
              <br />
              Physical cards coming SOON...
            </li>
          </List>
        </MainInfo>
        {extraInfo != undefined ? <ExtraInfo> {extraInfo}</ExtraInfo> : null}
      </StyledSider>
      <Form>{children}</Form>
    </Layout>
  )
}

export default SiderLayout

import { darken } from 'polished'
import React from 'react'
import styled from 'styled-components'

const StyledButton = styled.div`
  cursor: pointer;
  border-radius: 30px;
  border: 1pt solid
    ${(props) =>
      props.$isActive ? props.theme.color[props.$activeBorderColor] : props.theme.color[props.$inactiveBorderColor]};
  padding: 0.5rem 1rem;
  text-align: center;
  color: ${(props) =>
    props.$isActive ? props.theme.color[props.$activeColor] : props.theme.color[props.$inactiveColor]};
  background-color: ${(props) =>
    props.$isActive
      ? props.theme.color[props.$activeBackgroundColor]
      : props.theme.color[props.$inactiveBackgroundColor]};

  :active {
    background-color: ${(props) =>
      darken(
        0.3,
        props.$isActive
          ? props.theme.color[props.$activeBackgroundColor]
          : props.theme.color[props.$inactiveBackgroundColor]
      )};

    border-color: ${(props) =>
      darken(
        0.3,
        props.$isActive ? props.theme.color[props.$activeBorderColor] : props.theme.color[props.$inactiveBorderColor]
      )};
  }
`

const ToggleButton = ({
  title,
  isActive,
  onToggle,
  activeBackgroundColor = 'primary',
  inactiveBackgroundColor = 'background',
  activeColor = 'textPrimary',
  inactiveColor = 'muted',
  activeBorderColor = 'muted',
  inactiveBorderColor = 'muted',
  ...props
}) => {
  return (
    <StyledButton
      $isActive={isActive}
      $activeBackgroundColor={activeBackgroundColor}
      $inactiveBackgroundColor={inactiveBackgroundColor}
      $activeColor={activeColor}
      $inactiveColor={inactiveColor}
      $activeBorderColor={activeBorderColor}
      $inactiveBorderColor={inactiveBorderColor}
      onClick={() => onToggle(!isActive)}
      {...props}
    >
      {title}
    </StyledButton>
  )
}

export default ToggleButton

import React, { useEffect, useReducer, useState } from 'react'
import { BrowserRouter, Route, Routes, useLocation, Navigate } from 'react-router-dom'
import AuthContext, { useAuthManager } from './Auth'
import Authenticator from './authenticatedRoutes/Authenticator'
import BeneficiaryCentre from './authenticatedRoutes/BeneficiaryCentre'
import Home from './authenticatedRoutes/Home'
// import LBAddBeneficiary from './authenticatedRoutes/lightboxes/LBAddBeneficiary'
import StatementCentre from './authenticatedRoutes/StatementCentre'
import LightboxContext from './LightboxContext'
import MenuContext from './MenuContext'
import CompleteProfile from './onboarding/CompleteProfile'
import CompleteSignUp from './onboarding/CompleteSignUp'
import ConfirmOrder from './onboarding/ConfirmOrder'
import LastSteps from './onboarding/lastSteps'
import SignUp from './onboarding/SignUp'
import VerifyEmail from './onboarding/VerifyEmail'
import SessionContext, { initSession } from './SessionContext'
import sessionReducer from './sessionReducer'
import SignIn from './SignIn'

/** TODO: adding lazy loading */

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const App = () => {
  let cardNoirSession = JSON.parse(localStorage.getItem('card-noir-session')) || initSession
  if (cardNoirSession.version != initSession.version) {
    /* reset session if a new version is needed - avoids unstable state. 
    shouldn't cause any issue if we are pulling info from API */
    cardNoirSession = initSession
    localStorage.setItem('card-noir-session', JSON.stringify(cardNoirSession))
  }
  const [session, dispatch] = useReducer(sessionReducer, cardNoirSession)
  const [user, setUser] = useState()

  const [token, setToken, lastLoggedIn] = useAuthManager()

  const [menuCollapsed, _setMenuCollapsed] = useState(true)
  // const [lightbox, _setLightbox] = useState(<LBAddBeneficiary />)
  const [lightbox, _setLightbox] = useState()

  const setMenuCollapsed = (val) => {
    _setMenuCollapsed(val)
    document.getElementById('body').style = `overflow: ${val && lightbox === undefined ? 'auto' : 'hidden'}`
  }
  const setLightbox = (val) => {
    _setLightbox(val)
    document.getElementById('body').style = `overflow-y: ${val || !menuCollapsed ? 'hidden' : 'auto'}`
  }

  const basename = process.env.NODE_ENV === 'development' ? '' : 'app'

  // console.log(user)
  return (
    <SessionContext.Provider value={{ session, dispatch, user, setUser }}>
      <AuthContext.Provider value={{ token, lastLoggedIn, setToken }}>
        <MenuContext.Provider value={{ menuCollapsed, setMenuCollapsed }}>
          <LightboxContext.Provider value={{ lightbox, setLightbox }}>
            <BrowserRouter basename={basename}>
              <ScrollToTop />
              <Routes>
                <Route path='/sign-up'>
                  <Route index element={<SignUp />} />
                  <Route path='awaiting-verification' element={<VerifyEmail />} />
                  {/* if i understood properly, once our API is available, this token will be used to fetch info submitted on previous screen */}
                  <Route path='verify/:token/:email' element={<CompleteSignUp />} />

                  {/* TODO: Authenticator loads user session info if not present and redirects base on user account status */}
                  <Route element={<Authenticator />}>
                    <Route path='profile' element={<CompleteProfile />} />
                    <Route path='confirm-order' element={<ConfirmOrder />} />
                    <Route path='last-steps' element={<LastSteps />} />
                  </Route>
                </Route>

                <Route path='/member' element={<Authenticator />}>
                  <Route index element={<Home />} />
                  {/** TODO: Optional param is no longer supported in react-router v6. consider moving to wouter (not a typo) */}
                  <Route path='statement-centre' element={<StatementCentre />} />
                  <Route path='statement-centre/:id' element={<StatementCentre />} />
                  <Route path='beneficiary-centre' element={<BeneficiaryCentre />} />
                </Route>
                <Route path='sign-in' element={token ? <Navigate replace to='/member' /> : <SignIn />} />

                {/* FIXME - better handling for 404? Currently all path that doesn't match above will go to either Home or Sign-In page */}
                <Route path='*' element={token ? <Navigate replace to='/member' /> : <SignIn />} />
              </Routes>
            </BrowserRouter>
          </LightboxContext.Provider>
        </MenuContext.Provider>
      </AuthContext.Provider>
    </SessionContext.Provider>
  )
}

export default App

import React from 'react'
import { Button, Input, Label } from 'stream-ui'
import styled from 'styled-components'
import FacebookIcon from '../assets/icons/Icon_Social_Facebook_SVG.svg'
import InstagramIcon from '../assets/icons/Icon_Social_Instagram_SVG.svg'
import TwitterIcon from '../assets/icons/Icon_Social_Twitter_SVG.svg'
import { Container, onDesktop } from '../cardnoirStyledElements'
import AppFooter from './AppFooter'

const FooterNavbar = styled.nav`
  background: ${({ theme }) => theme.color.darkest};
  padding: 1.25rem;
`

const TopFooterContainer = styled(Container)`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;

  ${onDesktop`
    flex-direction: row;
    gap: 1.5rem;
  `}

  @media (min-width: 1000px) {
    gap: 2.5rem;
  }
`

const LinksWrapper = styled.div`
  width: 100%;

  > a {
    font-size: 1rem;
    line-height: 1.3;
    padding: 0.25rem 1rem;
    color: ${({ theme }) => theme.color.textPrimary};
    display: block;
    text-decoration: none;
    text-align: center;

    &:hover {
      color: #fcbd5b;
    }
  }

  ${onDesktop`
    width: unset;
    > a {
      text-align: unset;
      padding: 0 1rem;
    }
  `}
`

const InputWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: start;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  ${onDesktop`
    margin-top: unset;
    margin-bottom: unset;
  `}
`
const SocialIconsWrapper = styled.div`
  svg {
    height: 50px;
    width: 50px;
    margin-left: 8px;
  }
`
const Footer = () => {
  return (
    <div
      css={`
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
      `}
    >
      <FooterNavbar>
        <TopFooterContainer>
          <LinksWrapper>
            <a>Home</a>
            <a>Card &amp; Pricing</a>
            <a>About us</a>
          </LinksWrapper>
          <LinksWrapper>
            <a>Customer support</a>
            <a>Contact us</a>
            <a>FAQ</a>
          </LinksWrapper>
          <InputWrapper>
            <Label>Join our newsletter for exclusive offers</Label>
            <div>
              <Input description='Be first to hear about all our news & updates. Unsubscribe any time.' />
              <div>
                <Button
                  title={
                    <>
                      JOIN
                      <br />
                      NOW
                    </>
                  }
                  borderColor='success'
                  titleColor='success'
                  paddingInline='0.5rem'
                  paddingBlock='0.3rem'
                  titleLetterSpacing='2px'
                />
              </div>
            </div>
          </InputWrapper>
          <SocialIconsWrapper>
            <TwitterIcon />
            <FacebookIcon />
            <InstagramIcon />
          </SocialIconsWrapper>
        </TopFooterContainer>
      </FooterNavbar>
      <AppFooter
        css={`
          background: ${({ theme }) => theme.color.background};
        `}
      />
    </div>
  )
}

export default Footer

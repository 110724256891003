import React from 'react'
import { render } from 'react-dom'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import App from './App'
import { basicTheme, GlobalStyle } from 'stream-ui'

const RalewayBold = new URL('./assets/fonts/Raleway/Raleway-Bold.ttf', import.meta.url)
const RalewayMedium = new URL('./assets/fonts/Raleway/Raleway-Medium.ttf', import.meta.url)
const RalewayRegular = new URL('./assets/fonts/Raleway/Raleway-Regular.ttf', import.meta.url)
const RalewaySemiBold = new URL('./assets/fonts/Raleway/Raleway-SemiBold.ttf', import.meta.url)

/* Completely setting all theme variables here even though the 
current (2021/08/20) UI lib is configured with the same variable 
Simply because now we have one place for all colors.
However, I'm still keeping the destructured theme object as there are still major changes to the UI lib.

update (2021/09/09) - moved all CN related variables here. Component library has default values in place.
*/
const cardNoirTheme = {
  ...basicTheme,
  color: {
    ...basicTheme.color,
    primary: '#fbb03b',
    success: '#72bb53',
    error: '#ed1c24',
    muted: '#c0c0c0',
    background: '#323232',
    backgroundMuted: '#444444',
    textPrimary: '#f7f6f6',
    textSecondary: '#c0c0c0',
    label: '#f7f6f6',
    input: '#fbb03b',
    dropdownSelectedBackground: '#444444',
    dropdownSelectedText: '#fbb03b',
    buttonDisabled: '#666666',
    unset: 'unset',
    transparent: 'transparent',

    errorHighlight: '#ef545A',
    successHighlight: '#aedd94',
    primaryHighlight: '#fed683',
    physicalCardHighlight: '#fed683',
    virtualCardHighlight: '#90e4fc',
    dark: '#1a1a1a',
    darker: '#151515',
    darkest: '#080808',
    white: '#ffffff',
    primaryMuted: '#91641c',
    outline: '#e25c1e',

    shadow: '#f7f6f64c',
  },
  font: {
    ...basicTheme.font,
    size: {
      ...basicTheme.font.size,
      slightlyLarger: '1.25rem',
      normal: '1rem',
      small: '0.875rem',
      extraSmall: '0.75rem',
      transactionSummaryButtonTitle: '0.625rem',
      placeholder: '0.813rem',
      buttonTitle: '1rem',
    },
    family: {
      ...basicTheme.font.family,
      regular: '"Raleway-Regular", sans-serif',
      bold: '"Raleway-Bold", sans-serif',
      menuHeader: '"Raleway-SemiBold", sans-serif',
      buttonTitleFontFamily: '"Raleway-SemiBold", sans-serif',
      label: '"Raleway-Regular", sans-serif',
      medium: '"Raleway-Medium", sans-serif',
      semiBold: '"Raleway-SemiBold", sans-serif',
    },
  },
  border: {
    accent: '3px solid ' + basicTheme.color.primary,
  },
  breakpoints: {
    desktop: '950px',
  },
}

const CardNoirGlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Raleway-Bold';
    src: local('Raleway-Bold'), url(${RalewayBold}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Raleway-SemiBold';
    src: local('Raleway-SemiBold'), url(${RalewaySemiBold}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Raleway-Medium';
    src: local('Raleway-Medium'), url(${RalewayMedium}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Raleway-Regular';
    src: local('Raleway-Regular'), url(${RalewayRegular}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  body,input, select, textarea, button {
    font-family: ${({ theme }) => theme.font.family.regular};
  }

  html,body{
    background-color: ${({ theme }) => theme.color.background};
    color: ${({ theme }) => theme.color.textPrimary};
    margin: 0;
  }

  #root {
    min-height: 100vh;
  }
`

// Mount the app
render(
  <ThemeProvider theme={cardNoirTheme}>
    <GlobalStyle />
    <CardNoirGlobalStyles />
    <App />
  </ThemeProvider>,
  document.getElementById('root')
)

import React, { useContext } from 'react'
import LightboxContext from '../../LightboxContext'
import CloseIcon from '../../assets/icons/Icon_Close_Delete_No.svg'
import { Button } from 'stream-ui'
import { onDesktop } from '../../cardnoirStyledElements'

function LightboxWrapper({ title, children, footer, ...props }) {
  const { setLightbox } = useContext(LightboxContext)

  return (
    <div
      css={`
        box-shadow: 0 0 30px 0 ${(props) => props.theme.color.shadow};
        background-color: ${(props) => props.theme.color.background};
        border-radius: 5px;
        width: 100%;
        padding: 0.75rem;
        min-width: 390px;
        margin-bottom: 1.5rem;
        ${onDesktop`
          min-width: 770px;
        `}
      `}
      onClick={(e) => e.stopPropagation()}
      {...props}
    >
      <div css='display: flex; justify-content: space-between; align-items: center; margin-bottom: 0.5rem;'>
        <div
          css={`
            font-size: 1.5rem;
            color: ${(props) => props.theme.color.primary};
            font-family: ${(props) => props.theme.font.family.semiBold};
            width: 100%;
            text-align: center;
            line-height: 1.3;
          `}
        >
          {title}
        </div>
        <Button
          buttonSize='small'
          icon={<CloseIcon />}
          buttonType='borderless'
          iconColor='muted'
          onClick={() => setLightbox(undefined)}
        />
      </div>
      <div
        css={`
          border-radius: 5px;
          border: 2pt solid ${(props) => props.theme.color.primaryHighlight};
          padding: 1.25rem 0.75rem;
        `}
      >
        {children}
      </div>
      <div
        css={`
          padding-top: 0.75rem;
        `}
      >
        {footer}
      </div>
    </div>
  )
}

export default LightboxWrapper

import React from 'react'
import VISAIcon from '../assets/icons/Icon_Visa_SVG.svg'
import MastercardIcon from '../assets/icons/Icon_Master_Card_SVG.svg'
import styled from 'styled-components'
import { Container, onDesktop } from '../cardnoirStyledElements'

const LinksWrapper = styled.div`
  width: 100%;
  min-width: 205px;
  > a {
    color: ${({ theme }) => theme.color.textSecondary};
    font-family: ${({ theme }) => theme.font.family.semiBold};
    font-size: 1rem;
    line-height: 1.3;
    padding: 0.25rem 1rem;
    display: block;
    text-decoration: none;
    text-align: center;

    &:hover {
      color: ${({ theme }) => theme.color.primary};
    }

    ${onDesktop`
      text-align: unset;
      padding: 0.25 1rem;
    `}
  }

  ${onDesktop`
    width: unset;
  `}
`

const AppFooter = (props) => {
  return (
    <div
      css={`
        background: ${({ theme }) => theme.color.dark};
        padding: 0.75rem;
      `}
      {...props}
    >
      <Container
        css={`
          padding: 0;
          display: flex;
          align-items: center;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: center;

          ${onDesktop`
            flex-direction: row;
            gap: 2.5rem;
          `}
        `}
      >
        <LinksWrapper>
          <a>PRIVACY POLICY</a>
          <a>TERMS &amp; CONDITIONS</a>
        </LinksWrapper>
        <div
          css={`
            font-size: ${({ theme }) => theme.font.size.extraSmall};
            color: #929292;
            text-align: center;
            margin-top: 2rem;
            margin-bottom: 2rem;

            ${onDesktop`
              margin-top: unset;
              margin-bottom: unset;
            `}
          `}
        >
          © Card Noir. All rights reserved. Card Noir, (address)(registration country). Card Noir is authorised to ...Ex
          velit dolore voluptate eu et. Voluptate et esse aliquip qui id eiusmod culpa Lorem veniam ut. Culpa sint
          exercitation et mollit nisi amet sunt eiusmod fugiat sunt labore.
        </div>
        <div
          css={`
            display: flex;
            gap: 1rem;
          `}
        >
          <MastercardIcon
            css={`
              height: 43px;
              ${onDesktop`
                height: 55px;
              `}
            `}
          />
          <VISAIcon
            css={`
              height: 40px;
              ${onDesktop`
                height: 50px;
              `}
            `}
          />
        </div>
      </Container>
    </div>
  )
}

export default AppFooter

import PhysicalVISA from '../assets/cardImages/Card_Physical_VISA.png'
import PhysicalMaster from '../assets/cardImages/Card_Physical_MASTER.png'
import VirtualVISA from '../assets/cardImages/Card_Virtual_VISA.png'
import VirtualMaster from '../assets/cardImages/Card_Virtual_MASTER.png'

export const cardsByBrand = {
  visa: {
    virtual: VirtualVISA,
    physical: PhysicalVISA,
  },
  mastercard: {
    virtual: VirtualMaster,
    physical: PhysicalMaster,
  },
}

export const getCardImage = ( card ) => cardsByBrand[card.brand][card.type]

export default cardsByBrand

import React from 'react'
import { Button, CountryCodeDropdown, Input } from 'stream-ui'
import { css } from 'styled-components'
import ArchiveIcon from '../assets/icons/Icon_Archive.svg'
import AddNewBeneficiaryIcon from '../assets/icons/Icon_Beneficiary_Add.svg'
import SearchIcon from '../assets/icons/Icon_Search.svg'

const BeneficiaryCentreSearchIndividual = ({
  className,
  // nameFilter,
  // setNameFilter,
  countryFilter,
  setCountryFilter,
  statusFilter,
  setStatusFilter,
}) => {
  return (
    <div
      css={`
        position: relative;
        box-shadow: 0 0 30px 0 ${(props) => props.theme.color.shadow};
        background-color: ${(props) => props.theme.color.background}
        overflow: hidden;
        border-radius: 5px;
        padding: 1.5rem 1rem 1rem 1rem;
      `}
      className={className}
    >
      <div css='min-width: 360px;'>
        <div
          css={`
            color: ${(props) => props.theme.color.white};
            font-size: 1.125rem;
            font-family: ${(props) => props.theme.font.family.bold};
            padding-bottom: 1rem;
            text-align: center;
          `}
        >
          Search individual beneficiaries
        </div>
        <div css='display: flex;'>
          <Input
            type='text'
            placeholder='Search by name or alias...'
            color='white'
            css={`
              flex-grow: 1;
            `}
            cssForInput={css`
              font-size: ${(props) => props.theme.font.size.small};
              &::placeholder {
                color: ${(props) => props.theme.color.muted};
              }
              &:focus,
              &:focus-visible {
                outline: none;
                box-shadow: none;
                border-radius: 0;
                border-bottom-color: ${({ theme }) => theme.color.white};
              }
            `}
          />
          <Button icon={<SearchIcon />} iconColor='success' buttonType='borderless' buttonSize='extra-small' />
        </div>
        <div css='margin-top: 0.875rem;'>
          <CountryCodeDropdown value={countryFilter} onChange={setCountryFilter} placeholder='Filter by country' />
        </div>
        <div css='margin-top: 0.875rem; padding-bottom: 0.875rem;'>
          <Button
            buttonType='normal'
            buttonSize='small'
            borderColor='muted'
            borderRadius='5px'
            icon={<ArchiveIcon />}
            iconColor='muted'
            backgroundColor='background'
            title='Archived and disabled beneficiaries'
            titleColor='muted'
            titleFontSize='small'
            css={'width: 100%; justify-content:center;'}
            textAlign='center'
            paddingInline='0.5rem'
            onClick={() => setStatusFilter(statusFilter === 'active' ? 'inactive' : 'active')}
          />
        </div>
        <div css='margin-top: 1rem;'>
          <Button
            buttonType='bordered-icon'
            icon={<AddNewBeneficiaryIcon />}
            iconColor='textPrimary'
            titleColor='textPrimary'
            borderColor='primaryHighlight'
            title='Beneficiary'
            description='Add a beneficiary to your list'
            textPadding='0 0.75rem'
          />
        </div>
      </div>
    </div>
  )
}

export default BeneficiaryCentreSearchIndividual

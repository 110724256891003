import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import { PageEncryptedReminder } from 'stream-ui'
import styled from 'styled-components'
import API from '../../api'
import { Container, GlowingBox } from '../../cardnoirStyledElements'
import Footer from '../../layout/Footer'
import Navbar from '../../layout/Navbar'
import SessionContext from '../../SessionContext'
import Background from './Background'
import Overview from './Overview'
import Payment from './Payment'

const Wrapper = styled(GlowingBox)`
  isolation: isolate;
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
  padding: 4rem;
  box-shadow: 0px 0px 32px 1px #ffffff4c;
`

const LastSteps = () => {
  const { session, dispatch } = useContext(SessionContext)
  // const navigate = useNavigate()

  const [error, setError] = useState()

  const hasPaid =
    session.user.status === 'onboarding_payment_confirmed' ||
    session.user.status === 'onboarding_kyc_data_provided' ||
    session.user.status === 'pending_activation'

  const [view, setView] = useState('overview') // overview || payment || onboarding

  const [paymentIntent, setPaymentIntent] = useState()
  // const [paymentCustomerVerificationRequired, setPaymentCustomerVerificationRequired] = useState(false)
  const [showPaymentStatusIcon, setShowPaymentStatusIcon] = useState(hasPaid)

  const hasSubmittedKYC =
    session.user.status === 'onboarding_kyc_data_provided' || session.user.status === 'pending_activation'

  const [showKYCStatusIcon, setShowKYCStatusIcon] = useState(hasSubmittedKYC)

  const initiatePayment = async () => {
    try {
      setShowPaymentStatusIcon(true)
      const paymentIntent = await API.createPaymentIntentForMembershipFee({
        id: session.user.id,
        name: session.user.last_name,
        email: session.user.email,
      })
      setPaymentIntent(paymentIntent)
      setView('payment')

      // // uncomment this part, comment code above to skip payment
      // const paymentResponse = await API.simulatePayment(session.user.id)
      // dispatch({
      //   type: 'setUser',
      //   user: paymentResponse.individual,
      // })
      // setView('overview')
    } catch (err) {
      setShowPaymentStatusIcon(false)
      setError(err)
    }
  }

  const setPaymentSuccess = async () => {
    try {
      setShowPaymentStatusIcon(true)
      const paymentResponse = await API.simulatePayment(session.user.id)
      dispatch({
        type: 'setUser',
        user: paymentResponse.individual,
      })
      setView('overview')
    } catch (err) {
      setShowPaymentStatusIcon(false)
      setError(err)
    }
  }

  const setPaymentFailure = async (error) => {
    setShowPaymentStatusIcon(false)
    setError(error)
    setView('overview')
  }

  const submitForVerification = async () => {
    setShowKYCStatusIcon(true)
    try {
      const response = await API.simulateOnboarding(session.user.id)
      dispatch({
        type: 'setUser',
        user: response.individual,
      })
    } catch (err) {
      setShowKYCStatusIcon(false)
      setError(err)
    }
  }

  // const goToHome = () => {
  //   navigate('/member')
  // }

  return (
    <>
      <Helmet>Card Noir - Nearly done...</Helmet>
      <Navbar smaller={true} />
      <Container
        // $paymentCustomerVerificationRequired={paymentCustomerVerificationRequired}
        css={`
          position: relative;
          border-top: 1px solid transparent;
          /* z-index: ${(props) => (props.$paymentCustomerVerificationRequired ? '1300' : null)}; */
        `}
      >
        <Background />

        <Wrapper>
          {view === 'overview' && (
            <Overview
              {...{
                initiatePayment,
                setView,
                hasPaid,
                hasSubmittedKYC,
                showPaymentStatusIcon,
                error,
                showKYCStatusIcon,
                submitForVerification,
              }}
            />
          )}
          {view === 'payment' && (
            <Payment
              paymentIntent={paymentIntent}
              setPaymentSuccess={setPaymentSuccess}
              setPaymentFailure={setPaymentFailure}
              // setPaymentCustomerVerificationRequired={setPaymentCustomerVerificationRequired}
            />
          )}
        </Wrapper>
        <div css='margin-bottom: 3rem;'>
          <PageEncryptedReminder css='justify-content:center; margin: 0;' />
        </div>
      </Container>
      <Footer />
    </>
  )
}

export default LastSteps

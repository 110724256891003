import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import API from '../api'

import {
  Button,
  CountryCodeDropdown,
  Input,
  PageEncryptedReminder,
  SimpleDatePicker,
  EMAIL_REGEX,
  InputGrid,
  onStringChange,
  regexValidate,
} from 'stream-ui'
import {
  accentBorderTop,
  buttonStyles,
  ButtonWrapper,
  Container,
  Header,
  inputGridPaddingX,
  paddingBottom,
  paddingTop,
} from '../cardnoirStyledElements'
import Footer from '../layout/Footer'
import Navbar from '../layout/Navbar'
import SiderLayout from './SiderLayout'
import SignUpSteps from './SignUpSteps'
import ErrorMessage from '../ErrorMessage'

const SignUp = () => {
  const navigate = useNavigate()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [dateOfBirth, setDateOfBirth] = useState()
  const [promoCode, setPromoCode] = useState('')
  const [countryOfResidence, setCountryOfResidence] = useState()
  const [nationality, setNationality] = useState()

  const [hideErrors, setHideErrors] = useState(true)
  const [isValid, setIsValid] = useState(false)
  const [signupError, setSignupError] = useState()
  const [submitting, setSubmitting] = useState(false)
  useEffect(() => {
    setIsValid(
      firstName &&
        lastName &&
        email &&
        dateOfBirth &&
        countryOfResidence &&
        nationality &&
        firstName !== '' &&
        lastName !== '' &&
        regexValidate(email, true, EMAIL_REGEX)
    )
  }, [firstName, lastName, email, dateOfBirth, countryOfResidence, nationality])

  let signUp = async () => {
    if (!submitting) {
      if (isValid) {
        setSignupError()
        setSubmitting(true) // hack to disable signup button when processing
        try {
          const signupResponse = await API.signup({
            firstName,
            lastName,
            email,
            dateOfBirth,
            promoCode,
            countryOfResidence,
            nationality,
          })
          setSubmitting(false)

          navigate('/sign-up/awaiting-verification', {
            state: { email: signupResponse.email, token: signupResponse.token },
          })
        } catch (err) {
          setSubmitting(false)
          setSignupError(err)
        }
      } else {
        window.scrollTo(0, document.getElementById('top-of-form').getBoundingClientRect().top)
        setHideErrors(false)
      }
    }
  }

  return (
    <>
      <Helmet>Card Noir - Sign Up</Helmet>
      <Navbar smaller={true}/>
      <Header>Sign up with Card Noir today</Header>
      <Container>
        <SignUpSteps step={1} />
        <SiderLayout>
          <InputGrid
            id='top-of-form'
            css={`
              ${paddingBottom}${inputGridPaddingX}
            `}
          >
            <Input
              label='First name'
              id='firstname'
              description='First name as it appears on your ID'
              value={firstName}
              onChange={onStringChange(setFirstName)}
              errorMessage={firstName === '' ? 'This field is required' : ''}
              showErrorOnBlur={hideErrors}
            />
            <Input
              label='Last name'
              id='lastname'
              description='Last name as it appears on your ID'
              value={lastName}
              onChange={onStringChange(setLastName)}
              errorMessage={lastName === '' ? 'This field is required' : ''}
              showErrorOnBlur={hideErrors}
            />
            <Input
              type='email'
              label='Email address'
              id='email'
              description='A verification email will be sent to this email address'
              value={email}
              onChange={onStringChange(setEmail)}
              errorMessage={
                email === ''
                  ? 'This field is required'
                  : regexValidate(email, true, EMAIL_REGEX)
                  ? ''
                  : 'Please enter a valid email address'
              }
              showErrorOnBlur={hideErrors}
            />
            <SimpleDatePicker
              value={dateOfBirth}
              label='Date of birth'
              description='Enter your date of birth. Format: YYYY-MM-DD'
              required={true}
              validator={(current) =>
                current <= DateTime.local().startOf('day') ? '' : 'Date of birth cannot be a future date'
              }
              onChange={setDateOfBirth}
              showErrorOnBlur={hideErrors}
            />
          </InputGrid>
          <InputGrid
            css={`
              ${accentBorderTop}${paddingTop}${paddingBottom}${inputGridPaddingX}
            `}
          >
            <Input
              label='Promo code'
              id='promocode'
              description='If you have a Card Noir promo code, activate it here'
              value={promoCode}
              onChange={onStringChange(setPromoCode)}
            />
            <div
              css={`
                display: none;
                @media (min-width: 740px) {
                  display: unset;
                }
              `}
            />
          </InputGrid>
          <InputGrid
            css={`
              ${accentBorderTop}${paddingTop}${inputGridPaddingX}
              padding-bottom: 4rem;
            `}
          >
            <CountryCodeDropdown
              label='Country of residence'
              description='Your permanent address which will be verified later'
              value={countryOfResidence}
              onChange={setCountryOfResidence}
              errorMessage={!hideErrors && countryOfResidence === undefined ? 'This field is required' : false}
              showErrorOnBlur={true}
            />
            <CountryCodeDropdown
              label='Nationality'
              description='Country in which you hold citizenship. Unfortunately Card Noir does not currently offer services to US citizens or tax residents. Please check back soon'
              value={nationality}
              onChange={setNationality}
              errorMessage={!hideErrors && nationality === undefined ? 'This field is required' : false}
              showErrorOnBlur={true}
            />
          </InputGrid>
          <ErrorMessage message={signupError} css={'text-align:right; margin-bottom: 1rem;'} />
          <ButtonWrapper>
            <PageEncryptedReminder
              css={`
                @media (min-width: 1100px) {
                  margin: 0;
                  padding: 0;
                }
              `}
            />
            <div>
              <Button
                onClick={signUp}
                title='NEXT'
                {...buttonStyles.formButton}
                borderColor={isValid || submitting ? 'success' : 'buttonDisabled'}
                titleColor={isValid || submitting ? 'success' : 'buttonDisabled'}
              />
            </div>
          </ButtonWrapper>
        </SiderLayout>
      </Container>
      <br />
      <br />
      <br />
      <Footer />
    </>
  )
}

export default SignUp

import React, { useState } from 'react'
import styled from 'styled-components'
import Select, { Option } from 'rc-select'

import { Description, Label, DisplayOnlyText, ErrorMessage } from './styledElements'

export const OptionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.4rem 0.75rem;
  overflow: hidden;
  word-break: break-word;

  :hover {
    background-color: ${({ theme }) => theme.color.dropdownSelectedBackground};
    color: ${({ theme }) => theme.color.dropdownSelectedText};
  }
`
export const OptionLabel = styled(OptionItem)`
  padding: 0.2rem 0;
  display: block;
  color: ${({ color, theme }) => (color && theme.color[color] ? theme.color[color] : theme.color.input)};
`

/**
 * FIXME add proper doc
 * @param {*} param0
 *
 * @prop {function} valueFieldFunction - transformer function to extract a string/number value if the list items are JS Objects.
 * @returns
 */
const Dropdown = ({
  label,
  id,
  value,
  displayValue,
  onChange,
  description,
  errorMessage,
  list,
  valueFieldFunction = (v) => v,
  labelFieldFunction = (v) => v,
  optionLabelRenderFunction,
  optionItemRenderFunction,
  filterOption,
  placeholder = 'Please select...',
  displayOnly = false,
  color,
  readOnly,
  showErrorOnBlur = false,
  allowClear = false,
  dropdownMinWidth = 288,
  ...props
  // required = false,
}) => {
  const [blurred, setBlurred] = useState(false)
  return (
    <div
      {...props}
      css={`
        width: 100%;
      `}
    >
      {label && <Label htmlFor={id}>{label}</Label>}
      {displayOnly ? (
        <DisplayOnlyText label={label}>{displayValue ? displayValue : value}</DisplayOnlyText>
      ) : (
        <>
          <Select
            id={id}
            name={id}
            /* Setting rc-invalid when errorMessage is '' because one may only want to show invalid state without an error message */
            className={(errorMessage || errorMessage === '') && (!showErrorOnBlur || blurred) ? 'rc-invalid' : ''}
            placeholder={placeholder}
            allowClear={allowClear} // allows one to clear the input
            showArrow={true}
            showSearch={true}
            optionLabelProp='label'
            dropdownMatchSelectWidth={dropdownMinWidth}
            filterOption={(input, option) => {
              return filterOption && typeof filterOption === 'function'
                ? filterOption(input, list[option.key])
                : list[option.key].toLowerCase().indexOf(input.toLowerCase()) > -1
            }}
            onChange={readOnly ? () => {} : onChange}
            onBlur={() => setBlurred(true)}
            value={value}
            notFoundContent={
              <OptionItem style={{ justifyContent: 'center', padding: '1rem 0' }}>
                <span>No match found.</span>
              </OptionItem>
            }
          >
            {list.map((v, i) => (
              <Option
                key={i}
                value={valueFieldFunction && typeof valueFieldFunction === 'function' ? valueFieldFunction(v, i) : v}
                label={
                  optionLabelRenderFunction && typeof optionLabelRenderFunction === 'function' ? (
                    optionLabelRenderFunction(v)
                  ) : (
                    <OptionLabel color={color}>
                      {labelFieldFunction && typeof labelFieldFunction === 'function' ? labelFieldFunction(v) : v}
                    </OptionLabel>
                  )
                }
              >
                {optionItemRenderFunction && typeof optionItemRenderFunction === 'function' ? (
                  optionItemRenderFunction(v)
                ) : (
                  <OptionItem>
                    {labelFieldFunction && typeof labelFieldFunction === 'function' ? labelFieldFunction(v) : v}
                  </OptionItem>
                )}
              </Option>
            ))}
          </Select>
          <Description
            css={`
              display: ${description ? null : 'none'};
            `}
          >
            {description}
          </Description>
          <ErrorMessage
            css={`
              display: ${errorMessage && (!showErrorOnBlur || blurred) ? null : 'none'};
            `}
          >
            {errorMessage}
          </ErrorMessage>
        </>
      )}
    </div>
  )
}

export default Dropdown

import React, { useContext } from 'react'
import styled from 'styled-components'
import CardOptionsIcon from '../assets/icons/Icon_Card_Options.svg'
import CopyIcon from '../assets/icons/Icon_Copy.svg'
import FundsLoadIcon from '../assets/icons/Icon_Funds_Load.svg'
import FundsSendIcon from '../assets/icons/Icon_Funds_Send.svg'
import FundsTransferIcon from '../assets/icons/Icon_Funds_Transfer.svg'
import TickIcon from '../assets/icons/Icon_Tick_Yes.svg'
import EyeIcon from '../assets/icons/Icon_View_Info_Eye.svg'
import { buttonStyles, GlowingBox, HeaderSecondary, onDesktop } from '../cardnoirStyledElements'
import { currencyFormat } from '../currencies'
import Card from './Card'
import HomeAccountTimeline from './HomeAccountTimeline'
import { Button } from 'stream-ui'
import { useNavigate } from 'react-router'
import SessionContext from '../SessionContext'

const cardRelatedActions = {
  buttonType: 'vertical',
  textAlign: 'center',
}

const CardOptions = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1rem;
  column-gap: 1.5rem;
`

const AccountDetails = ({ account, accent, ...rest }) => {
  return (
    <div css='width: 100%' {...rest}>
      <HeaderSecondary
        css={`
          padding: 0.25rem 0 0.65rem 0;
          text-align: center;

          ${onDesktop`
            text-align: initial;
          `}
        `}
      >
        {account.name}
      </HeaderSecondary>
      <div
        $accent={accent}
        css={`
          display: flex;
          justify-content: space-between;
          padding: 0.4rem 0.6rem;
          border-top: 2pt solid ${(props) => props.theme.color[props.$accent]};
          border-bottom: 2pt solid ${(props) => props.theme.color[props.$accent]};
          align-items: center;
        `}
      >
        <span css='font-size: 1rem;'>Balance</span>
        <span
          css={`
            font-size: 1.25rem;
            font-family: ${(props) => props.theme.font.family.semiBold};
            color: ${(props) => props.theme.color.primary};
          `}
        >
          {currencyFormat.format(account.balance) + ' ' + account.currency}
        </span>
      </div>
    </div>
  )
}

const HomeAccount = ({ isFirst, account }) => {
  const { session } = useContext(SessionContext)
  const transactions = session.transactions.filter((t) => t.account_id === account.id)
  const cards = account.cards
  const accentColor = cards[0].type + 'CardHighlight'
  const navigate = useNavigate()
  return (
    <GlowingBox
      css={`
        display: grid;
        padding: 1rem 1rem;
        grid-template-areas:
          'accountdetails'
          'cards'
          'options';
        row-gap: 1rem;

        ${onDesktop`
          padding-left: 3rem; 
          padding-right: 3rem;
          row-gap: 0rem;
          column-gap: 2rem;
          grid-template-areas:
            'cards accountdetails'
            'cards options';
          grid-template-columns: auto 1fr;
          height: calc(240px * ${cards.length});
        `}

        @media (min-width: 1220px) {
          padding-right: 0;
          row-gap: 0rem;
          column-gap: 2rem;
          grid-template-areas:
            'cards accountdetails timeline'
            'cards options timeline';
          grid-template-columns: auto 7fr 6fr;
          height: calc(240px * ${cards.length});
        }
      `}
    >
      <div css='display:flex; gap: 1.5rem; flex-direction: column; grid-area: cards;'>
        {cards.map((card, i) => (
          <div key={i} css='display: flex; gap: 1rem; padding: 0.625rem 0;'>
            <Card card={card} />
            <div css='display: flex; flex-direction: column; gap: 0.75rem; justify-content: end; padding-bottom: 0.3rem;'>
              {isFirst && (
                <Button
                  {...cardRelatedActions}
                  description={
                    <>
                      main
                      <br />
                      card
                    </>
                  }
                  icon={<TickIcon />}
                  iconColor='physicalCardHighlight'
                  iconPadding='0.4rem 0rem 0 0rem'
                  textPadding='0.7rem 0.2rem 0.35rem'
                />
              )}
              <Button description='copy' icon={<CopyIcon />} {...cardRelatedActions} />
              <Button description='show' icon={<EyeIcon />} {...cardRelatedActions} />
            </div>
          </div>
        ))}
      </div>
      <AccountDetails
        account={account}
        accent={accentColor}
        css={`
          grid-area: accountdetails;
        `}
      />

      <div
        css={`
          grid-area: options;

          ${onDesktop`
            grid-column: 2;
            grid-row: 1;
            margin-top: 94px;
          `}
        `}
      >
        <CardOptions>
          <Button
            title='Load funds'
            description='receive and add'
            icon={<FundsLoadIcon />}
            {...buttonStyles.cardOptionsStyle}
            borderColor={accentColor}
          />
          <Button
            title='Send funds'
            description='pay a beneficiary'
            icon={<FundsSendIcon />}
            {...buttonStyles.cardOptionsStyle}
            borderColor={accentColor}
          />
          <Button
            title='Transfer'
            description='to another card'
            icon={<FundsTransferIcon />}
            {...buttonStyles.cardOptionsStyle}
            borderColor={accentColor}
          />
          <Button
            title='Card Options'
            description='statement, IBAN'
            icon={<CardOptionsIcon />}
            {...buttonStyles.cardOptionsStyle}
            borderColor='successHighlight'
            iconColor='success'
            onClick={() => {
              navigate('/member/statement-centre/' + account.id)
            }}
          />
        </CardOptions>
      </div>

      <div
        css={`
          grid-area: timeline;
          display: none;

          @media (min-width: 1220px) {
            display: initial;
          }
        `}
      >
        <HomeAccountTimeline transactions={transactions} numberOfCards={cards.length} />
      </div>
    </GlowingBox>
  )
}

export default HomeAccount

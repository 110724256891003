import React, { useContext } from 'react'
import { useNavigate } from 'react-router'
import { Button } from 'stream-ui'
import ActivateCardIcon from '../assets/icons/Icon_Activate_Card.svg'
import CopyIcon from '../assets/icons/Icon_Copy.svg'
import HelpIcon from '../assets/icons/Icon_Help_Question.svg'
import TickIcon from '../assets/icons/Icon_Tick_Yes.svg'
import EyeIcon from '../assets/icons/Icon_View_Info_Eye.svg'
import { HeaderSecondary, onDesktop } from '../cardnoirStyledElements'
import { currencyFormat } from '../currencies'
import SessionContext from '../SessionContext'
import AccountDropdown from './AccountDropdown'
import Card from './Card'

const Welcome = ({ showHelp, accounts }) => {
  const navigate = useNavigate()
  return (
    <div>
      <div
        css={`
          display: flex;
          align-items: center;
          margin-bottom: 1.5rem;
          padding-bottom: 1.4rem;
          border-bottom: 2pt solid ${(props) => props.theme.color.primary};
        `}
      >
        <HeaderSecondary css='text-align: center; font-size: 1.75rem; padding: 0; color: #ffffff;'>
          Statement Centre
        </HeaderSecondary>
        <HelpIcon
          css={`
            width: 34px;
            height: 34px;
            fill: #aaaaaa;
          `}
          onClick={showHelp}
        />
      </div>
      <p css='color: #ffffff; text-align: center; padding-inline: 5px;'>
        Welcome to the Statement Centre
        <br />
        <br />
        Currently all transaction history from all cards is being shown. Select a card to search, narrow and refine your
        statement history
      </p>

      <AccountDropdown
        list={accounts}
        value={'all'}
        onChange={(g) => {
          navigate('/member/statement-centre/' + (g === 'all' ? '' : g), { replace: true })
        }}
      />
    </div>
  )
}

const StatementCentreCards = ({ account, showHelp }) => {
  const { user } = useContext(SessionContext)

  const accentColor = account ? account.cards[0].type + 'CardHighlight' : 'physicalCardHighlight'
  return account ? (
    <div
      css={`
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding: 0 1rem;

        ${onDesktop`
          padding: 0;
        `}
      `}
    >
      {account.cards.map((card, i) => (
        <div key={i} css='display: flex; justify-content: space-between;  width: 100%; order: 1;'>
          <Card card={card} width='315px' />
          <div css='display: flex; flex-direction: column; gap: 0.75rem; justify-content: end; padding-bottom: 0.3rem;'>
            <Button
              buttonType='vertical'
              textAlign='center'
              description={
                <>
                  main
                  <br />
                  card
                </>
              }
              icon={<TickIcon />}
              iconColor='physicalCardHighlight'
              iconPadding='0.4rem 0rem 0 0rem'
              textPadding='0.7rem 0.2rem 0.35rem'
              css='padding-top: 36px;'
            />
            <Button description='copy' icon={<CopyIcon />} buttonType='vertical' textAlign='center' />
            <Button description='show' icon={<EyeIcon />} buttonType='vertical' textAlign='center' />
          </div>
        </div>
      ))}
      <Button
        key={0}
        buttonType='bordered-icon'
        icon={<ActivateCardIcon />}
        iconColor='error'
        borderColor='errorHighlight'
        title='Got your card? Activate it here'
        description='After you receive your card you can activate it here'
        textPadding='0 0.75rem'
        css={`
          order: 2;
          ${onDesktop`
            margin-left: 1rem;
          `}
        `}
      />
      <div
        css={`
          width: 100%;
          order: 0;
          ${onDesktop`
            order: 3;
          `}
        `}
      >
        <HeaderSecondary
          $accent={accentColor}
          css={`
            padding: 0.875rem 1rem;
            text-align: center;

            border-top: 2pt solid ${(props) => props.theme.color[props.$accent]};
            display: none;
            ${onDesktop`
              display: block;
              text-align: initial;
            `}
          `}
        >
          {account.name}
        </HeaderSecondary>
        <div
          $accent={accentColor}
          css={`
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.375rem 1rem;
            border-top: 2pt solid ${(props) => props.theme.color[props.$accent]};
            border-bottom: 2pt solid ${(props) => props.theme.color[props.$accent]};
            align-items: center;
          `}
        >
          <span css='font-size: 1rem;'>Balance</span>
          <span
            css={`
              font-size: 1.25rem;
              font-family: ${(props) => props.theme.font.family.bold};
              color: ${(props) => props.theme.color.primary};
            `}
          >
            {currencyFormat.format(account.balance) + ' ' + account.currency}
          </span>
        </div>
      </div>
    </div>
  ) : (
    <div
      css={`
        min-height: 270px;
        position: relative;
        padding-bottom: 0.2rem;
        box-shadow: 0 0 30px 0 ${(props) => props.theme.color.shadow};
        overflow: hidden;
        border-radius: 5px;
        margin-bottom: 1.5rem;
        ${onDesktop`
          border-bottom-left-radius: ${(props) => (props.group ? '0px' : '5x')};
          border-bottom-right-radius: ${(props) => (props.group ? '0px' : '5px')};
          margin-bottom: 0;
        `}
      `}
    >
      <div css='position: relative; z-index: 1; padding: 1.5rem 0 2rem 0; width: 360px; margin-left: auto; margin-right: auto;'>
        <Welcome showHelp={showHelp} accounts={user.accounts} />
      </div>
    </div>
  )
}

export default StatementCentreCards

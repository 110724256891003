import { countries } from './components/countries'

export const EMAIL_REGEX =
/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

/**
 * Checks if the given email address is valid.
 *
 * @param {string} email
 * @returns {boolean}
 */
export const isValidEmail = (email) => email.match(EMAIL_REGEX) !== null

/**
 * Takes the value of a input element and pass it to the given handler function.
 *
 * @param {function} handler - function to call
 * @returns
 */
export const onStringChange = (handler) => (e) => {
  e.preventDefault()
  handler(e.target.value)
}

/**
 * Given a country code (alpha2code), returns the keyed country object (used in CountryCodeDropdown, PhoneNumberInput & PhoneCodeDropdown)
 */
export const getKeyedCountry = (countryCode) => {
  const keyedCountryIndex = countries.findIndex((v) => v.alpha2code === countryCode)
  if (keyedCountryIndex > -1) {
    return { ...countries[keyedCountryIndex], key: keyedCountryIndex }
  }
}

export const regexValidate = (value, required = false, regex = /^.+/g) => {
  if ((value === undefined || value === '') && !required) {
    return true
  }
  return value.match(regex) !== null
}

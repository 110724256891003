import { DateTime } from 'luxon'
import React from 'react'
import { currencyFormat } from '../currencies'
import {  descriptionMap, getTransactionType, iconMap} from './StatementCentreTimeline'

const pr = new Intl.PluralRules(new Intl.NumberFormat().resolvedOptions().locale, {
  type: 'ordinal',
})
const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
])
const formatOrdinals = (n) => {
  const rule = pr.select(n)
  const suffix = suffixes.get(rule)
  return `${n}${suffix}`
}

const getDate = (dateString) => {
  let date = DateTime.fromISO(dateString)

  return date.toFormat('cccc ') + formatOrdinals(date.get('day')) + date.toFormat(' LLLL, yyyy')
}

const TransactionItem = ({ transaction }) => {
  const transactionType = getTransactionType(transaction)

  return (
    <div
      css={`
        :nth-child(odd) {
          background: ${(props) => props.theme.color.backgroundMuted};
        }
      `}
    >
      <div
        css={`
          display: flex;
          width: 100%;
          padding: 0.5rem 3rem 0 1rem;
          height: 2.875rem;
          align-items: start;
          font-size: ${(props) => props.theme.font.size.small};
          font-family: ${(props) => props.theme.font.family.semiBold};
          color: ${(props) => props.theme.color.textPrimary};
          line-height: 1.3;
        `}
      >
        {iconMap[transactionType]}
        <div css='flex-basis: 100%; padding-left: 0.5rem; '>
          <div
            css={`
              font-size: ${(props) => props.theme.font.size.extraSmall};
              color: ${(props) => props.theme.color.textSecondary};
            `}
          >
            {descriptionMap(transactionType)}
          </div>
          <div>{transaction.counterparty.name}</div>
        </div>
        <div
          css={`
            text-align: right;
            flex-shrink: 0;
          `}
        >
          {currencyFormat.format(transaction.amount) + ' ' + transaction.currency}
        </div>
      </div>
    </div>
  )
}

const HomeAccountTimeline = ({ transactions, numberOfCards = 1 }) => {
  let availableSpace = numberOfCards * 192 + 48 * (numberOfCards - 1) - 25
  let displayedTransactions = {}
  transactions.sort((f, s) => DateTime.fromISO(f.created_at) < DateTime.fromISO(s.created_at))
  for (let tx in transactions) {
    if (displayedTransactions[getDate(transactions[tx].created_at)]) {
      availableSpace = availableSpace - 46
      if (availableSpace < 0) {
        break
      }
      displayedTransactions[getDate(transactions[tx].created_at)].push(transactions[tx])
    } else {
      availableSpace = availableSpace - 73.5
      if (availableSpace < 0) {
        break
      }
      displayedTransactions[getDate(transactions[tx].created_at)] = [transactions[tx]]
    }
  }

  return (
    <div
      css={`
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        height: 100%;
      `}
    >
      <div
        css={`
          border-left: 5px solid white;
          height: 100%;
        `}
      >
        <div
          css={`
            font-size: ${(props) => props.theme.font.size.small};
            font-family: ${(props) => props.theme.font.family.semiBold};
            padding-bottom: 0.5rem;
            padding-left: 1rem;
            position: relative;
            ::before {
              content: '';
              width: 17px;
              display: block;
              height: 17px;
              background-color: ${(props) => props.theme.color.primary};
              border-radius: 50%;
              position: absolute;
              left: -11px;
              top: -1px;
            }
          `}
        >
          Most recent transactions
        </div>
        {Object.keys(displayedTransactions).map((date, i) => (
          <div key={date}>
            <div css='padding-left: 1rem;'>
              <div
                css={`
                  border-top: 1px solid ${(props) => props.theme.color.muted};
                  border-bottom: 1px solid ${(props) => props.theme.color.muted};
                  padding: 0.3rem 0;
                  margin-right: 3rem;
                  font-size: ${(props) => props.theme.font.size.small};
                  font-family: ${(props) => props.theme.font.family.semiBold};
                  color: ${(props) => props.theme.color.physicalCardHighlight};

                  position: ${i === 0 ? null : 'relative'};
                  ::before {
                    ${i === 0 ? null : 'content:"";'}
                    width: 17px;
                    display: block;
                    height: 17px;
                    background-color: ${(props) => props.theme.color.primary};
                    border-radius: 50%;
                    position: absolute;
                    left: -27px;
                    top: 3px;
                  }
                `}
              >
                {date}
              </div>
            </div>
            {displayedTransactions[date].map((transaction) => (
              <TransactionItem key={transaction.id} transaction={transaction} />
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default HomeAccountTimeline

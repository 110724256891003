import React, { useContext, useState } from 'react'
import { Button, Dropdown, Flag, getKeyedCountry, Input, onStringChange } from 'stream-ui'
import NameIcon from '../../assets/icons/icon-beneficiary-name-bookmark.svg'
import AddNewBeneficiaryIcon from '../../assets/icons/Icon_Beneficiary_Add.svg'
import CardNoirIcon from '../../assets/icons/Icon_Beneficiary_CARDN.svg'
import SepaIcon from '../../assets/icons/Icon_Beneficiary_SEPA.svg'
import SwiftIcon from '../../assets/icons/Icon_Beneficiary_SWIFT.svg'
import SearchIcon from '../../assets/icons/Icon_Search.svg'
import { buttonStyles, onDesktop } from '../../cardnoirStyledElements'
import LightboxContext from '../../LightboxContext'
import SessionContext from '../../SessionContext'
import LBAddBeneficiary from './LBAddBeneficiary'
import LightboxWrapper from './LightboxWrapper'


const Beneficiary = ({ data, isLastInGroup, isEven, isSelected, setSelectedBeneficiary }) => {
  const paymentTypeDescription = {
    sepa: 'European IBAN - SEPA',
    swift: 'International SWIFT',
  }

  return (
    <div
      $isEven={isEven}
      css={`
        border-bottom: 1pt solid #aaaaaa;
        margin-bottom: ${isLastInGroup ? '0.35rem' : ''};
        background: ${(props) => (props.$isEven ? props.theme.color.backgroundMuted : props.theme.color.background)};
      `}
    >
      <div
        css={`
          padding: 0.75rem 0.75rem;
          height: 110px;
          display: grid;
          row-gap: 0.5rem;
          column-gap: 1rem;
          grid-template-columns: auto 1fr auto;
          grid-template-areas:
            'icon name button'
            'icon typeAndLocation typeAndLocation';

          ${onDesktop`
            height: 90px;
            grid-template-areas: 
              'icon name button' 
              'icon typeAndLocation button'; 
          `}
        `}
      >
        <div css='width: 1.5rem; grid-area: icon;'>
          {data.payment_type === 'swift' ? (
            <SwiftIcon
              css={`
                fill: ${(props) => props.theme.color.white};
              `}
            />
          ) : data.payment_type === 'cardnoir' ? (
            <CardNoirIcon
              css={`
                fill: ${(props) => props.theme.color.white};
              `}
            />
          ) : (
            <SepaIcon
              css={`
                fill: ${(props) => props.theme.color.white};
              `}
            />
          )}
        </div>
        <div
          css={`
            display: flex;
            grid-area: name;
            flex-direction: column;
            gap: 0.25rem;
            ${onDesktop`
              justify-content: space-between;
              flex-direction: row;
            `}
          `}
        >
          <div
            $isSelected={isSelected}
            css={`
              font-family: ${(props) => props.theme.font.family.bold};
              color: ${(props) => (props.$isSelected ? props.theme.color.success : null)};
            `}
          >
            {data.alias}
          </div>
          <div
            css={`
              display: flex;
              align-items: center;
              gap: 0.5rem;
              font-size: 0.875rem;

              ${onDesktop`
                flex-direction: row-reverse;
              `}
            `}
          >
            <NameIcon
              css={`
                width: 0.875rem;
                fill: ${(props) => props.theme.color.primaryHighlight};
              `}
            />
            <div>{data.name}</div>
          </div>
        </div>
        <div
          css={`
            grid-area: button;
            display: flex;
            align-items: center;
            ${onDesktop`margin-left: 1rem;`}
          `}
        >
          <Button
            buttonType='normal'
            buttonSize='small'
            borderColor='successHighlight'
            borderRadius={'5px'}
            backgroundColor={isSelected ? 'success' : isEven ? 'backgroundMuted' : 'background'}
            title={isSelected ? 'Selected' : 'Select'}
            titleColor='white'
            titleFontSize='small'
            css={`
              min-width: 5rem;
              justify-content: center;
            `}
            iconPadding='0.3rem'
            textAlign='center'
            onClick={(e) => {
              e.stopPropagation()
              setSelectedBeneficiary(isSelected ? undefined : data.id)
            }}
          />
        </div>
        <div
          css={`
            grid-area: typeAndLocation;
            font-size: 12px;
            display: flex;
            justify-content: space-between;
            ${onDesktop`
              padding-left: 0.4rem;
            `}
          `}
        >
          <div>
            <div
              css={`
                font-family: ${(props) => props.theme.font.family.bold};
              `}
            >
              {paymentTypeDescription[data.payment_type]}
            </div>
            <div css='display: flex; align-items: center; line-height: 1.3;'>
              <Flag countryCode={data.country} square={true} css='height: 12px; width: 12px; border-radius: 2px;' />
              &nbsp;&nbsp;
              <div>
                <span
                  css={`
                    font-family: ${(props) => props.theme.font.family.bold};
                  `}
                >
                  {data.country}
                </span>
                &nbsp;&nbsp;{getKeyedCountry(data.country).name}
              </div>
            </div>
          </div>
          <div css='text-align: right; font-size: 0.75rem;'>
            <div>Default reference</div>
            <div
              css={`
                font-family: ${(props) => props.theme.font.family.bold};
              `}
            >
              {data.reference || '-'}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const LBSendFunds = (props) => {
  const { user, session } = useContext(SessionContext)
  const { setLightbox } = useContext(LightboxContext)

  const cards = user.accounts
    .reduce((all, value) => [...all, ...value.cards.map((c) => ({ label: value.name + ' *' + c.last4, ...c }))], [])
    .sort((c1, c2) => c1.label.localeCompare(c2.label))

  const [selectedCard, setSelectedCard] = useState()
  const [amount, setAmount] = useState()
  const [reference, setReference] = useState()
  // const [showExistingBeneficiaries, setShowExistingBeneficiaries] = useState(true)
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(props.selectedBeneficiaryId)

  return (
    <LightboxWrapper title='SEND FUNDS from your card' {...props} css='width: 100%; max-width: 632px;'>
      <div
        css={`
          padding-bottom: 1.5rem;
          border-bottom: 2pt solid ${(props) => props.theme.color.primaryHighlight};
          margin-bottom: 1.5rem;
        `}
      >
        <Dropdown
          list={cards}
          valueFieldFunction={(v) => v.id}
          labelFieldFunction={(v) => v.label}
          css='min-width: 340px; width: unset; flex-grow: 1; z-index: 12;'
          filterOption={(input, val) => val.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          label='Send funds from this card:'
          description='Funds will be transferred in EUR currency and are valid up to 50 000 EUR per transaction'
          value={selectedCard}
          onChange={(id) => setSelectedCard(id)}
        />
      </div>
      <div
        css={`
          display: grid;
          grid-template-columns: 1fr;
          gap: 2rem;
          justify-content: center;
          margin-bottom: 1.5rem;

          ${onDesktop`
            grid-template-columns: 1fr 1fr;   
            justify-content: start;         
          `}
        `}
      >
        <div css='display: grid; grid-template-columns: 1fr auto;'>
          <Input
            label='Amount'
            id='amount'
            description='Enter the amount you want to send'
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            errorMessage={amount === '' ? 'This field is required' : ''}
            // showErrorOnBlur={hideErrors}
            type='tel'
            pattern='[0-9]*'
          />
          <div
            css={`
              padding-top: 2.25rem;
              padding-left: 0.75rem;
              font-size: ${(props) => props.theme.font.size.small};
              font-family: ${(props) => props.theme.font.family.bold};
            `}
          >
            € EUR
          </div>
        </div>
        <Input
          label='Reference'
          id='ref'
          description='Enter a reference for the beneficiary to recognize'
          value={reference}
          onChange={onStringChange(setReference)}
          errorMessage={reference === '' ? 'This field is required' : ''}
          // showErrorOnBlur={hideErrors}
        />
      </div>
      {/* <div
        css={`
          display: flex;
          height: 3rem;
          overflow: hidden;

          border-top-left-radius: ${showExistingBeneficiaries ? null : '5px'};
          border-top-right-radius: ${showExistingBeneficiaries ? '5px' : null};
        `}
      >
        <ButtonTab
          selected={showExistingBeneficiaries}
          title='Existing beneficiaries'
          onClick={() => setShowExistingBeneficiaries(true)}
        />
        <ButtonTab
          selected={!showExistingBeneficiaries}
          title='Create new beneficiary'
          onClick={() => setShowExistingBeneficiaries(false)}
        />
      </div> */}
      {/* {showExistingBeneficiaries && (
        <> */}
      <div
        css={`
          margin-top: 1rem;
          display: grid;
          grid-template-columns: auto 1fr;
          grid-column-gap: 2rem;
          grid-row-gap: 0.5rem;
          align-items: center;
          padding-bottom: 0.75rem;
          border-bottom: 2pt solid ${(props) => props.theme.color.successHighlight};
        `}
      >
        <div
          css={`
            font-family: ${(props) => props.theme.font.family.bold};
            font-size: 1.125rem;
            color: ${(props) => props.theme.color.textPrimary};
          `}
        >
          List of all
          <br />
          beneficiaries
        </div>

        <div
          css={`
            display: flex;
            gap: 0.75rem;
            justify-content: end;
            align-items: center;
          `}
        >
          <Input
            type='text'
            placeholder='Search by name, alias or reference...'
            css={`
              display: none;
              ${onDesktop`display: block;`}
              min-width: 360px;
              flex-grow: 1;
              input {
                font-size: ${(props) => props.theme.font.size.small};
              }
            `}
            iconOnRight={
              <SearchIcon
                css={`
                  fill: ${(props) => props.theme.color.success};
                `}
              />
            }
          />
          <Button
            title={
              <>
                Add&nbsp;new
                <br />
                beneficiary
              </>
            }
            {...buttonStyles.statementCentre}
            icon={<AddNewBeneficiaryIcon />}
            iconColor={'primary'}
            onClick={(e) => {
              e.stopPropagation()
              setLightbox(<LBAddBeneficiary />)
            }}
          />
        </div>
        <Input
          type='text'
          placeholder='Search beneficiaries...'
          css={`
            grid-column: span 2;
            ${onDesktop`display: none;`}
            input {
              font-size: ${(props) => props.theme.font.size.small};
            }
          `}
          iconOnRight={
            <SearchIcon
              css={`
                fill: ${(props) => props.theme.color.success};
              `}
            />
          }
        />
      </div>
      <div>
        {session.beneficiaries.map((b, i) => (
          <Beneficiary
            data={b}
            isEven={i % 2 === 0}
            isLastInGroup={i === session.beneficiaries.length - 1}
            key={b.id}
            isSelected={b.id === selectedBeneficiary}
            setSelectedBeneficiary={setSelectedBeneficiary}
          />
        ))}
      </div>
      {/* </> */}
      {/* )} */}
    </LightboxWrapper>
  )
}

export default LBSendFunds

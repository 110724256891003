import { DateTime } from 'luxon'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import { Button, Checkbox, Input, SimpleDatePicker, ToggleButton } from 'stream-ui'
import { css } from 'styled-components'
import ClearIcon from '../assets/icons/Icon_Close_Delete_No.svg'
import SearchIcon from '../assets/icons/Icon_Search.svg'
import AnalyticsIcon from '../assets/icons/Icon_Statement_Analytics.svg'
import SaveExportIcon from '../assets/icons/Icon_Statement_Save_Export.svg'
import { buttonStyles, onDesktop } from '../cardnoirStyledElements'
import ChatButton from '../ChatButton'
import SessionContext from '../SessionContext'
import AccountDropdown from './AccountDropdown'
import AnalyticsDropdown from './AnalyticsDropdown'
import StatementCentreTimeline from './StatementCentreTimeline'

const FilterButton = (props) => (
  <ToggleButton
    {...props}
    activeBackgroundColor={'success'}
    inactiveBackgroundColor='transparent'
    activeColor='textPrimary'
    inactiveColor='textPrimary'
    activeBorderColor='success'
    inactiveBorderColor='muted'
    css={`
      flex-grow: 1;
      height: 32px;
      border-radius: 5px;
      border-width: 2px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 78px;
      font-size: ${(props) => props.theme.font.size.small};
      font-family: ${(props) => (props.isActive ? props.theme.font.family.bold : null)};
    `}
  />
)

const SearchButton = (props) => (
  <Button
    icon={<SearchIcon />}
    iconColor='white'
    css='flex-shrink: 0; min-height: 2rem;'
    paddingInline='0.25rem'
    borderWidth='1pt'
    borderRadius='5px'
    borderColor='success'
    title='Search'
    titleFontSize='small'
    buttonSize='small'
    backgroundColor='success'
    {...props}
  />
)

const ClearButton = (props) => (
  <Button
    icon={<ClearIcon />}
    iconColor='textPrimary'
    iconPadding='0.6rem'
    css='flex-shrink: 0; min-height: 2rem;'
    paddingInline='0.25rem'
    borderWidth='1pt'
    borderRadius='5px'
    borderColor='muted'
    title='Clear'
    titleFontSize='small'
    titleFontFamily='normal'
    buttonSize='small'
    {...props}
  />
)

const FilterCheckbox = ({ label, checked, onChange }) => (
  <Checkbox
    label={label}
    checked={checked}
    onChange={onChange}
    css='align-items: center; justify-content: end; min-width: 95px; flex-direction: row-reverse;'
    cssForCheckIcon={css`
      color: ${(props) => props.theme.color.primary};
      left: 1px;
      top: 1px;
      width: 90%;
    `}
    cssForBox={css`
      border-color: ${(props) => (props.checked ? props.theme.color.primaryHighlight : props.theme.color.muted)};
      width: 30px;
      height: 30px;
      margin-left: 0.5rem;
      margin-right: 0;
      border-width: ${checked ? '2pt' : null};
    `}
    cssForLabel={css`
      font-size: ${(props) => props.theme.font.size.small};
      color: ${(props) => props.theme.color.muted};
      text-align: right;
    `}
  />
)

const StatementCentreReport = ({ account, className }) => {
  const { session, user } = useContext(SessionContext)
  const navigate = useNavigate()

  const [filterList, setFilterList] = useState(['spent', 'loaded', 'transfers'])
  const [showDateFilter, setShowDateFilter] = useState(false)
  const [showAmountFilter, setShowAmountFilter] = useState(false)
  const [showAnalyticsFilter, setShowAnalyticsFilter] = useState(false)

  const toggleFilter = (value) => (isActive) => {
    if (isActive) {
      setFilterList([...filterList, value])
    } else {
      setFilterList(filterList.filter((item) => item !== value))
    }
  }

  const transactions = account
    ? session.transactions
        .filter((t) => t.account_id === account.id && (account === undefined || t.account_id === account.id))
        .map((t) => ({ ...t, account: account }))
    : user.accounts.reduce(
        (prev, current) => [
          ...prev,
          ...session.transactions
            .filter((t) => t.account_id === current.id && (account === undefined || t.account_id === account.id))
            .map((t) => ({ ...t, account: current })),
        ],
        []
      )

  const datePickerProps = {
    reverseOrder: true,
    cssForLabel: css`
      color: ${(props) => props.theme.color.muted};
      text-align: right;
      font-size: ${(props) => props.theme.font.size.extraSmall};
    `,
    cssForInputWrapper: css`
      gap: 0.3rem;
    `,
    cssForInput: css`
      font-size: ${(props) => props.theme.font.size.small};
      background-color: ${(props) => props.theme.color.background};
      border: 1pt solid ${(props) => props.theme.color.muted};
      border-radius: 6px;
      &::placeholder {
        color: ${({ theme }) => theme.color.primaryHighlight};
      }
    `,
    validator: (current) => (current <= DateTime.local().startOf('day') ? '' : 'Date of birth cannot be a future date'),
    showErrorOnBlur: true,
  }

  const filterInputProps = {
    cssForLabel: css`
      color: ${(props) => props.theme.color.muted};
      text-align: right;
      font-size: ${(props) => props.theme.font.size.extraSmall};
    `,
    cssForInput: css`
      text-align: center;
      font-size: ${(props) => props.theme.font.size.small};
      background-color: ${(props) => props.theme.color.background};
      border: 1pt solid ${(props) => props.theme.color.muted};
      border-radius: 6px;
      padding: 0.25rem 0.5rem;
      &::placeholder {
        color: ${({ theme }) => theme.color.primaryHighlight};
      }
    `,
    cssForInputWrapper: css`
      flex-grow: 1;
    `,
    showErrorOnBlur: true,
  }

  return (
    <div>
      <div
        className={className}
        css={`
          box-shadow: 0 0 30px 0 ${(props) => props.theme.color.shadow};
          background-color: ${(props) => props.theme.color.background};
          border-radius: 5px;
          width: 100%;
          padding: 1rem;
        `}
      >
        <div
          css={`
            display: flex;
            justify-content: space-between;
            padding-bottom: 1rem;
            border-bottom: 2pt solid ${(props) => props.theme.color.successHighlight};
          `}
        >
          <div
            css={`
              font-family: ${(props) => props.theme.font.family.semiBold};

              font-size: 20px;
            `}
          >
            Statement
            <br />
            Centre
          </div>
          <div css='display: flex; gap: 1rem;'>
            <Button
              title={
                <>
                  Account
                  <br />
                  analytics
                </>
              }
              {...buttonStyles.statementCentre}
              icon={<AnalyticsIcon />}
              iconColor={'success'}
            />
            <Button
              title={
                <>
                  Save
                  <br />
                  export
                </>
              }
              {...buttonStyles.statementCentre}
              icon={<SaveExportIcon />}
              iconColor={'success'}
            />
          </div>
        </div>
        <div
          css={`
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            border-bottom: 2pt solid ${(props) => props.theme.color.successHighlight};
          `}
        >
          <div
            css={`
              display: flex;
              gap: 0.75rem;
              flex-direction: column;

              ${onDesktop`
                flex-direction: row;
                flex-wrap: wrap;
              `}
            `}
          >
            <AccountDropdown
              list={user.accounts}
              value={account?.id || 'all'}
              onChange={(id) => {
                navigate('/member/statement-centre/' + (id === 'all' ? '' : id), { replace: true })
              }}
            />
            <div css='display: flex; gap: 0.75rem;'>
              <FilterButton
                title='all'
                isActive={filterList.length === 3}
                onToggle={(isActive) =>
                  isActive ? setFilterList(['spent', 'loaded', 'transfers']) : setFilterList([])
                }
              />
              <FilterButton title='spent' isActive={filterList.includes('spent')} onToggle={toggleFilter('spent')} />
              <FilterButton title='loaded' isActive={filterList.includes('loaded')} onToggle={toggleFilter('loaded')} />
              <FilterButton
                title='transfers'
                isActive={filterList.includes('transfers')}
                onToggle={toggleFilter('transfers')}
              />
            </div>
          </div>
        </div>
        <div
          css={`
            display: flex;
            gap: 0.75rem;
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            align-items: start;
            justify-content: end;
            flex-wrap: wrap;
          `}
        >
          <Input
            type='text'
            placeholder='Search transactions...'
            css={`
              min-width: 360px;
              flex-grow: 1;
              input {
                font-size: ${(props) => props.theme.font.size.small};
              }
            `}
            iconOnRight={
              <SearchIcon
                css={`
                  fill: ${(props) => props.theme.color.success};
                `}
              />
            }
          />
          <div css='display: flex; gap: 1rem;'>
            <FilterCheckbox
              label={
                <>
                  Add
                  <br />
                  dates
                </>
              }
              checked={showDateFilter}
              onChange={(e) => setShowDateFilter(e)}
            />
            <FilterCheckbox
              label={
                <>
                  Add
                  <br />
                  amount
                </>
              }
              checked={showAmountFilter}
              onChange={(e) => setShowAmountFilter(e)}
            />
            <FilterCheckbox
              label={
                <>
                  Show
                  <br />
                  analytics
                </>
              }
              checked={showAnalyticsFilter}
              onChange={(e) => setShowAnalyticsFilter(e)}
            />
          </div>
        </div>
        {showDateFilter && (
          <div
            css={`
              display: flex;
              gap: 0.75rem;
              padding-top: 0.75rem;
              padding-bottom: 0.75rem;
              border-top: 2pt solid ${(props) => props.theme.color.primaryHighlight};
              align-items: start;
              flex-wrap: wrap;
              justify-content: end;
              ${onDesktop`
                flex-wrap: nowrap;
                justify-content: start;
              `}
            `}
          >
            <SimpleDatePicker
              label={
                <>
                  from
                  <br />
                  date
                </>
              }
              css={`
                display: flex;
                gap: 0.5rem;
              `}
              onChange={() => {}}
              {...datePickerProps}
            />
            <SimpleDatePicker
              label={
                <>
                  to
                  <br />
                  date
                </>
              }
              onChange={() => {}}
              css={`
                display: flex;
                gap: 0.5rem;
              `}
              {...datePickerProps}
            />
            <SearchButton />
            <ClearButton />
          </div>
        )}
        {showAmountFilter && (
          <div
            css={`
              display: flex;
              gap: 0.75rem;
              padding-top: 0.75rem;
              padding-bottom: 0.75rem;
              border-top: 2pt solid ${(props) => props.theme.color.primaryHighlight};
              align-items: start;
              flex-wrap: wrap;
              justify-content: end;
              ${onDesktop`
                flex-wrap: nowrap;
                justify-content: start;
              `}
            `}
          >
            <Input
              label={
                <>
                  from
                  <br />
                  amount
                </>
              }
              css={`
                display: flex;
                gap: 0.5rem;
                flex-grow: 1;
              `}
              onChange={() => {}}
              placeholder='min'
              {...filterInputProps}
            />
            <Input
              label={
                <>
                  to
                  <br />
                  amount
                </>
              }
              onChange={() => {}}
              css={`
                display: flex;
                gap: 0.5rem;
                flex-grow: 1;
              `}
              placeholder='max'
              {...filterInputProps}
            />
            <div
              css={`
                height: 34px;
                display: flex;
                align-items: center;
                font-family: ${(props) => props.theme.font.family.bold};
                font-size: 0.875rem;
              `}
            >
              € EUR
            </div>
            <SearchButton />
            <ClearButton />
          </div>
        )}
        {showAnalyticsFilter && (
          <div
            css={`
              display: flex;
              gap: 0.75rem;
              padding-top: 0.75rem;
              padding-bottom: 0.75rem;
              border-top: 2pt solid ${(props) => props.theme.color.primaryHighlight};
              align-items: start;
              flex-wrap: wrap;
              justify-content: end;
              ${onDesktop`
                flex-wrap: nowrap;
                justify-content: start;
              `}
            `}
          >
            <AnalyticsDropdown value='Filter by analytics' color='textPrimary' />
            <AnalyticsDropdown value='Filter by analytics' color='textPrimary' />

            <SearchButton />
            <ClearButton />
          </div>
        )}
        <StatementCentreTimeline transactions={transactions} />
      </div>

      <ChatButton
        css={`
          margin-top: 1.5rem;
          ${onDesktop`
            margin-top: 2rem;
          `}
        `}
      />
    </div>
  )
}

export default StatementCentreReport

import React, { useContext, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router'
import BackIcon from '../assets/icons/Icon_Arrow_Back_1.svg'
import CardOptionsIcon from '../assets/icons/Icon_Card_Options.svg'
import CloseIcon from '../assets/icons/Icon_Close_Delete_No.svg'
import StatementCentreIcon from '../assets/icons/Icon_Statement.svg'
import { Container, onDesktop } from '../cardnoirStyledElements'
import AppHeader from '../layout/AppHeader'
import AppWrapper from '../layout/AppWrapper'
import SessionContext from '../SessionContext'
import ButtonTab from './ButtonTab'
import StatementCentreCardOptions from './StatementCentreCardOptions'
import StatementCentreCards from './StatementCentreCards'
import StatementCentreReport from './StatementCentreReport'

const HelpMessage = ({ dismissHelp }) => {
  return (
    <div
      css={`
        display: flex;
        gap: 0.75rem;
        padding: 1rem;
        background: ${(props) => props.theme.color.background};
        margin-bottom: 1.5rem;
        box-shadow: 0 0 30px 0 ${(props) => props.theme.color.shadow};
        overflow: hidden;
        border-radius: 10px;
        flex-direction: column;
        align-items: center;

        color: ${(props) => props.theme.color.white};

        ${onDesktop`
          margin-bottom: 3rem;
          flex-direction: row;
        `}

        > div:first-child {
          font-size: 1.125rem;
          font-family: ${(props) => props.theme.font.family.bold};
        }
      `}
    >
      <div css='display: flex; justify-content: space-between;'>
        Three easy steps to search, find and export past transactions
        <div
          css={`
            ${onDesktop`display: none;`}
          `}
          onClick={dismissHelp}
        >
          <CloseIcon
            css={`
              fill: ${(props) => props.theme.color.muted};
              height: 24px;
            `}
          />
        </div>
      </div>
      <div
        css={`
          display: flex;
          gap: 1rem;
          font-size: 0.875rem;
          align-items: center;
          flex-direction: column;

          ${onDesktop`
            flex-direction: row;
          `}

          > div {
            display: flex;
            gap: 1rem;
            font-size: 0.875rem;
            align-items: center;
          }

          > div {
            > div:first-child {
              height: 46px;
              width: 46px;
              flex-shrink: 0;
              color: white;
              border: 2pt solid ${(props) => props.theme.color.successHighlight};
              border-radius: 5px;
              font-size: 0.75rem;
              font-family: ${(props) => props.theme.font.family.bold};
              text-align: center;
              padding: 0.4rem 0.25rem;
              line-height: 1;

              > div:last-child {
                font-size: 1.25rem;
              }
            }
          }
        `}
      >
        <div>
          <div>
            <div>STEP</div>
            <div>#1</div>
          </div>
          <div>Choose an account and currency to start, or search all transactions</div>
        </div>
        <div>
          <div>
            <div>STEP</div>
            <div>#2</div>
          </div>
          <div>Use the filters to search, narrow and refine your statement history</div>
        </div>
        <div>
          <div>
            <div>STEP</div>
            <div>#3</div>
          </div>
          <div>Export and save exactly the information you need for easy accounting</div>
        </div>
      </div>
      <div
        css={`
          display: none;
          ${onDesktop`display: initial;`}
        `}
        onClick={dismissHelp}
      >
        <CloseIcon
          css={`
            fill: ${(props) => props.theme.color.muted};
            height: 26px;
          `}
        />
      </div>
    </div>
  )
}

const StatementCentre = () => {
  const { user } = useContext(SessionContext)
  const { loadingError } = useOutletContext()

  const { id } = useParams()
  const navigate = useNavigate()

  const [dismissHelp, setDismissHelp] = useState(id !== undefined)

  const [mobileShowStatement, setMobileShowStatement] = useState(true)

  let account = id && user ? user.accounts.find((acc) => acc.id === id) : undefined

  return (
    <AppWrapper
      title='Card Noir - Statement Centre'
      appHeader={
        <AppHeader
          title={account ? account.name : 'Statement Centre'}
          description={account ? 'Card overview, transact & statements' : 'Manage, navigate and export statements'}
          icon={
            <BackIcon
              css={`
                padding: 0.1rem;
                height: 100%;
                width: 100%;
              `}
            />
          }
          iconOnClick={() => {
            navigate(-1)
          }}
        />
      }
    >
      <Container
        css={`
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;

          ${onDesktop`
              padding-top: 3rem;
              padding-bottom: 3rem;
            `}
        `}
      >
        {user === undefined && !loadingError && <div>Loading...</div>}
        {user === undefined && loadingError && (
          <div>We&apos;ve met with an unexpected error while loading the dashboard. Please try again later.</div>
        )}
        {user && !loadingError && (
          <>
            {!dismissHelp && <HelpMessage dismissHelp={() => setDismissHelp(true)} />}
            <div
              css={`
                display: grid;
                align-items: start;
                grid-template-areas:
                  'cardOptions'
                  ${mobileShowStatement
                    ? `
                  'report'
                `
                    : null};
                grid-template-columns: 1fr;
                flex-direction: column;
                ${onDesktop`
                grid-template-areas: 'cardOptions report';
                grid-template-columns: auto 1fr;
                gap: 3rem;
              `}
              `}
            >
              <div
                css={`
                  width: 100%;
                  min-height: 275px;
                  isolation: isolate;
                  grid-area: cardOptions;

                  ${onDesktop`
                  width: 390px;
                `}
                `}
              >
                <StatementCentreCards showHelp={() => setDismissHelp(false)} account={account} />
                {account && (
                  <div
                    css={`
                      margin-top: 1.5rem;
                      margin-bottom: ${mobileShowStatement ? '1.5rem' : ''};
                      ${onDesktop`
                    display: none;
                  `};
                    `}
                  >
                    <div
                      css={`
                        display: flex;
                        height: 3rem;
                        overflow: hidden;
                        border-top-left-radius: ${mobileShowStatement ? '5px' : null};
                        border-top-right-radius: ${mobileShowStatement ? null : '5px'};
                      `}
                    >
                      <ButtonTab
                        selected={!mobileShowStatement}
                        icon={<CardOptionsIcon />}
                        title='OPTIONS'
                        description='transact &amp; settings'
                        onClick={() => setMobileShowStatement(false)}
                      />
                      <ButtonTab
                        selected={mobileShowStatement}
                        icon={<StatementCentreIcon />}
                        title='Statement'
                        description='transaction history'
                        onClick={() => setMobileShowStatement(true)}
                      />
                    </div>
                  </div>
                )}
                {account && (
                  <StatementCentreCardOptions
                    account={account}
                    css={`
                      margin-top: 1rem;
                      display: ${mobileShowStatement ? 'none' : null};
                      ${onDesktop`
                      margin-top: 0px;
                      display: block;
                    `};
                    `}
                  />
                )}
              </div>
              <StatementCentreReport
                css={`
                  grid-area: report;
                  display: ${!mobileShowStatement ? 'none' : null};
                  ${onDesktop`
                  display: block;
                `};
                `}
                account={account}
              />
            </div>
          </>
        )}
      </Container>
    </AppWrapper>
  )
}

export default StatementCentre

import React from 'react'

const ButtonTab = ({ title, icon, description, selected, onClick }) => (
  <div
    onClick={onClick}
    selected={selected}
    css={`
      flex-basis: 50%;

      display: flex;
      align-items: center;
      padding: 0.25rem 1rem;
      background-color: ${(props) =>
        props.selected ? props.theme.color.background : props.theme.color.backgroundMuted};
      cursor: pointer;

      border-style: solid;
      border-width: 2pt;
      border-color: transparent;
      border-top-color: ${(props) => (props.selected ? props.theme.color.successHighlight : null)};
      border-bottom-color: ${(props) => (props.selected ? null : props.theme.color.successHighlight)};

      :first-child {
        border-right-color: ${(props) => props.theme.color.successHighlight};
        border-right-style: solid;
      }
      :not(:first-child) {
        border-left-width: 0;
      }

      svg {
        height: 2rem;
      }

      svg,
      path {
        fill: ${(props) => (props.selected ? props.theme.color.success : props.theme.color.muted)};
      }
    `}
  >
    {icon}
    <div css='width: 100%; text-align: center;'>
      <div
        css={`
          color: ${(props) => props.theme.color.textPrimary};
          color: ${selected ? '#ffffff' : null};
          font-family: ${(props) => props.theme.font.family.bold};
        `}
      >
        {title}
      </div>
      {description && (
        <div
          css={`
            color: ${(props) => props.theme.color.muted};
            color: ${selected ? '#ffffff' : null};
            font-size: ${(props) => props.theme.font.size.extraSmall};
            font-family: ${(props) => props.theme.font.family.bold};
          `}
        >
          {description}
        </div>
      )}
    </div>
  </div>
)

export default ButtonTab

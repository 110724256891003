import React, { useContext } from 'react'
import { spinMixin } from 'stream-ui'
import styled from 'styled-components'
import SpinIcon from '../../assets/icons/icon-funds-convert-forex.svg'
import Sunglasses from '../../assets/icons/Icon_Sunglasses.svg'
import TickIcon from '../../assets/icons/Icon_Tick_Yes.svg'
import { Header } from '../../cardnoirStyledElements'
import ErrorMessage from '../../ErrorMessage'
import SessionContext from '../../SessionContext'
import Steps, { createStepItem } from '../Steps'

const BigButtonWrapper = styled.div`
  height: 130px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;

  @media (min-width: 1430px) {
    height: 110px;
  }
`

const BigButtonText = styled.div`
  position: relative;
  cursor: pointer;
  border: 3px solid ${(props) => (props.done ? props.theme.color.textPrimary : props.theme.color.success)};
  border-radius: 6px;
  margin-right: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  filter: ${(props) => (props.done ? 'blur(2px)' : null)};
`

const Tick = ({ show, done = false }) => {
  return show ? (
    <div css='position: absolute; top: 0; right: -48px; bottom: 0; display: flex; align-items: center;'>
      {done ? (
        <TickIcon
          css={`
            width: 36px;
            fill: ${(props) => props.theme.color.success};
          `}
        />
      ) : (
        <SpinIcon
          css={`
            width: 36px;
            fill: ${(props) => props.theme.color.muted};
            ${spinMixin}
          `}
        />
      )}
    </div>
  ) : null
}

const Overview = ({
  initiatePayment,
  hasPaid,
  hasSubmittedKYC,
  showPaymentStatusIcon,
  error,
  showKYCStatusIcon,
  submitForVerification,
}) => {
  const { dispatch } = useContext(SessionContext)

  return (
    <>
      <Header css='margin-top: 0;' onClick={() => dispatch({ type: 'resetLastSteps' })}>
        Great, your profile has been created
      </Header>
      <p css='width: 100%; text-align: center; margin-top: -0.8rem; margin-bottom: 3rem;'>
        Your Card Noir profile has already been created and is almost ready to use. <br />
        There are two requirements left for you to complete
      </p>
      <div css='display: flex; gap: 2rem; align-items: start;'>
        <Steps
          css='padding-top:1rem; padding-bottom: 1rem;'
          steps={[
            createStepItem(4, 'Load Funds to Your Profile'),
            createStepItem(5, 'Verify Your Information'),
            createStepItem(6, 'Application Submitted!', <Sunglasses css='padding:4px;' />),
          ]}
          current={hasPaid ? (hasSubmittedKYC ? 6 : 5) : 4}
          circleDiameter={{ xxs: 40, xs: 45, sm: 50, md: 60, xxl: 60 }}
          connectorLength={{ xxs: 15, xs: 20, sm: 50, md: 70, xxl: 50 }}
          numberFontSize={{ xxs: '1.1rem', xs: '1.5rem', sm: '2rem', md: '2.5rem' }}
          textFontSize={{ xxs: '0.66rem', xs: '0.75rem', sm: '0.85rem', md: '1rem' }}
          vertical={true}
        />
        <div css='display: flex; width: 100%; flex-direction: column;'>
          <BigButtonWrapper onClick={() => (hasPaid ? null : initiatePayment())}>
            <BigButtonText done={hasPaid}>
              <b>CLICK</b> to pay your 3 year Card Noir membership fee of 99 EUR.
              <br />
              Once your application is approved you will immediately be available to load funds onto your new card
              <Tick done={hasPaid} show={showPaymentStatusIcon} />
            </BigButtonText>
          </BigButtonWrapper>
          {hasPaid && (
            <BigButtonWrapper onClick={() => (hasPaid && !hasSubmittedKYC ? submitForVerification() : null)}>
              <BigButtonText done={hasSubmittedKYC}>
                <b>CLICK</b> to easily verify your Mobile Phone, ID and Address. We partner with the fast and secure
                document verification service called ONFIDO. The verification process will open in a new window...
                it&apos;s super easy!
                <Tick done={hasSubmittedKYC} show={showKYCStatusIcon} />
              </BigButtonText>
            </BigButtonWrapper>
          )}
          {/* {hasPaid && !hasSubmittedKYC && (
                <BigButtonWrapper>
                  <BigButtonText>
                    <br />
                    Your request is being processed. If you need to start over click{' '}
                    <a
                      css={`
                        color: ${(props) => props.theme.color.primary};
                      `}
                    >
                      here
                    </a>
                    .
                    <br />
                    <br />
                  </BigButtonText>
                </BigButtonWrapper>
              )} */}
          {hasPaid && hasSubmittedKYC && (
            <BigButtonWrapper>
              <BigButtonText>
                APPLICATION SUBMITTED! Your Card Noir application is complete and has been received on our end. Watch
                your emails and expect to hear from us the moment your application is approved. We&apos;ll be in touch
                soon!
                <Tick done={true} show={true} />
              </BigButtonText>
            </BigButtonWrapper>
          )}
        </div>
      </div>
      <ErrorMessage message={error} css={'text-align:center; margin-bottom: 1rem;'} />
      {/* {hasPaid && hasSubmittedKYC && (
            <ButtonWrapper>
              <div></div>
              <Button
                onClick={goToHome}
                title='RETURN TO HOME PAGE'
                {...buttonStyles.formButton}
                css='margin-right: 3rem; margin-top: 1rem;'
                borderColor='success'
                titleColor='success'
              />
            </ButtonWrapper>
          )} */}
    </>
  )
}

export default Overview
